import React, { Component } from 'react'
import { IsValidPhoneNumber } from '@coreprj/helpers/base'
import { CreateFormInline } from '@coreprj/shareds/Forms/FormHelper'
import { IBusinessInfo, IPaymentInfo, IPersonalInfo } from '@coreprj/types/models'
import { FormValidator, SingleRuleValidate } from '@coreprj/shareds/Forms/ValidateForm/FormValidator'
import { ProfileContentConfigs } from '../configs'
import CreateFormBase from './CreateFormBase'

interface IPropsBase<T> {
  data?: T
  onSubmit?: (value: Partial<T>) => Promise<void>
  disabled?: boolean
}

const formPersonalValidate = new FormValidator<Partial<IPersonalInfo>>({
  FirstName: { Rules: [{ rule: SingleRuleValidate.Required }] },
  LastName: { Rules: [{ rule: SingleRuleValidate.Required }] },
  DisplayName: { Rules: [{ rule: SingleRuleValidate.Required }] },
  Birthday: { Rules: [{ rule: SingleRuleValidate.Required }] },
  Address: { Rules: [{ rule: SingleRuleValidate.Required }] },
  PhoneNumber: {
    Rules: [
      { rule: SingleRuleValidate.Required },
      {
        rule: SingleRuleValidate.Custom,
        Value: (value, model) => !IsValidPhoneNumber(value),
        message: 'Invalid phone number format.'
      }
    ]
  }
})
const FormPersonalBase = CreateFormBase<IPersonalInfo>()
const FormPersonalContent = CreateFormInline(formPersonalValidate, (p) => (
  <FormPersonalBase key={p.Model?.Id} {...p} config={ProfileContentConfigs.personal()} />
))
export class FormPersonalInfo extends Component<IPropsBase<IPersonalInfo>> {
  render() {
    return <FormPersonalContent.Form onSubmit={this.handleSubmit} data={this.props.data} disabled={this.props.disabled} />
  }
  handleSubmit = async (value: Partial<IPersonalInfo>) => {
    this.props.onSubmit && this.props.onSubmit({ ...this.props.data, ...value })
  }
}

const formBusinessValidate = new FormValidator<Partial<IBusinessInfo>>({
  CompanyName: { Rules: [{ rule: SingleRuleValidate.Required }] }
})
const FormBusinessBase = CreateFormBase<IBusinessInfo>()
const FormBusinessContent = CreateFormInline(formBusinessValidate, (p) => (
  <FormBusinessBase key={p.Model?.Id} {...p} config={ProfileContentConfigs.business()} />
))
export class FormBusinessInfo extends Component<IPropsBase<IBusinessInfo>> {
  render() {
    return <FormBusinessContent.Form onSubmit={this.handleSubmit} data={this.props.data} disabled={this.props.disabled} />
  }
  handleSubmit = async (value: Partial<IBusinessInfo>) => {
    this.props.onSubmit && this.props.onSubmit({ ...this.props.data, ...value })
  }
}

const formPaymentValidate = new FormValidator<Partial<IPaymentInfo>>({
  AccountNumber: { Rules: [{ rule: SingleRuleValidate.Required }] },
  BankName: { Rules: [{ rule: SingleRuleValidate.Required }] },
  IdentityCard: { Rules: [{ rule: SingleRuleValidate.Required }] },
  SwiftNumber: { Rules: [{ rule: SingleRuleValidate.Required }] },
  BeneficiaryName: { Rules: [{ rule: SingleRuleValidate.Required }] },
  BankAddress: { Rules: [{ rule: SingleRuleValidate.Required }] }
})
const FormPaymentBase = CreateFormBase<IPaymentInfo>()
const FormPaymentContent = CreateFormInline(formPaymentValidate, (p) => (
  <FormPaymentBase key={p.Model?.Id} {...p} config={ProfileContentConfigs.payment()} />
))
export class FormPaymentInfo extends Component<IPropsBase<IPaymentInfo>> {
  render() {
    return <FormPaymentContent.Form onSubmit={this.handleSubmit} data={this.props.data} disabled={this.props.disabled} />
  }
  handleSubmit = async (value: Partial<IPaymentInfo>) => {
    this.props.onSubmit && this.props.onSubmit({ ...this.props.data, ...value })
  }
}
