import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {MediaStoreGraphDbContextMediasSortInput} from '../inputs';
import type {MediaStoreGraphDbContextCategoriesSortInput} from '../inputs';
import type {MediaStoreGraphDbContextOrdersSortInput} from '../inputs';
import type {MediaStoreGraphDbContextTicketsSortInput} from '../inputs';
import type {MediaStoreGraphDbContextBillingsSortInput} from '../inputs';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface MediaStoreGraphDbContextFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'MediaStoreGraphDbContext', T, TVariables> {

    on<XName extends ImplementationType<'MediaStoreGraphDbContext'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): MediaStoreGraphDbContextFetcher<
        XName extends 'MediaStoreGraphDbContext' ?
        T & X :
        WithTypeName<T, ImplementationType<'MediaStoreGraphDbContext'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'MediaStoreGraphDbContext'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): MediaStoreGraphDbContextFetcher<T, TVariables>;


    readonly __typename: MediaStoreGraphDbContextFetcher<T & {__typename: ImplementationType<'MediaStoreGraphDbContext'>}, TVariables>;


    media<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Media', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "media"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["media"]
    >;

    media<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['media']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Media', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "media"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['media']>
    >;

    media<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "media", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Media', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"media", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["media"] & XDirectiveVariables
    >;

    media<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['media']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "media", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Media', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"media", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['media']> & XDirectiveVariables
    >;


    medias<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'MediaPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "medias"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["medias"]
    >;

    medias<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['medias']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'MediaPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "medias"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['medias']>
    >;

    medias<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "medias", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'MediaPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"medias", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["medias"] & XDirectiveVariables
    >;

    medias<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['medias']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "medias", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'MediaPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"medias", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['medias']> & XDirectiveVariables
    >;


    category<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Category', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "category"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["category"]
    >;

    category<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['category']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Category', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "category"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['category']>
    >;

    category<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "category", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Category', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"category", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["category"] & XDirectiveVariables
    >;

    category<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['category']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "category", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Category', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"category", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['category']> & XDirectiveVariables
    >;


    categories<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'CategoryPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "categories"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["categories"]
    >;

    categories<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['categories']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'CategoryPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "categories"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['categories']>
    >;

    categories<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "categories", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'CategoryPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"categories", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["categories"] & XDirectiveVariables
    >;

    categories<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['categories']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "categories", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'CategoryPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"categories", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['categories']> & XDirectiveVariables
    >;


    order<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Order', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "order"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["order"]
    >;

    order<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['order']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Order', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "order"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['order']>
    >;

    order<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "order", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Order', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"order", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["order"] & XDirectiveVariables
    >;

    order<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['order']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "order", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Order', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"order", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['order']> & XDirectiveVariables
    >;


    orders<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'OrderPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "orders"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["orders"]
    >;

    orders<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['orders']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'OrderPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "orders"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['orders']>
    >;

    orders<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "orders", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'OrderPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"orders", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["orders"] & XDirectiveVariables
    >;

    orders<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['orders']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "orders", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'OrderPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"orders", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['orders']> & XDirectiveVariables
    >;


    ticket<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Ticket', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "ticket"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["ticket"]
    >;

    ticket<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['ticket']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Ticket', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "ticket"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['ticket']>
    >;

    ticket<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticket", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Ticket', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticket", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["ticket"] & XDirectiveVariables
    >;

    ticket<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['ticket']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticket", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Ticket', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticket", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['ticket']> & XDirectiveVariables
    >;


    tickets<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'TicketPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "tickets"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["tickets"]
    >;

    tickets<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['tickets']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'TicketPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "tickets"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['tickets']>
    >;

    tickets<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "tickets", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'TicketPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"tickets", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["tickets"] & XDirectiveVariables
    >;

    tickets<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['tickets']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "tickets", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'TicketPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"tickets", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['tickets']> & XDirectiveVariables
    >;


    billing<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Billing', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billing"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billing"]
    >;

    billing<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billing']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Billing', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billing"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billing']>
    >;

    billing<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billing", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Billing', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billing", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billing"] & XDirectiveVariables
    >;

    billing<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billing']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billing", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Billing', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billing", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billing']> & XDirectiveVariables
    >;


    billings<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'BillingPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billings"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billings"]
    >;

    billings<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billings']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'BillingPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billings"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billings']>
    >;

    billings<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billings", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'BillingPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billings", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billings"] & XDirectiveVariables
    >;

    billings<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billings']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billings", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'BillingPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billings", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billings']> & XDirectiveVariables
    >;
}

export const mediaStoreGraphDbContext$: MediaStoreGraphDbContextFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "MediaStoreGraphDbContext", 
            "EMBEDDED", 
            [], 
            [
                {
                    category: "REFERENCE", 
                    name: "media", 
                    argGraphQLTypeMap: {id: 'Int!'}, 
                    targetTypeName: "Media", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "medias", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextMediasSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "MediaPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "category", 
                    argGraphQLTypeMap: {id: 'Int!'}, 
                    targetTypeName: "Category", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "categories", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextCategoriesSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "CategoryPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "order", 
                    argGraphQLTypeMap: {id: 'Int!'}, 
                    targetTypeName: "Order", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "orders", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextOrdersSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "OrderPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "ticket", 
                    argGraphQLTypeMap: {id: 'Int!'}, 
                    targetTypeName: "Ticket", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "tickets", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextTicketsSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "TicketPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "billing", 
                    argGraphQLTypeMap: {id: 'Int!'}, 
                    targetTypeName: "Billing", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "billings", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextBillingsSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "BillingPage", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export interface MediaStoreGraphDbContextArgs {

    readonly media: {
        readonly id: number
    }, 

    readonly medias: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextMediasSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly category: {
        readonly id: number
    }, 

    readonly categories: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextCategoriesSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly order: {
        readonly id: number
    }, 

    readonly orders: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextOrdersSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly ticket: {
        readonly id: number
    }, 

    readonly tickets: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextTicketsSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly billing: {
        readonly id: number
    }, 

    readonly billings: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextBillingsSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }
}
