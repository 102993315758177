import React, { Component } from 'react'
import { CartItemContext } from './Context'
import { getFormValidate } from './FormValidate'
import { ICartDTO, ICartItemFormDTO } from '../../type'
import { ICartItemContentProps, ICartItemContext } from './type'
import { MessageTexts } from '../../helpers/MessageTexts'
import { ConvertFormDataToJson } from '../../../../Components/ValidateForm/Common'
import { FormValidator, PartialError, SingleRuleValidate } from '../../../../Components/ValidateForm/FormValidator'
import Helpers from '../../helpers'
import CartItemContent from './Content'

interface IProps extends ICartItemContentProps {
  data: ICartDTO
}

export default class CartItem extends Component<IProps, ICartItemContext> {
  private formValidate: FormValidator<Partial<ICartItemFormDTO>>
  constructor(props: IProps) {
    super(props)
    this.formValidate = getFormValidate()
    this.state = {
      onBlur: this.handleBlurPasteLink,
      onAdd: this.handleAddLink,
      onClearLink: this.handleClearLink,
      onDelete: this.handleDeleteLink,
      MessageError: {},
    }
  }

  refForm: HTMLFormElement | null = null
  render() {
    return (
      <form ref={(ref) => (this.refForm = ref)}>
        <CartItemContext.Provider value={this.state}>
          <CartItemContent {...this.props} />
        </CartItemContext.Provider>
      </form>
    )
  }

  validate = () => {
    if (!this.refForm) return false
    const formData = new FormData(this.refForm)
    const data = ConvertFormDataToJson(formData)
    const messageError = this.formValidate.run(data)
    this.setState({ MessageError: messageError })
    return Object.keys(messageError).length < 1
  }

  handleBlurPasteLink = () => {
    if (!this.refForm) return
    const formData = new FormData(this.refForm)
    const data = ConvertFormDataToJson<ICartItemFormDTO>(formData)
    const messageError: PartialError<ICartItemFormDTO> = this.formValidate.run(data)
    this.setState({ modelState: data, MessageError: messageError })
  }

  handleDeleteLink = (value: string) => {
    let linksOld = this.props.data.jsonContent?.links ?? []
    const links = Helpers.removeLink(linksOld, value)
    const cart: ICartDTO = Helpers.updateLinkJsonContentCart(this.props.data, links)
    this.props.onChange && this.props.onChange(cart)
    this.setState({ modelState: { ...this.state.modelState, PasteLinks: Helpers.linksToString(links) } })
  }

  handleClearLink = () => {
    const cart: ICartDTO = Helpers.updateLinkJsonContentCart(this.props.data, [])
    this.props.onChange && this.props.onChange(cart)
    this.setState({ modelState: { ...this.state.modelState, PasteLinks: Helpers.linksToString([]) } })
  }

  handleAddLink = () => {
    const formValidate = this.validateForm()
    if (!formValidate) return
    const { formData, messageError } = formValidate

    if (messageError.PasteSingleLink && messageError.PasteSingleLink.length > 0) {
      this.setState({ MessageError: messageError })
      return
    }

    const list = this.props.data.jsonContent?.links ?? []
    const singleLinks = Helpers.linksToArray(formData.PasteSingleLink)
    const links = Helpers.joinLink(list, singleLinks)

    if (links.length + singleLinks.length > Helpers.maxLink) {
      messageError['PasteSingleLink'] = [{ rule: SingleRuleValidate.Custom, message: MessageTexts.maxLink }]
    }

    const cart: ICartDTO = Helpers.updateLinkJsonContentCart(this.props.data, links.slice(0, Helpers.maxLink))

    formData.PasteLinks = Helpers.linksToString(links.slice(0, Helpers.maxLink))
    this.props.onChange && this.props.onChange(cart)
    this.setState({ modelState: formData, MessageError: messageError })
  }

  private getFormData = () => {
    if (!this.refForm) return
    const fData = new FormData(this.refForm)
    return ConvertFormDataToJson<ICartItemFormDTO>(fData)
  }

  private validateForm = () => {
    const formData = this.getFormData()
    if (!formData) return
    const messageError: PartialError<ICartItemFormDTO> = this.formValidate.run(formData)
    return { formData, messageError }
  }
}
