import React, { FC } from 'react'
import { Box } from '@mui/material'
import { Outlet, useMatch } from 'react-router-dom'
import AppFooter from './AppFooter'
import AppHeader from './AppHeader'
import { NavigateKey } from '../../Route'

const Layout: FC = () => {
  let isExplore = useMatch(NavigateKey.Explore)
  return (
    <Box>
      <AppHeader fixed isSearch={!!isExplore} />
      <Box sx={{ minHeight: 'calc(100vh - 240px)' }}>
        <Outlet />
      </Box>
      <AppFooter />
    </Box>
  )
}
export default Layout
