export enum ELazyStatus {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error'
}

export enum EMediaStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export enum EMediaType {
  Video = 'Video',
  Audio = 'Audio'
}

export enum EOrderStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Done = 'Done',
  Error = 'Error'
}

export enum EOrderPayment {
  Paypal = 'Paypal',
  Payoneer = 'Payoneer'
}

export enum EClaimedMediaStatus {
  Pending = 'Pending',
  Processed = 'Processed'
}

export enum EBillingStatus {
  New = 'New',
  Paid = 'Paid',
  UnPaid = 'UnPaid'
}

export enum EBillingType {
  Revenue = 'Revenue'
}

export enum EPriceType {
  Media = 'Media',
  Pack = 'Pack'
}

export enum ECategoryType {
  Video = 'Video',
  Audio = 'Audio'
}

export enum EGalleryType {
  System = 'System',
  User = 'User'
}

export enum EGalleryKey {
  Videos1 = 'Videos1',
  Videos2 = 'Videos2',
  Audio1 = 'Audio1',
  Audio2 = 'Audio2',
  Audio3 = 'Audio3',
  Audio4 = 'Audio4',
  Audio5 = 'Audio5'
}

export enum ENotificationSendType {
  All = 'All',
  User = 'User'
}

export enum ENotificationAreaType {
  Header = 'Header',
  Popup = 'Popup'
}
