import React, { Component, Fragment } from 'react'
import { Box } from '@mui/material'
import { IOrder, IOrderDetail } from '@coreprj/types/models'
import { IProfileContentFormConfig } from '@coreprj/shareds/Pages/ProfileContent/configs'
import TableFormater from '@coreprj/helpers/TableFormater'
import UiStyleds from '@coreprj/shareds/Pages/ProfileContent/UI/Styled'
import CreateFormBase from '@coreprj/shareds/Pages/ProfileContent/Form/CreateFormBase'
import { FieldSocialUrl, parseNameResourceInfo } from './Fields'

export const GetOrderConfigs = (value?: Partial<IOrder>): IProfileContentFormConfig<Partial<IOrder>> => {
  return {
    title: <UiStyleds.Text18 sx={{ fontWeight: 600, color: '#0094fe' }}>Order infomation</UiStyleds.Text18>,
    description: value?.Name ?? '',
    items: [
      { inputName: 'Method', title: 'Method' },
      { inputName: 'Status', title: 'Status' }
    ]
  }
}

export const GetOrderDetailConfigs = (value: IOrderDetail, index: number): IProfileContentFormConfig<Partial<IOrderDetail>> => {
  const descriptions = [value.Type, TableFormater.formatCurrency(value.Price), TableFormater.formatDate(value.DateCreated)].filter((x) => !!x)
  return {
    title: (
      <UiStyleds.Text16 sx={{ fontWeight: 600 }}>
        <span className='noselect'>{index}. </span>
        {parseNameResourceInfo(value.ResourceInfo)}
      </UiStyleds.Text16>
    ),
    description: (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
        {descriptions.map((item, index) => {
          return (
            <Fragment key={item + index}>
              <UiStyleds.Text14 key={index} variant='caption' {...{ component: 'span' }}>
                {item}
              </UiStyleds.Text14>
              {index < descriptions.length - 1 && (
                <UiStyleds.Text14 key={index} variant='caption' {...{ component: 'span' }}>
                  {' | '}
                </UiStyleds.Text14>
              )}
            </Fragment>
          )
        })}
      </Box>
    ),
    items: [{ inputName: 'SocialUrl', title: 'SocialUrl', inputElement: FieldSocialUrl }]
  }
}

interface IProps {
  data?: Partial<IOrder>
}

export default class OrderDetailContent extends Component<IProps> {
  render() {
    const list = this.props.data?.OrderDetail ?? []
    return (
      <Box>
        {this.renderBasicInfo(this.props.data)}
        {list.map(this.renderItem)}
      </Box>
    )
  }

  renderItem = (item: IOrderDetail, index: number) => {
    const config = GetOrderDetailConfigs(item, index + 1)
    const Item = CreateFormBase<Partial<IOrderDetail>>()
    const name = parseNameResourceInfo(item.ResourceInfo)
    return <Item key={name + index} alwaysCustomized={false} config={config} Model={item} />
  }

  renderBasicInfo = (value?: Partial<IOrder>) => {
    const config = GetOrderConfigs(value)
    const BasicInfo = CreateFormBase<Partial<IOrderDetail>>()
    return <BasicInfo alwaysCustomized={false} config={config} Model={value} />
  }
}
