import { IBusinessInfo, IPaymentInfo, IPersonalInfo } from '@coreprj/types/models'
import { DatePickers } from './Form/InputControls'
import TextOnly from './Form/TextOnly'

export interface IItemConfig<T> {
  title: string
  placeholder?: string
  inputName: keyof T & string
  disabled?: boolean
  inputElement?: React.ElementType
  AllwaysDisplay?: boolean
}

export interface IProfileContentFormConfig<T> {
  title?: JSX.Element | string
  description?: JSX.Element | string
  items: IItemConfig<T>[]
}

export const ProfileContentConfigs = {
  personal: (): IProfileContentFormConfig<IPersonalInfo> => ({
    title: 'Personal information',
    description: 'Some info may be visible',
    items: [
      { inputName: 'Email', title: 'Email', disabled: true },
      { inputName: 'FirstName', title: 'First name' },
      { inputName: 'LastName', title: 'Last name' },
      { inputName: 'DisplayName', title: 'Display name' },
      { inputName: 'PhoneNumber', title: 'Phone number' },
      { inputName: 'Birthday', title: 'Birthday', inputElement: DatePickers },
      { inputName: 'Address', title: 'Address' },
      { inputName: 'PasswordRamdom', title: 'Ramdom password', inputElement: TextOnly, AllwaysDisplay: false }
    ]
  }),
  business: (): IProfileContentFormConfig<IBusinessInfo> => ({
    title: 'Business information',
    items: [
      { inputName: 'CompanyName', title: 'Company name' },
      { inputName: 'Position', title: 'Position' },
      { inputName: 'TaxId', title: 'Tax' },
      { inputName: 'Address', title: 'Business address' }
    ]
  }),
  payment: (): IProfileContentFormConfig<IPaymentInfo> => ({
    title: 'Payment information',
    items: [
      { inputName: 'AccountNumber', title: 'Account number' },
      { inputName: 'BankName', title: 'Bank name' },
      { inputName: 'IdentityCard', title: 'Identity card' },
      { inputName: 'SwiftNumber', title: 'Swift code' },
      { inputName: 'BeneficiaryName', title: 'Beneficiary name' },
      { inputName: 'BankAddress', title: 'Bank address' }
    ]
  })
}
