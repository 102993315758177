import React, { Component } from 'react'
import { Container, Stack, styled } from '@mui/material'
import { PlayListSkeleton } from '../../../../Components'
import Carousel from './Carousel'
import PlaylistItem from './PlaylistItem'
import { IGallery } from '@coreprj/types/models'

interface IProps {
  data: IGallery[]
}
export class AudioGalleries extends Component<IProps> {
  render() {
    return (
      <Container>
        <Stack gap='15px'>
          <Title>Royalty-free music</Title>
          <SubTitle sx={{ fontWeight: '400' }}>Download or stream - Get unlimited access to over 40,000 tracks & 90,000 sound effects</SubTitle>
          {this.renderContent()}
        </Stack>
      </Container>
    )
  }

  renderContent = () => {
    const { data } = this.props
    if (data.length <= 0) return <PlayListSkeleton />
    return <Carousel data={data} renderItem={(e, i) => <PlaylistItem data={e} key={i} />} />
  }
}
export default AudioGalleries

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '36px',
  fontWeight: 700,
  textAlign: 'center',
  color: '#606060'
})

const SubTitle = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '20px',
  fontWeight: 500,
  textAlign: 'center',
  color: '#878787'
})
