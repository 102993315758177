import { createSlice } from '@reduxjs/toolkit'
import { fetchHomeThunk } from './Thunks'
import { IHomeStateSlice } from './type'
import { ELazyStatus } from '../../../Models/enums'
import { EGalleryKey } from '@coreprj/types/enum'

// Define the initial state using that type
const initialState: IHomeStateSlice = {
  Status: ELazyStatus.Loading,
  Galleries: [],
  Categories: [],
  Prices: []
}

const HomeSlice = createSlice({
  name: 'HomeSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded
        state.Categories = action.payload.Categories?.Data ?? []
        state.Galleries = (action.payload.Galleries?.Data ?? []).map((x) => {
          if (x.Key === EGalleryKey.Videos1) {
            x.GalleryDetails = action.payload.GalleryDetail?.Videos1?.Data ?? []
          } else if (x.Key === EGalleryKey.Videos2) {
            x.GalleryDetails = action.payload.GalleryDetail?.Videos2?.Data ?? []
          }
          return x
        })
        state.Prices = action.payload.Prices?.Data ?? []
      })
      .addCase(fetchHomeThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchHomeThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
      })
  }
})

// export const {} = HomeSlice.actions

export default HomeSlice
