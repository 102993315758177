export enum NavigateKey {
  InitialPath = '/',
  Home = '/',
  Explore = '/explore',
  Cart = '/cart',
  CheckOut = '/checkout',
  CheckOutComplete = '/checkout-complete',
  Report = '/report',
  Detail = '/detail/:type/:id',
  SubmitMedia = '/submit-media',
  FQAs = '/fqas',
  Policy = '/privacy-policy',
  Pricing = '/our-service',
  Profile = '/profile',
  Order = '/order',
  OrderDetail = '/order-detail',
  AboutUs = '/about'
}
