import { EntityState } from '@reduxjs/toolkit'

export enum LazyStatus {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error'
}

export interface TStateRedux {
  Status: LazyStatus
}

export interface TDispatchRedux<T = any> {
  FetchData: (p: T, signal?: AbortSignal) => { abort: () => void }
}

export interface ICRUDActionRedux<TModel, Id extends keyof TModel> {
  Create: (model: Partial<TModel>) => Promise<void>
  Update: (Id: TModel[Id], model: Partial<TModel>) => Promise<void>
  Delete: (Id: TModel[Id], model?: Partial<TModel>) => Promise<void>
}

export interface ISliceBase<TModel> {
  Status: LazyStatus
  data: EntityState<TModel>
  requestedId?: string
}
