import { ICartDTO } from '../type'
import { DataMapping } from './DataMapping'
import { FetchDelay } from '../../../utilities'
import { CreateHttpService } from '@coreprj/graphql/base'
import ServiceBase from '@coreprj/http/ServiceBase'

class CheckOutServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/user/Checkout`))
  }
  private urlValidate = '/Validate'
  validate = (value: ICartDTO[], signal?: AbortSignal): Promise<boolean> => {
    return FetchDelay(async () => {
      const bodyRequest = DataMapping.cartsCheckout(value)
      const result = await this.PushNotify(this.Post<boolean>, this.urlValidate, bodyRequest, { signal })
      return result
    }, 2000)
  }
}

const CheckOutService = new CheckOutServiceBase()
export default CheckOutService
