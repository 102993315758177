import React, { Component } from 'react'
import { Button } from '@mui/material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { IAddToCartButtonReduxProps, ICartDTO, ICartInfoDTO } from './type'

interface IProps extends IAddToCartButtonReduxProps {}

export default class AddToCartButton extends Component<IProps> {
  render() {
    return (
      <Button
        color="primary"
        variant="contained"
        sx={{ textTransform: 'unset' }}
        endIcon={<AddShoppingCartIcon />}
        onClick={this.handleClick}
      >
        Add to cart
      </Button>
    )
  }

  handleClick = () => {
    const cartInfos: ICartInfoDTO[] = [{ key: 'Price', value: this.props.pricePackage.Price.toString() }]
    const cart: ICartDTO = {
      id: this.props.pricePackage.Id.toString(),
      name: this.props.pricePackage.Name ?? '',
      type: 'Pack',
      pricePackage: this.props.pricePackage,
      infos: cartInfos,
    }
    this.props.addCart(cart)
  }
}
