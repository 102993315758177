import React, { Component } from 'react'
import { ELazyStatus } from '@coreprj/types/enum'
import { Avatar, Box, Container, Stack } from '@mui/material'
import { FormBusinessInfo, FormPaymentInfo, FormPersonalInfo } from './Form/FormCommons'
import { EProfileFormType, IProfileContentData, TUpdateProfileContentParams } from './type'
import UiStyleds from './UI/Styled'
import { authService } from 'partner-oidc-auth'
import { AuthStringAvatar } from '@coreprj/auth-oidc-ui/Helper'

interface IProps {
  status?: ELazyStatus
  data?: IProfileContentData
  avatarDisabled?: boolean
  components?: {
    orderView?: {
      top?: JSX.Element
      bottom?: JSX.Element
    }
  }
  events?: {
    onUpdate: (value: TUpdateProfileContentParams) => Promise<void>
  }
}
interface IState {
  displayName?: string
  avatar?: string
}
export default class ProfileContent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
  }
  async populateState() {
    const user = await authService.getUser()
    this.setState({
      displayName: user?.nickname,
      avatar: user?.picture
    })
  }
  componentDidMount(): void {
    this.populateState()
  }
  render() {
    return (
      <Container maxWidth={false}>
        <Box sx={{ height: '72px' }} />
        {!this.props.avatarDisabled && (
          <Stack sx={{ alignItems: 'center', gap: '6px' }}>
            <Avatar src={this.state.avatar} {...AuthStringAvatar(this.state.displayName ?? 'User', { width: 110, height: 110 })} />
            <UiStyleds.Text16 sx={{ fontWeight: '600', textAlign: 'center' }}>
              Welcome, <i>{this.state.displayName}</i>
            </UiStyleds.Text16>
          </Stack>
        )}
        {this.props.components?.orderView?.top && this.props.components.orderView.top}
        <FormPersonalInfo
          disabled={this.props.status === ELazyStatus.Loading}
          data={this.props.data?.[EProfileFormType.Personal]}
          onSubmit={async (value) => {
            await (this.props.events?.onUpdate && this.props.events?.onUpdate({ key: EProfileFormType.Personal, value }))
          }}
        />
        <FormBusinessInfo
          disabled={this.props.status === ELazyStatus.Loading}
          data={this.props.data?.[EProfileFormType.Business]}
          onSubmit={async (value) => {
            await (this.props.events?.onUpdate && this.props.events?.onUpdate({ key: EProfileFormType.Business, value }))
          }}
        />
        <FormPaymentInfo
          disabled={this.props.status === ELazyStatus.Loading}
          data={this.props.data?.[EProfileFormType.Payment]}
          onSubmit={async (value) => {
            await (this.props.events?.onUpdate && this.props.events?.onUpdate({ key: EProfileFormType.Payment, value }))
          }}
        />
        {this.props.components?.orderView?.bottom && this.props.components.orderView.bottom}
        {/* <UiStyleds.FormWrap sx={{ pb: '24px' }}>
          <UiStyleds.Text18 sx={{ fontWeight: 600 }}>Other</UiStyleds.Text18>
          <Button sx={{ mt: '12px' }}>
            <UiStyleds.Text16 sx={{ color: '#FF3419', fontWeight: 600, textTransform: 'none' }} {...{ component: 'span' }}>
              Disable account
            </UiStyleds.Text16>
          </Button>
        </UiStyleds.FormWrap> */}
      </Container>
    )
  }
}
