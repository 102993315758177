import { createAsyncThunk } from '@reduxjs/toolkit'
import { EProfileFormType, IProfileContentData, TUpdateProfileContentParams } from '@coreprj/shareds/Pages/ProfileContent/type'
import ProfileService from '../services/ProfileService'
import { authService } from 'partner-oidc-auth'
import { ApiAlertContext } from '@coreprj/shareds/AlertGlobal'

interface IProfileThunk {
  profileContentData: IProfileContentData
}

export const fetchProfileThunk = createAsyncThunk<IProfileThunk>('fetchProfileThunk', async (_, thunkAPI) => {
  const res = await ProfileService.getProfile(thunkAPI.signal)
  return { profileContentData: res }
})

export const fetchProfileUpdateThunk = createAsyncThunk<IProfileContentData | undefined, TUpdateProfileContentParams>(
  'fetchProfileUpdateThunk',
  async (params, thunkAPI) => {
    try {
      let result: IProfileContentData | undefined = undefined
      switch (params.key) {
        case EProfileFormType.Personal: {
          const res = await ProfileService.updatePersonal(params.value, thunkAPI.signal)
          result = { [EProfileFormType.Personal]: res }
          console.log('authService')
          await authService.signIn({ returnUrl: authService.getReturnUrl() })
          break
        }
        case EProfileFormType.Business: {
          const res = await ProfileService.updateBusiness(params.value, thunkAPI.signal)
          result = { [EProfileFormType.Business]: res }
          break
        }
        case EProfileFormType.Payment: {
          const res = await ProfileService.updatePayment(params.value, thunkAPI.signal)
          result = { [EProfileFormType.Payment]: res }
          break
        }
        default: {
          result = undefined
          break
        }
      }
      ApiAlertContext.ApiAlert?.PushSuccess('Update done!')
      return result
    } catch (error) {
      ApiAlertContext.ApiAlert?.PushError('Update fail!')
    }
  }
)
