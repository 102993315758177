import { AxiosError } from 'axios'
import { ApiAlertContext } from '@coreprj/shareds/AlertGlobal'
import { ApplicationPaths, authService } from 'partner-oidc-auth'

export const ProcessRepose = async (err: AxiosError) => {
  if (err.response?.status === 403) {
    const dataError: { Code: number; Message: string } = err.response.data as any
    switch (dataError.Code) {
      case 3: {
        await authService.signOut({ returnUrl: authService.getReturnUrl() })
        break
      }
      case 1: {
        await authService.userManager?.revokeTokens(['access_token'])
        window.location.replace(ApplicationPaths.Origin(''))
        break
      }
      default: {
        window.location.replace(ApplicationPaths.Origin(ApplicationPaths.IdentityAccessDenied))
        break
      }
    }
  } else if (err.response?.status === 401) {
    await authService.userManager?.revokeTokens(['access_token'])
    try {
      const user = await authService.userManager?.signinSilent(authService.createArguments({ returnUrl: window.location.pathname }))
      authService.updateState(user)
    } catch (error) {
      if (await authService.getUser()) {
        await authService.signOut({ returnUrl: process.env.PUBLIC_URL })
        await authService.userManager?.clearStaleState()
        await authService.userManager?.removeUser()
      } else {
        await authService.userManager?.clearStaleState()
        window.location.replace(process.env.PUBLIC_URL ?? '/')
      }
    }
  } else if (err.code === 'ERR_CANCELED') {
  } else if ((err.response?.status ?? 500) >= 500) {
    if (err.code !== 'ERR_CANCELED') {
      ApiAlertContext.ApiAlert?.PushError('An error has occurred! (server error 500)')
    }
  } else if (err.response?.status === 404) {
    ApiAlertContext.ApiAlert?.PushError('An error has occurred! (server error 404)')
  }
  return Promise.reject(err)
}
