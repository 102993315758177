import { IOrder } from '@coreprj/types/models'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GridCallbackDetails } from '@mui/x-data-grid'
import { IPagingModel } from '@coreprj/shareds/Tables/ModelFilter'
import { ConvertTableToRequestGraphQLFilter } from '@coreprj/http/Getway'
import { ITableTemplateState } from '@coreprj/shareds/Tables/TemplateTable'
import { getConvertGraphQLFilterOptions, mapOrdersDataTable } from '../Services/DataMapping'
import GraphQLOrderService, { TOrder } from '@coreprj/services/GraphQLOrderService'
import { ISliceState } from './Slice'

interface IOrderThunkParams {
  key: keyof ITableTemplateState<IOrder>
  value: any
  details: GridCallbackDetails
}

interface IResponse {
  Result: IPagingModel<IOrder>
  TableInfo: ITableTemplateState<IOrder>
}

export const fetchOrderThunk = createAsyncThunk<IResponse, IOrderThunkParams | undefined>('fetchOrderThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as { OrderSlice: ISliceState }

  const dataTemp = { ...state.OrderSlice.TableInfo }
  if (param) {
    dataTemp[param.key] = param.value
    dataTemp.details = param.details
  }

  const filterOptions = getConvertGraphQLFilterOptions()
  const rParams = ConvertTableToRequestGraphQLFilter<Omit<IOrder, 'CategorieNames'>, TOrder>(dataTemp, filterOptions)
  const res = await GraphQLOrderService.all(rParams, thunkAPI.signal)

  const reposeData: IResponse = {
    Result: {
      Data: mapOrdersDataTable(res.items),
      Total: res?.totalItems ?? 0,
      Amount: dataTemp.PageInfo?.pageSize ?? 25,
      Page: dataTemp.PageInfo?.page ?? 0
    },
    TableInfo: dataTemp
  }
  return reposeData
})
