import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, Fade, IconButton, Stack, alpha, styled } from '@mui/material'
import PauseIcon from '@mui/icons-material/Pause'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import { MusicDisBase64 } from '../ImageHelpers'
import { MouseEventProvider } from './MouseEventProvider'
import { AddAudioDrawListener } from './AddAudioDrawListener'

import './animation.css'

// interface ITrack {
//   title: string
//   artist: string
//   audioSrc: string
//   image: string
//   color: string
// }

interface IProps {
  tracksUrl?: string[]
  imageUrl?: string
  isNext?: boolean
  isPev?: boolean
  autoPlay?: {
    play: boolean
    EventProvider: MouseEventProvider
  }
  Id?: string
}

const getTime = (time: number) => {
  return Number.isNaN(time) ? 0 : time
}
const _bgUris = [
  'images/bg/audio-player-bg-1.png',
  'images/bg/audio-player-bg-2.png',
  'images/bg/audio-player-bg-3.png',
  'images/bg/audio-player-bg-4.png',
]

let bgIndex = new Date().getTime() % _bgUris.length

export const AudioPlayer: FC<IProps> = (props) => {
  // State
  const [trackIndex] = useState(0)
  const [trackProgress, setTrackProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const isInitial = useRef(true)
  const eleId = useRef(props.Id ?? new Date().getTime().toString())
  const isMounted = useRef(true)
  // Destructure for conciseness
  const { tracksUrl = [] } = props
  const audioSrc = tracksUrl[trackIndex]
  const bgUri = useRef(_bgUris[bgIndex])
  //Refs
  const audioRef = useRef(new Audio(audioSrc))
  if (isInitial.current) {
    audioRef.current.addEventListener('canplaythrough', () => {
      setIsLoading(false)
      if (props.autoPlay) {
        props.autoPlay.EventProvider.dispatchEvent(audioRef.current)
      }
    })
    audioRef.current.addEventListener('playing', () => {
      setIsPlaying(true)
    })
    audioRef.current.addEventListener('pause', () => {
      setIsPlaying(false)
    })
    audioRef.current.addEventListener('timeupdate', () => {
      setTrackProgress(getTime(audioRef.current.currentTime) / getTime(audioRef.current.duration))
    })
  }

  // const intervalRef = useRef<ReturnType<typeof setInterval>>()
  // const isReady = useRef(false)

  // Destructure for conciseness
  const duration = Number.isNaN(audioRef.current.duration) ? 0 : audioRef.current.duration

  function formatTime(number: number) {
    const hours = Math.floor(number / 3600)
    const minutes = Math.floor((number % 3600) / 60)
    const seconds = number % 60

    const formattedHours = String(hours).padStart(2, '0') ?? '00'
    const formattedMinutes = String(minutes).padStart(2, '0') ?? '00'
    const formattedSeconds = String(seconds).padStart(2, '0') ?? '00'

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  }

  const Play = () => {
    if (audioRef.current.paused && isMounted.current) {
      audioRef.current.play()
    }
  }

  const toPrevTrack = () => {}

  const toNextTrack = () => {}

  const onScrub = (value: string) => {
    // Clear any timers already running
    audioRef.current.currentTime = parseFloat(value) * audioRef.current.duration
    Play()
  }

  const onScrubEnd = () => {
    // If not already playing, start
    audioRef.current.pause()
  }

  const handleClickPlayPause = (status: boolean) => () => {
    if (status) {
      Play()
    } else {
      audioRef.current.pause()
    }
  }

  useEffect(() => {
    isInitial.current = false
    audioRef.current.addEventListener('click', (e) => {
      handleClickPlayPause(true)()
    })
    AddAudioDrawListener(audioRef.current, eleId.current)
  }, [eleId.current, audioRef.current])

  useEffect(() => {
    return () => {
      isMounted.current = false
      audioRef.current.pause()
      audioRef.current = new Audio()
    }
  }, [audioRef.current])

  return (
    <PlayerWrap backgroundUri={bgUri.current}>
      <WaveFormCanvanWrap>
        <Box id={eleId.current} component={'canvas'} sx={{ width: '100%' }}></Box>
      </WaveFormCanvanWrap>

      <InfoBox>
        <ImageAnimate playing={isPlaying ? 'true' : 'false'}>
          <img src={MusicDisBase64} />
        </ImageAnimate>
        <Controls>
          <CustomIconButton disabled onClick={toPrevTrack}>
            <SkipPreviousIcon />
          </CustomIconButton>
          {isPlaying ? (
            <CustomIconButton disabled={isLoading} onClick={handleClickPlayPause(false)}>
              <PauseIcon fontSize="large" />
            </CustomIconButton>
          ) : (
            <CustomIconButton disabled={isLoading} onClick={handleClickPlayPause(true)}>
              <PlayArrowIcon fontSize="large" />
            </CustomIconButton>
          )}
          <CustomIconButton disabled onClick={toNextTrack}>
            <SkipNextIcon />
          </CustomIconButton>
        </Controls>
        <Text>
          {formatTime(Math.floor(trackProgress * duration))} / {formatTime(Math.floor(duration))}
        </Text>
      </InfoBox>

      <Box sx={{ position: 'relative', height: '20px', width: '100%', px: '24px', mx: '-24px' }}>
        <Fade in={isLoading} unmountOnExit>
          <Box sx={{ textAlign: 'center', fontWeight: 600 }}>Loading...</Box>
        </Fade>
        <Fade in={!isLoading} unmountOnExit>
          <Box sx={{ position: 'absolute', width: '100%', top: 0, left: 0 }}>
            <CustomAudio
              style={{ width: '100%' }}
              type="range"
              value={trackProgress}
              step={0.001}
              min="0"
              max="1"
              onChange={(e) => onScrub(e.target.value)}
              onMouseUp={onScrubEnd}
              onKeyUp={onScrubEnd}
            />
          </Box>
        </Fade>
      </Box>
    </PlayerWrap>
  )
}

export default AudioPlayer

const PlayerWrap = styled(Stack)<{ backgroundUri?: string }>((p) => ({
  width: '100%',
  padding: '24px',
  borderRadius: '8px',
  backgroundColor: '#fafafa',
  boxShadow: '0 6px 10px rgba(0, 0, 0, 0.05)',
  margin: 'auto',
  color: '#606060',
  alignItems: 'center',
  gap: '10px',
  overflow: 'hidden',
  position: 'relative',
  // background: 'url(images/bg/audio-player-bg-4.jpg)',
  background: p.backgroundUri ? `url(${p.backgroundUri})` : undefined,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}))

const CustomAudio = styled('input')({
  WebkitAppearance: 'none',
  appearance: 'none',
  width: '100%',
  cursor: 'pointer',
  outline: 'none',
  overflow: 'hidden',
  borderRadius: '16px',
  background: alpha('#fff', 0.6),
  '&::-webkit-slider-runnable-track': {
    height: '10px',
    background: 'transparent',
    borderRadius: '16px',
  },
  '&::-moz-range-track': {
    height: '10px',
    background: '#ccc',
    borderRadius: '16px',
  },

  '&::-webkit-slider-thumb': {
    WebkitAppearance: 'none',
    appearance: 'none',
    height: '10px',
    width: '10px',
    // borderRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    backgroundColor: '#0000008a',
    // border: '1px solid #0000008a',
    boxShadow: '-407px 0 0 400px #0000008a',
  },
  '&::-moz-range-thumb': {
    height: '10px',
    width: '10px',
    backgroundColor: alpha('#fff', 0.6),
    borderRadius: '50%',
    border: '1px solid #0000008a',
    boxShadow: '-407px 0 0 400px #0000008a',
  },
})

const InfoBox = styled(Stack)({
  background: alpha('#ffffff', 0.6),
  alignItems: 'center',
  padding: '12px 24px',
  borderRadius: '6px',
  position: 'relative',
  zIndex: 10,
})

const WaveFormCanvanWrap = styled(Box)({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  left: 0,
  height: '120px',
  pointerEvents: 'none',
  transform: 'translateY(-50%)',
  opacity: '0.5',
})

const ImageAnimate = styled(Box)<{ playing?: 'true' | 'false' }>(({ playing }) => ({
  '& img': { width: '275px', height: '275px' },
  ...(playing === 'true' ? { animation: 'rotate 10s linear infinite' } : {}),
}))

const Controls = styled(Box)({
  display: 'flex',
  margin: '0 auto 15px',
  gap: '25px',
})

const CustomIconButton = styled(IconButton)({
  width: '50px',
})

const Text = styled('p')({
  fontSize: '16px',
  fontWeight: 600,
  color: '#606060',
})
