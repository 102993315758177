import React, { Component } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Text18 } from '../../../../Components/Text'
import { EPaymentMethod } from '../../../../Models/enums'

export interface IPaymentMethodAccordionConfig {
  type: EPaymentMethod
  title: string
  subTitle?: JSX.Element
  body: JSX.Element
}

interface IProps {
  config: IPaymentMethodAccordionConfig[]
  value?: EPaymentMethod
  onChange?: (value: EPaymentMethod) => void
}

export default class PaymentMethodAccordions extends Component<IProps> {
  render() {
    return (
      <Box sx={{ mt: '12px' }}>
        {this.props.config.map((item) => (
          <Accordion
            key={item.type}
            expanded={item.type === this.props.value}
            onChange={() => this.handleChange(EPaymentMethod.PayPal)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Text18 sx={{ width: '33%', flexShrink: 0 }}>{item.title}</Text18>
              {item.subTitle}
            </AccordionSummary>
            <AccordionDetails>{item.body}</AccordionDetails>
          </Accordion>
        ))}
      </Box>
    )
  }

  handleChange = (value: EPaymentMethod) => {
    this.props.onChange && this.props.onChange(value)
  }
}
