interface IConstantDefault {
  page: number
  pageCount: number
}

const ConstantDefault: IConstantDefault = {
  page: 1,
  pageCount: 12,
}

export default ConstantDefault
