import { createAsyncThunk } from '@reduxjs/toolkit'
import { IPricePackage } from '../../../Models'
import PricePackageService from '../services/PricePackageService'

interface IResults {
  pricePackages: IPricePackage[]
}

export const fetchPricingThunk = createAsyncThunk<IResults>('fetchPricingThunk', async (_, thunkAPI) => {
  const response = await PricePackageService.all(thunkAPI.signal)
  return { pricePackages: response }
})
