import { ClickAwayListener, Box, Button, ButtonGroup } from '@mui/material'
import { Component } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

interface IProps {
  onClick?: () => void
  disabled?: boolean
}
interface IState {
  isDeleting: boolean
}
class BtnDeleteV2 extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { isDeleting: false }
  }
  handlingClickAway = () => {
    this.setState({ isDeleting: false })
  }
  handlingClick = () => {
    this.setState({ isDeleting: true })
  }
  render() {
    return (
      <ClickAwayListener onClickAway={this.handlingClickAway}>
        <Box sx={{ width: '72px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '40px' }}>
          {!this.state.isDeleting && (
            <Button
              sx={{ padding: '8px', minWidth: '36px' }}
              variant="contained"
              color="error"
              onClick={this.handlingClick}
              disabled={this.props.disabled}
            >
              <DeleteOutlineIcon />
            </Button>
          )}
          {this.state.isDeleting && (
            <ButtonGroup variant="text" color="inherit" aria-label="button delete group">
              <Button sx={{ padding: '8px', minWidth: '36px' }} onClick={this.props.onClick}>
                <DoneIcon sx={{ color: '#00BE00' }} />
              </Button>
              <Button sx={{ padding: '8px', minWidth: '36px' }} onClick={this.handlingClickAway}>
                <CloseIcon sx={{ color: '#F10000' }} />
              </Button>
            </ButtonGroup>
          )}
        </Box>
      </ClickAwayListener>
    )
  }
}

export default BtnDeleteV2
