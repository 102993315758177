import { ReactNode } from 'react'
import { EOrder } from '../../../../Models/filter'
import StraightIcon from '@mui/icons-material/Straight'

interface ISetting {
  value?: EOrder
  display: string
  icon: ReactNode
}

export const SortMenuConfig: Record<EOrder, ISetting> = {
  [EOrder.Ascending]: {
    value: EOrder.Ascending,
    display: 'Name Asc',
    icon: <StraightIcon />,
  },
  [EOrder.Descending]: {
    value: EOrder.Descending,
    display: 'Name Desc',
    icon: <StraightIcon sx={{ transform: 'rotateX(180deg)' }} />,
  },
}
