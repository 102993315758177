import React, { Component } from 'react'
import { Box, styled, Container, Typography } from '@mui/material'
import { EMediaType } from '../../../../Models/enums'
import { MediaItem, ProductsSkeleton } from '../../../../Components'
import Carousel from './Carousel'
import { mapGalleryDetailToMedia, mapLinkExplore } from '../../helpers'
import { IGallery } from '@coreprj/types/models'

interface IProps {
  data: IGallery[]
}

export class VideoGalleries extends Component<IProps> {
  renderItems = (item?: IGallery) => {
    const list = item?.GalleryDetails
    if (!list || list.length <= 0) return <ProductsSkeleton items={{ xs: 3, md: 4 }} />
    return (
      <Box sx={{ marginTop: '6px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '6px' }}>
          <SubTitle>{item.Name}</SubTitle>
          <SMText {...mapLinkExplore({ searchKey: item.Name, mediaType: EMediaType.Video })}>See more</SMText>
        </Box>
        <Carousel
          data={list}
          renderItem={(e, i) => (
            <Box key={i.toString()} sx={{ padding: '7.5px' }}>
              <MediaItem data={mapGalleryDetailToMedia(e)} />
            </Box>
          )}
        />
      </Box>
    )
  }

  render() {
    return (
      <Container sx={{ marginTop: '36px' }}>
        <Title>Video Colections</Title>
        <SubTitle sx={{ fontWeight: '400' }}>Download or stream - Get unlimited access to +40,000 media</SubTitle>
        <Box sx={{ marginTop: '24px' }}>
          {this.renderItems(this.props.data[0])}
          {this.renderItems(this.props.data[1])}
        </Box>
      </Container>
    )
  }
}

export default VideoGalleries

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '36px',
  fontWeight: 700,
  textAlign: 'center',
  color: '#606060'
})

const SubTitle = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '20px',
  fontWeight: 500,
  textAlign: 'center',
  color: '#606060'
})

const SMText = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 700,
  textTransform: 'none',
  color: '#606060',
  cursor: 'pointer',
  textDecoration: 'unset !important',
  transition: 'linear 0.2s',
  borderBottom: '1px solid #fff',
  '&:hover': {
    borderBottomColor: '#1976d2',
    color: '#1976d2'
  }
})
