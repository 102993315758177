import { connect } from 'react-redux'
import { AppDispatch, RootState } from '@coreprj/redux/type'
import { CreateHocLazyUnspinner } from '@coreprj/redux/Core/HocLazy'
import NotificationService from '@coreprj/services/NotificationService'
import { NotificationSlice } from './Slice'
import { fetchNotificationThunk } from './Thunks'
import { INotificationMapDispatchRedux, INotificationMapStateRedux } from './type'
import { setSingleLocalStorageNotification } from '../Services/LocalStorageNotification'
import ViewBase from '..'

const mapStateToProps = (state: RootState): INotificationMapStateRedux => ({
  Status: state.NotificationSlice.Status,
  data: state.NotificationSlice.data.entities,
  tableInfo: state.NotificationSlice.TableInfo,
  NotificationSlice: state.NotificationSlice
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
const mapDispatchToProps = (dispatch: AppDispatch): INotificationMapDispatchRedux => {
  return {
    FetchData() {
      return dispatch(fetchNotificationThunk())
    },
    onChange(key, value, details) {
      if (key === 'FilterModel') {
        dispatch(NotificationSlice.actions.onChange({ key, value, details }))
      }
      FetchAccessory.request?.abort()
      FetchAccessory.request = dispatch(fetchNotificationThunk({ tableInfo: { key, value: value, details } }))
    },
    Create: async (model) => {},
    Update: async (Id, model) => {},
    Delete: async (Id) => {},
    ReadNotification: async (value) => {
      await NotificationService.ReadNotification(value.Id)
      setSingleLocalStorageNotification(value.Id)
      dispatch(fetchNotificationThunk())
    }
  }
}
const ComponentLazy = CreateHocLazyUnspinner(ViewBase)
export const NotificationMapRedux = connect(mapStateToProps, mapDispatchToProps)(ComponentLazy)
