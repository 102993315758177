import { IOrder, IOrderDetail } from '@coreprj/types/models'
import { TOrderDetail } from '@coreprj/services/GraphQLOrderService'
import { EOrderPayment, EOrderStatus } from '@coreprj/types/enum'

export const mapOrderDetail = (value?: TOrderDetail): IOrderDetail[] => {
  const list = value?.orderDetails ?? []
  return list.map<IOrderDetail>((item, index) => ({
    Id: item?.id ?? -1,
    OrderId: item?.orderId ?? -1,
    ResourceId: item?.resourceId ?? '',
    Price: item?.price ?? 0,
    Amount: item?.amount ?? 0,
    PricePackageId: item?.pricePackageId ?? -1,
    SocialUrl: item?.socialUrl ?? '',
    Type: item?.type ?? '',
    ResourceInfo: item?.resourceInfo ?? '',
    DateCreated: item?.dateCreated ?? ''
  }))
}

export const mapOrder = (value?: TOrderDetail): IOrder => {
  const data: IOrder = {
    Id: value?.id ?? -1,
    UserId: value?.userId ?? '',
    Name: value?.name ?? '',
    Description: value?.description ?? '',
    ReferenceId: value?.referenceId ?? '',
    Status: value?.status as EOrderStatus,
    Method: value?.method as EOrderPayment.Paypal,
    TransactionId: value?.transactionId ?? '',
    TransactionInfo: value?.transactionInfo ?? '',
    DateCreated: value?.dateCreated ?? ''
  }
  data.OrderDetail = mapOrderDetail(value)
  return data
}
