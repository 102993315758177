/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react'
import { Popover, PopoverProps } from '@mui/material'

interface IPopoverBase {
  anchorEl: HTMLElement | null
  popoverProps?: Omit<PopoverProps, 'open'>
  content?: () => JSX.Element
}

export interface IOptionPopover extends IPopoverBase {}

export type TShowPopover = (option: IOptionPopover) => void
export type TClosePopover = (reason?: 'backdropClick' | 'escapeKeyDown') => void

export interface IGlobalPopoverContext {
  showPopover: TShowPopover
  closePopover: TClosePopover
}
export const GlobalPopoverContext = React.createContext<IGlobalPopoverContext>({} as any)

export const mapGlobalPopoverContext = (context: (state: IGlobalPopoverContext) => React.ReactNode) => (
  <GlobalPopoverContext.Consumer>{context}</GlobalPopoverContext.Consumer>
)

interface IProps {}
interface IState extends IPopoverBase {}
type TProps = React.PropsWithChildren<IProps>
export default class GlobalPopover extends Component<TProps, IState> implements IGlobalPopoverContext {
  constructor(props: any) {
    super(props)
    this.state = { anchorEl: null }
  }

  render() {
    return (
      <GlobalPopoverContext.Provider value={this}>
        {this.props.children}
        <Popover
          sx={{ '& .MuiPaper-root.MuiPaper-elevation': { overflow: 'hidden' } }}
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={(_, reason) => this.closePopover(reason)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          {...this.state.popoverProps}
        >
          {this.renderContent()}
        </Popover>
      </GlobalPopoverContext.Provider>
    )
  }

  renderContent = () => {
    if (!this.state.content) return <></>
    return this.state.content()
  }

  showPopover = (option: IOptionPopover) => {
    this.setState({ ...option })
  }

  closePopover: TClosePopover = () => {
    this.setState({ anchorEl: null })
  }
}
