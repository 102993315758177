import React, { Component } from 'react'
import { Button, Container, Stack, Typography } from '@mui/material'

class PrivacyPolicyPage extends Component {
  render() {
    return (
      <Container>
        <Stack sx={{ minHeight: 'calc(100vh - 400px)', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
          <Typography variant="h5">PrivacyPolicyPage - Is in the process of development!</Typography>
          <Button variant="outlined" color="inherit" LinkComponent="a" href="/">
            Return to home page
          </Button>
        </Stack>
      </Container>
    )
  }
}
export default PrivacyPolicyPage
