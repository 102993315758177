import { IMedia } from '../../../Models'
import { CreateHttpService } from '@coreprj/graphql/base'
import ServiceBase from '@coreprj/http/ServiceBase'
import { FetchDelay } from '../../../utilities'
import { IMediaFilterDTO } from '../type'
import FilterMapping from './FilterMapping'

interface IFetchFilterResult {
  Amount: number
  Page: number
  Total: number
  Data: IMedia[]
}

class MediaServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Media`))
  }
  private urlAll = '/All'
  filter = async (filter: IMediaFilterDTO, signal?: AbortSignal): Promise<IFetchFilterResult> => {
    return FetchDelay<IFetchFilterResult>(async () => {
      try {
        const filterReq = FilterMapping.parseMedia(filter)
        const res = await this.PushNotify(this.Post<IFetchFilterResult>, this.urlAll, filterReq, { signal })
        return res
      } catch (error) {
        return { Products: [], Amount: 0, Data: [], Page: 0, Total: 0 } as IFetchFilterResult
      }
    }, 1000)
  }
}

const MediaService = new MediaServiceBase()
export default MediaService
