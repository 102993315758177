import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Grid } from '@mui/material'
import { NavigateKey } from '../../../Route'
import { Text14 } from '../../../Components/Text'
import { ELazyStatus } from '../../../Models/enums'

interface IProps {
  status?: ELazyStatus
  components: {
    payment: JSX.Element
    cart: JSX.Element
  }
}

export default class Layout extends Component<IProps> {
  render() {
    return <Container sx={{ pt: '64px', pb: '128px' }}>{this.renderContent()}</Container>
  }

  renderContent() {
    return (
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Box sx={{ minHeight: '50vh' }}>{this.props.components.payment}</Box>
          <Box sx={{ display: 'flex', alignItems: 'center', pt: '18px' }}>
            <Text14
              {...{ component: Link, to: NavigateKey.Cart }}
              sx={{ transition: 'linear 0.3s', fontWeight: 600, '&:hover': { color: '#006bd6' } }}
            >
              Continue edit
            </Text14>
          </Box>
        </Grid>
        <Grid item xs={6}>
          {this.props.components.cart}
        </Grid>
      </Grid>
    )
  }
}
