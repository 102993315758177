import React, { Component } from 'react'
import { IOrder } from '@coreprj/types/models'
import { GridCallbackDetails } from '@mui/x-data-grid'
import { ITableTemplateState, TTemplateTableType } from '@coreprj/shareds/Tables/TemplateTable'
import CRUDPannel from '@coreprj/shareds/Tables/TemplateTable/CRUDPannel'
import CustomActionPannel from './CustomActionPannel'

interface ICRUD<TModel, Id extends keyof TModel> {
  Create: (model: Partial<TModel>) => Promise<void>
  Update: (Id: TModel[Id], model: Partial<TModel>) => Promise<void>
  Delete: (Id: TModel[Id], model?: Partial<TModel>) => Promise<void>
}

export type TOnTableStateChange = <Tk extends keyof ITableTemplateState<IOrder>>(
  key: Tk,
  value: ITableTemplateState<IOrder>[Tk],
  details: GridCallbackDetails
) => void

interface IProps extends ICRUD<IOrder, 'Id'> {
  Table: TTemplateTableType<IOrder>
  onChange: TOnTableStateChange
  tableInfo: ITableTemplateState<IOrder>
  RowEdit?: (value: IOrder) => JSX.Element
  RowDelete?: JSX.Element
}

export default class OrderContent extends Component<IProps> {
  onCreate = async (data: Partial<IOrder>) => {
    return this.props.Create(data)
  }
  onEdit = async (Id: number, data: Partial<IOrder>) => {
    return this.props.Update(Id, data)
  }
  onDelete = async (Id: number, data: Partial<IOrder>) => {
    return this.props.Delete(Id, data)
  }
  onChange = <TK extends keyof ITableTemplateState<IOrder>>(key: TK, value: ITableTemplateState<IOrder>[TK], details: GridCallbackDetails<any>) => {
    this.props.onChange && this.props.onChange(key, value, details)
  }
  render() {
    const { Table } = this.props
    return (
      <Table
        CRUDPannel={(p) => <CRUDPannel Title='Orders' />}
        ActionPannel={(p) => {
          const data = p.data as IOrder
          return <CustomActionPannel Delete={this.props.RowDelete} Edit={this.props.RowEdit ? this.props.RowEdit(data) : undefined} />
        }}
        ReduxOption={this.props.tableInfo}
        onChange={this.onChange}
      />
    )
  }
}
