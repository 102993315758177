import React, { Component } from 'react'
import { Box, styled } from '@mui/material'
import { IOrder } from '@coreprj/types/models'
import { MapOprators } from '@coreprj/shareds/Tables/TemplateTable/GridOperatorCustomer'
import { CreateTableTemplate } from '@coreprj/shareds/Tables/TemplateTable'
import OrderContent from '@coreprj/shareds/Pages/OrderContent'
import TableFormater from '@coreprj/helpers/TableFormater'
import { NavigateKey } from '../../Route'
import { TOrderProps } from './Reduxes/Container'
import { CreateProfilePathname } from '../Profile/Helpers'
import StatusCell from './UI/StatusCell'
import ButtonDetail from './UI/ButtonDetail'

const INoData = () => (
  <b>
    <i>chưa thấy dữ liệu từ api</i>
  </b>
)

const Table = CreateTableTemplate<IOrder>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Name: { type: 'string', minWidth: 190, flex: 1 },
    ReferenceId: { flex: 1, minWidth: 300, headerName: 'Reference ID', renderCell: (params) => params.value ?? <INoData /> },
    TransactionId: { flex: 1, minWidth: 200, headerName: 'Transaction ID', renderCell: (params) => params.value ?? <INoData /> },
    Method: { type: 'string', minWidth: 150 },
    DateCreated: { type: 'string', minWidth: 175, headerName: 'Date created', renderCell: (params) => TableFormater.tooltipDate(params.value) },
    Status: { type: 'string', renderCell: (params) => <StatusCell status={params.value} /> }
  },
  filterOperators: MapOprators, //server mode,
  GridColumnVisibilityModel: {
    ReferenceId: false,
    TransactionId: false
  }
})

export interface IProps extends TOrderProps {}
export default class ViewBase extends Component<IProps> {
  render() {
    return (
      <Wrap>
        <OrderContent
          {...this.props}
          Table={Table}
          RowEdit={(value) => <ButtonDetail to={CreateProfilePathname(NavigateKey.OrderDetail, { id: value.Id.toString() })} />}
        />
      </Wrap>
    )
  }
}

const Wrap = styled(Box)({
  height: 'calc(100vh - var(--height-header))',
  display: 'flex',
  paddingTop: '24px',
  paddingLeft: '12px'
})
