export * from './AppConstants'

export const HttpRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

export const KeyExtractor = (item: any, index: number) => `key${index}`

export const Sleep = (sec: number) => new Promise((resolve) => setTimeout(resolve, sec))

export const FetchDelay = async function <TModel>(action: () => Promise<TModel>, sec: number) {
  const [res] = await Promise.all([action(), Sleep(sec)])
  return res
}

export const WindowScrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, 50)
}

export const FormatTiming = (num: number) => {
  if (num === 0) return '00:00'
  const hours = Math.floor(num / 3600)
  const minutes = Math.floor((num % 3600) / 60)
  const seconds = num % 60

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export const FormatDatetime = (value?: string) => {
  if (!value) return 'No time'
  const dt = new Date(value)
  const date = String(dt.getDate()).padStart(2, '0')
  const month = String(dt.getMonth()).padStart(2, '0')
  const year = dt.getFullYear()
  return `Date Added: ${month}-${date}-${year}`
}

export const FormatFileSize = (sizeInKb: number) => {
  if (sizeInKb < 1024) {
    return sizeInKb + ' Kb'
  } else if (sizeInKb < 1024 * 1024) {
    return (sizeInKb / 1024).toFixed(2) + ' Mb'
  } else if (sizeInKb < 1024 * 1024 * 1024) {
    return (sizeInKb / (1024 * 1024)).toFixed(2) + ' Gb'
  } else {
    return (sizeInKb / (1024 * 1024 * 1024)).toFixed(2) + ' Tb'
  }
}

export const AreObjectsEqual = (obj1: any, obj2: any) => {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Kiểm tra số lượng keys
  if (keys1.length !== keys2.length) {
    return false
  }

  // Kiểm tra các keys có giống nhau không
  for (let key of keys1) {
    if (!keys2.includes(key)) {
      return false
    }
  }

  // Kiểm tra giá trị của từng key
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  // Hai object giống nhau
  return true
}

export default class Utilities {
  static formatDatetime = (value?: string) => {
    if (!value) return 'No time'
    const dt = new Date(value)
    const date = String(dt.getDate()).padStart(2, '0')
    const month = String(dt.getMonth()).padStart(2, '0')
    const year = dt.getFullYear()
    return `Date Added: ${month}-${date}-${year}`
  }

  static formatFileSize = (sizeInKb: number) => {
    if (sizeInKb < 1024) {
      return sizeInKb + ' Kb'
    } else if (sizeInKb < 1024 * 1024) {
      return (sizeInKb / 1024).toFixed(2) + ' Mb'
    } else if (sizeInKb < 1024 * 1024 * 1024) {
      return (sizeInKb / (1024 * 1024)).toFixed(2) + ' Gb'
    } else {
      return (sizeInKb / (1024 * 1024 * 1024)).toFixed(2) + ' Tb'
    }
  }

  static isValidPhoneNumber(phoneNumber: string): boolean {
    // Biểu thức chính quy để kiểm tra số điện thoại
    const phoneRegex = /^(\+?\d{1,4}[\s-]?)?((\(\d{1,4}\))|\d{1,4})[\s-]?\d{1,4}[\s-]?\d{1,9}$/
    return phoneRegex.test((phoneNumber ?? '').trim())
  }
}
