import React, { Component } from 'react'
import { EOrderStatus } from '@coreprj/types/enum'
import { Box, Typography, styled } from '@mui/material'

const StatusColors: { [key in EOrderStatus]: string } = {
  [EOrderStatus.Draft]: 'linear-gradient(to right,#dedede,#f0f0f0)',
  [EOrderStatus.Pending]: 'linear-gradient(to right,#0d6efd,#82baff)',
  [EOrderStatus.Done]: 'linear-gradient(to right,#357a38,#1edd9b)',
  [EOrderStatus.Error]: 'linear-gradient(to right,#e72134,#eba1a1)'
}

interface IProps {
  status: EOrderStatus
}

export default class StatusCell extends Component<IProps> {
  render() {
    const { status } = this.props
    return (
      <Wrapper>
        <CustomTypography sx={{ background: (StatusColors as any)[status] }}>{status}</CustomTypography>
      </Wrapper>
    )
  }
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%'
})

const CustomTypography = styled(Typography)({
  borderRadius: '4px',
  fontSize: '0.8rem',
  padding: '4px 7px',
  fontWeight: '400',
  minWidth: '70px',
  textAlign: 'center',
  color: '#fff'
})
