import React, { Component } from 'react'
import { Box, Button, Divider, styled } from '@mui/material'
import { Text14, Text18 } from '../../../Components/Text'
import UiStyleds from '../ui/styleds'
import { Link } from 'react-router-dom'
import { NavigateKey } from '../../../Route/NavigateKey'

const textContentHeight = '85px'

export class BackLinkPricing extends Component {
  render() {
    return (
      <UiStyleds.FormWrap>
        <TextTitle>Privacy policy</TextTitle>
        <Divider />
        <TextContent>
          You can read our policy here before using the service. That will ensure the safety of your digital assets
        </TextContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: '9px', pr: '12px' }}>
          <Button color="primary" sx={{ fontWeight: 600, textTransform: 'unset' }} component={Link} to={NavigateKey.Policy}>
            Read
          </Button>
        </Box>
      </UiStyleds.FormWrap>
    )
  }
}

export class BackLinkFQAs extends Component {
  render() {
    return (
      <UiStyleds.FormWrap>
        <TextTitle>FQAs</TextTitle>
        <Divider />
        <TextContent>Questions are frequently asked by our customers. Hope it helps you</TextContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: '9px', pr: '12px' }}>
          <Button color="primary" sx={{ fontWeight: 600, textTransform: 'unset' }} component={Link} to={NavigateKey.FQAs}>
            Read
          </Button>
        </Box>
      </UiStyleds.FormWrap>
    )
  }
}

const TextTitle = styled(Text18)({
  fontWeight: 600,
  marginTop: '-6px',
  paddingBottom: '6px',
})

const TextContent = styled(Text14)({
  textAlign: 'justify',
  height: textContentHeight,
  padding: '12px 24px 12px 0',
})
