import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Divider, Stack, Typography, styled } from '@mui/material'
import { NavigateKey } from '../../../Route'
import { MediaTypeTabsMapRedux } from '../../Explore/redux/Map'

interface IProps {}

const HeaderNav: FC<IProps> = (props) => {
  const navigate = useNavigate()

  const navigateSubmitVideoScreen = () => {
    navigate(NavigateKey.SubmitMedia, { preventScrollReset: true })
  }

  const navTo = (key: NavigateKey) => () => {
    navigate(key)
  }

  const scrollToPricing = () => {
    const targetElement = document.getElementById('PricingSection')
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' })
    } else {
      navTo(NavigateKey.Pricing)()
    }
  }

  return (
    <Stack direction="row" gap="25px" sx={{ display: { xs: 'none', md: 'flex' } }}>
      <MediaTypeTabsMapRedux />
      <Divider orientation="vertical" sx={{ height: '28px' }} />
      <CustomStack onClick={navTo(NavigateKey.FQAs)}>
        <Text>FQAs</Text>
      </CustomStack>
      <CustomStack onClick={scrollToPricing}>
        <Text>Pricing</Text>
      </CustomStack>
      <CustomStack onClick={navigateSubmitVideoScreen}>
        <Text>Submit media</Text>
      </CustomStack>
    </Stack>
  )
}
export default HeaderNav

const Text = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: '500',
  whiteSpace: 'nowrap',
})

const CustomStack = styled(Box)({
  display: 'flex',
  gap: '5px',
  color: '#606060',
  cursor: 'pointer',
  borderBottom: '1px solid transparent',
  transition: 'all linear 0.2s',
  '&.active': {
    color: '#1976D2',
    borderBottom: '1px solid #1976D2',
  },
  '&:hover': {
    borderBottom: '1px solid #606060',
  },
})
