export const Sleep = (sec: number) => new Promise((res) => setTimeout(res, sec))

export const KeyExtractor = (item: any, index: number) => 'key' + index

export const FetchDelay = async function <TModel>(action: () => Promise<TModel>, sec: number) {
  const [res] = await Promise.all([action(), Sleep(sec)])
  return res
}

export const IsValidEmail = (value: string) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return regex.test(value)
}

export const IsValidPhoneNumber = (phoneNumber: string): boolean => {
  // Biểu thức chính quy để kiểm tra số điện thoại
  const phoneRegex = /^(\+?\d{1,4}[\s-]?)?((\(\d{1,4}\))|\d{1,4})[\s-]?\d{1,4}[\s-]?\d{1,9}$/
  return phoneRegex.test((phoneNumber ?? '').trim())
}
