import { createAsyncThunk } from '@reduxjs/toolkit'
import { INotification } from '@coreprj/types/models'
import { GridCallbackDetails } from '@mui/x-data-grid'
import { IPagingModel } from '@coreprj/shareds/Tables/ModelFilter'
import { ConvertTableToRequestGraphQLFilter } from '@coreprj/http/Getway'
import { ITableTemplateState } from '@coreprj/shareds/Tables/TemplateTable'
import NotificationService, { TNotificationInfo } from '@coreprj/services/NotificationService'
import { INotificationStateSlice } from './type'
import { setLocalStorageNotification } from '../Services/LocalStorageNotification'
import { getConvertGraphQLFilterOptions, getReadedNotificationIds, mapNotificationsDataTable } from '../Services/DataMapping'

interface ITableTemplateInfoThunk {
  key: keyof ITableTemplateState<INotification>
  value: any
  details: GridCallbackDetails
}

interface ITableTemplateParamsThunk {
  tableInfo?: ITableTemplateInfoThunk
}

interface ITableTemplateReponseThunk {
  Result?: IPagingModel<INotification>
  TableInfo: ITableTemplateState<INotification>
}

// ========= ========= ========= Main Redux ========= ========= =========
interface IParamsThunk extends ITableTemplateParamsThunk {}

interface IResponseThunk extends ITableTemplateReponseThunk {}

export const fetchNotificationThunk = createAsyncThunk<IResponseThunk, IParamsThunk | undefined>(
  'fetchNotificationThunk',
  async (param, thunkAPI) => {
    const state = thunkAPI.getState() as { NotificationSlice: INotificationStateSlice }

    // ========= Table =========
    const dataTemp = { ...state.NotificationSlice.TableInfo }
    if (param?.tableInfo) {
      dataTemp[param.tableInfo.key] = param.tableInfo.value
      dataTemp.details = param.tableInfo.details
    }

    const filterOptions = getConvertGraphQLFilterOptions()
    const rParams = ConvertTableToRequestGraphQLFilter<Partial<INotification>, TNotificationInfo>(dataTemp, filterOptions)
    const res = await NotificationService.All(rParams, thunkAPI.signal)

    // ========= LocalStorage =========
    const notificationIds = getReadedNotificationIds(res?.NotificationInfos?.items)
    if (notificationIds.length > 0) setLocalStorageNotification(notificationIds)

    // ========= Return =========
    const dataResponse: IResponseThunk = {
      Result: {
        Data: mapNotificationsDataTable(res?.NotificationInfos?.items),
        Amount: dataTemp.PageInfo.pageSize ?? 0,
        Page: dataTemp.PageInfo.page ?? 0,
        Total: res?.NotificationInfos?.totalItems ?? dataTemp.PageInfo.rowTotal ?? 0
      },
      // Result: { Data: [], Amount: 0, Page: 0, Total: 0 },
      TableInfo: dataTemp
    }
    return dataResponse
  }
)
