import { IOrder } from '@coreprj/types/models'
import { PayloadAction } from '@reduxjs/toolkit'
import { GridCallbackDetails } from '@mui/x-data-grid'
import { ITableTemplateState } from '@coreprj/shareds/Tables/TemplateTable'
import { fetchOrderThunk } from './Thunks'
import { ISliceBase, LazyStatus } from '@coreprj/redux/Core/types'
import { CreateCRUDSlice2 } from '@coreprj/redux/Core/CRUDSlice2'

export interface ISliceState extends ISliceBase<IOrder> {
  TableInfo: ITableTemplateState<IOrder>
}
export const OrderSlice = CreateCRUDSlice2<IOrder>()({
  name: 'OrderSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    return {
      ...x,
      TableInfo: {
        isLoading: true,
        PageInfo: {
          data: [],
          page: 0,
          pageSize: 25
        },
        GridSortModel: [{ field: 'DateCreated', sort: 'desc' }]
      }
    } as ISliceState
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchOrderThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        const { Result, TableInfo } = action.payload

        state.Status = LazyStatus.Loaded
        state.TableInfo = {
          ...TableInfo,
          isLoading: false,
          PageInfo: {
            data: Result?.Data ?? [],
            page: Result?.Page ?? 0,
            pageSize: Result?.Amount ?? 100,
            rowTotal: Result?.Total
          }
        }
      })
      .addCase(fetchOrderThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = LazyStatus.Error
        state.TableInfo.isLoading = true
      })
      .addCase(fetchOrderThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = LazyStatus.Loading
        state.TableInfo.isLoading = true
      })
  },
  reducers: {
    onChange: (state, action: PayloadAction<{ key: keyof ITableTemplateState<IOrder>; value: any; details: GridCallbackDetails }>) => {
      state.TableInfo[action.payload.key] = action.payload.value
      state.TableInfo.details = action.payload.details
    }
  }
})
