import React, { Component } from 'react'
import { Stack } from '@mui/material'

interface IProps {
  isLoading?: boolean
  components: {
    search: JSX.Element
    ourPartner: JSX.Element
    category: JSX.Element
    video: JSX.Element
    audio: JSX.Element
    package: JSX.Element
  }
}

export default class Layout extends Component<IProps> {
  render() {
    return (
      <Stack sx={{ backgroundColor: '#FFFFFF', overflowX: 'hidden', gap: '75px' }}>
        {this.props.components.search}
        {this.props.components.ourPartner}
        {this.props.components.category}
        {this.props.components.video}
        {this.props.components.audio}
        {this.props.components.package}
      </Stack>
    )
  }
}
