import React, { Component } from 'react'
import { Box, Chip, Fade } from '@mui/material'

interface IProps {
  data: string[]
  selected?: string
  onClick?: (value?: string) => void
}

export default class RecommendedCategories extends Component<IProps> {
  render() {
    return (
      <Fade in={this.props.data.length > 0}>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          <Chip label="All" variant={!this.props.selected ? 'filled' : 'outlined'} onClick={() => this.handleClick()} />
          {this.props.data.map((e, i) => (
            <Chip
              key={i}
              label={e}
              variant={this.props.selected === e ? 'filled' : 'outlined'}
              onClick={() => this.handleClick(e)}
            />
          ))}
        </Box>
      </Fade>
    )
  }

  handleClick = (value?: string) => {
    this.props.onClick && this.props.onClick(value)
  }
}
