import { FC } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { Box, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { NavigateKey } from '../../Route/NavigateKey'
import { IMediaItemProps, IProducItemProps } from './type'
import { AppConstant, FormatDatetime, FormatTiming } from '../../utilities'

interface IProps extends IProducItemProps, IMediaItemProps {}

const VideoBox: FC<IProps> = (props) => {
  const getImageUrl = () => {
    if (!!props.data.ResourceId) return `url(${AppConstant.baseImageUrl + props.data.ResourceId})`
    return `url(${process.env.PUBLIC_URL + '/images/error.png'})`
  }

  const componentLink = () => {
    const path = generatePath(NavigateKey.Detail, { type: props.data.Type.toLocaleLowerCase(), id: props.data.Id.toString() })
    return { component: Link, to: path, target: props.isBlank ? '_blank' : '_self' }
  }

  return (
    <Main>
      <Box sx={{ position: 'relative' }}>
        <CustomImage>
          <Box className="box-background" sx={{ backgroundImage: getImageUrl() }} />
        </CustomImage>
        <BoxHover className="box-horver" {...componentLink()} />
        <ControlPopsition className="box-icon" sx={{ top: '25px' }}>
          <Tooltip title="Detail and purchase" placement="left">
            <IconButton {...componentLink()}>
              <AddShoppingCartIcon fontSize="small" sx={{ color: '#0ED197' }} />
            </IconButton>
          </Tooltip>
        </ControlPopsition>
        {/* <IconBox className="box-icon" sx={{ top: '70px' }}>
          <CopyBox content={props.data.Name} sxIconProps={{ color: '#0ED197' }} />
        </IconBox> */}
      </Box>
      <Stack gap="5px" color="#606060" px="5px">
        <Title>{props.data.Name ?? 'Exo 2/Bold/16px'}</Title>
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">{FormatDatetime(props.data.DateCreated)}</Typography>
          <Box flex={1} />
          <Stack direction="row" alignItems="center">
            <AccessTimeIcon />
            <Text>{FormatTiming(props.data.Duration)}</Text>
          </Stack>
        </Stack>
      </Stack>
    </Main>
  )
}

export default VideoBox

const Main = styled(Stack)(({ theme }) => ({
  maxWidth: '400px',
  borderRadius: '6px',
  backgroundColor: '#fff',
  overflow: 'hidden',
  gap: '10px',
  boxShadow: '0 2px 14px rgba(0,0,0,0)',
  transition: 'all linear 0.3s',
  '&:hover .box-horver, &:hover .box-icon': { opacity: 1 },
  [theme.breakpoints.down('md')]: { '& .box-horver, & .box-icon': { opacity: 1 } },
  '&:hover .box-background': { transform: 'translate(-50%, -50%) scale(1.1)' },
}))

const CustomImage = styled('div')({
  width: '100%',
  paddingBottom: '82%',
  overflow: 'hidden',
  borderRadius: '6px',
  position: 'relative',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  background: '#fafafa',
  '& .box-background': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transform: 'translate(-50%, -50%) scale(1)',
    transition: 'all .3s',
  },
})

const BoxHover = styled(Box)({
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  transition: 'all linear 0.3s',
  opacity: 0,
  borderRadius: '4px',
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%)',
})

const ControlPopsition = styled('div')({
  borderRadius: '8px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 14px rgba(0,0,0,0.23)',
  position: 'absolute',
  right: '10px',
  transition: 'all linear 0.3s',
  opacity: 0,
})

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 700,
  flexGrow: 0,
  flexBasis: 'auto',
  padding: '5px 0',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060',
})
