
export const Sleep = (sec: number) => new Promise((resolve) => setTimeout(resolve, sec))
export const FetchDelay = async function <TModel>(action: () => Promise<TModel>, sec: number) {
  const [res] = await Promise.all([action(), Sleep(sec)])
  return res
}

export const isGuid = (value: string): boolean => {
  const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  return guidRegex.test(value)
}

