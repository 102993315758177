import React, { Component } from 'react'
import { Backdrop, Box, Modal, SxProps, styled } from '@mui/material'

interface IBase {
  sx?: SxProps
  sxWrap?: SxProps
  backdropActivated?: boolean
}

export interface OptionModal extends IBase {
  ContentModal?: () => JSX.Element
}
interface GlobalModalState extends IBase {
  ContentModal?: React.JSXElementConstructor<any>
}
interface GlobalModalProps {}

export interface IGlobalModalContext {
  ShowModal: (option: OptionModal) => void
  CloseModal: () => void
}
export const GlobalModalContext = React.createContext<IGlobalModalContext>({} as any)

export enum ContentPosition {
  Center
}
export interface IMapGlobalModal {
  context: IGlobalModalContext
}

export const mapGlobalModalContext = (context: (state: IGlobalModalContext) => React.ReactNode) => (
  <GlobalModalContext.Consumer>{context}</GlobalModalContext.Consumer>
)

export type TShowModal = (option: OptionModal) => void
type TCloseModal = (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void

export default class GlobalModal
  extends Component<React.PropsWithChildren<GlobalModalProps>, GlobalModalState>
  implements IGlobalModalContext
{
  constructor(props: any) {
    super(props)
    this.state = {}
  }
  ShowModal: TShowModal = (option: OptionModal) => {
    this.setState({
      ContentModal: option.ContentModal,
      sx: option.sx,
      sxWrap: option.sxWrap,
      backdropActivated: option.backdropActivated
    })
  }
  CloseModal: TCloseModal = (_, reason) => {
    if (reason === 'backdropClick' && !this.state.backdropActivated) return
    this.setState({ ContentModal: undefined })
  }
  GenerateContent = (): JSX.Element => {
    const Content = this.state.ContentModal ?? (() => <></>)
    const Temp = React.forwardRef(() => <Content />)
    return <Temp />
  }
  render() {
    return (
      <GlobalModalContext.Provider value={this}>
        {this.props.children}
        <Modal
          open={!!this.state.ContentModal}
          onClose={this.CloseModal}
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 300 } }}
          sx={this.state.sx}
        >
          <Wrapper sx={this.state.sxWrap}>{this.GenerateContent()}</Wrapper>
        </Modal>
      </GlobalModalContext.Provider>
    )
  }
}

const Wrapper = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none'
})

// const style = {
//   position: 'absolute' as 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3
// }
