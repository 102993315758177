import Graphql from '@coreprj/graphql/base'
import GraphQLServiceBase from '@coreprj/http/GraphQLServiceBase'

export type TOrder = Graphql.QMediaStore.TOrder
type TOrdersFilterParams = Graphql.QMediaStore.TOrdersFilterParams
type TAllResult = Graphql.TGraphQlResult<TOrder>

export type TOrderDetail = Graphql.QMediaStore.TOrderDetail
type TOrderFilterParams = Graphql.QMediaStore.TOrderFilterParams

class GraphQLOrderService extends GraphQLServiceBase {
  constructor() {
    super()
  }

  all = async (params: TOrdersFilterParams, signal?: AbortSignal): Promise<TAllResult> => {
    const res = await this.graphql.Query(this.queries.OrdersQuery(params), { signal, delay: 700 })
    return res.mediaStore.orders as TAllResult
  }

  single = async (params: TOrderFilterParams, signal?: AbortSignal): Promise<TOrderDetail> => {
    const res = await this.graphql.Query(this.queries.OrderDetailsQuery(params), { signal, delay: 700 })
    return res.mediaStore.order as TOrderDetail
  }
}
export default new GraphQLOrderService()
