// Disable SSL certificate verification
// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

// export const main = async () => {
//   const data = await GraphqlAdminClient.Query(routeNote({ take: 10, skip: 0 }))
//   console.log(data)
// }
// main()

import * as Graphql from './Requests/GraphqlRequest'

export * from './models'
export * from './utilities'
export * from './Requests/helper'
export { CreateHttpService } from './Requests/Getway'

export default Graphql
