import { Link } from 'react-router-dom'
import { IJsonContent, IPricePackageDTO } from '../type'
import { NavigateKey } from '../../../Route'

class HelpersBase {
  getServicesByCart = (value: IPricePackageDTO): IJsonContent => {
    try {
      return JSON.parse(value.JsonContent ?? '')
    } catch (error: any) {
      console.log(`Parse price package service error: ${error.message}`)
      return {}
    }
  }

  mapLinkSubmitMedia = (isBlank?: boolean) => {
    return { component: Link, to: NavigateKey.SubmitMedia, target: isBlank ? '_blank' : '_self' }
  }
}
const Helpers = new HelpersBase()
export default Helpers
