import { Component } from 'react'
import { Box, Button, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip, Typography, alpha, styled } from '@mui/material'
import { ICartDTO, ICartInfoDTO, IChooseLicenseReduxProps } from './type'
import { IMedia, IPricePackage } from '../../Models'
import { ChooseLicenseSkeleton } from '../../Components'
import Helpers from './helpers'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Link } from 'react-router-dom'
import { NavigateKey } from '../../Route'

interface IProps extends IChooseLicenseReduxProps {
  media?: IMedia
  pricePackages: IPricePackage[]
}

interface IState {
  pricePackageId?: string
}

class ChooseLicense extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const pricePackageId = this.getPricePackageId()?.toString() ?? ''
    this.state = { pricePackageId }
  }

  render() {
    if (!this.props.media) return <ChooseLicenseSkeleton />
    return (
      <Container sx={{ padding: { xs: '10px 0', md: '30px 15px 10px' } }}>
        <Text>For social media, YouTube, blogs & educational use.</Text>
        <Box sx={{ display: 'flex', alignItems: 'center', pr: '6px' }}>
          <Text18 sx={{ flex: 1 }}>Which license do ss I need?</Text18>
          <Tooltip title='Read more about our services' placement='bottom-end'>
            <CustomIconButton size='small' {...{ component: Link, to: NavigateKey.Pricing }}>
              <HelpOutlineIcon />
            </CustomIconButton>
          </Tooltip>
        </Box>
        <RadioGroup
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={this.state.pricePackageId}
          onChange={this.handleChange}
          sx={{ mt: '9px' }}
        >
          {this.renderRadioItems()}
        </RadioGroup>
        <Button variant='contained' color='success' onClick={this.handleSubmit} disabled={this.isDisabledSubmit()}>
          Add to Cart
        </Button>
      </Container>
    )
  }

  renderRadioItems = () => {
    return this.props.pricePackages.map((e, i) => (
      <FormControlLabel
        key={i}
        value={e.Id}
        control={<Radio />}
        sx={{ '& .MuiTypography-root': { width: '100%' } }}
        label={
          <CustomLabel>
            <BigText>{e.Name}</BigText>
            <BigText>${e.Price}</BigText>
          </CustomLabel>
        }
      />
    ))
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const check1 = prevProps.CartSlice.carts.ids.length !== this.props.CartSlice.carts.ids.length
    const check2 = prevProps.media?.Id !== this.props.media?.Id
    if (check1 || check2) {
      const id = this.getPricePackageId()
      this.setState({ pricePackageId: id?.toString() ?? '' })
    }
  }

  getPricePackageId = () => {
    const list = Object.values(this.props.CartSlice.carts.entities)
    const pricePackage = list.find((e) => e?.id === this.props.media?.Id.toString())
    return pricePackage?.pricePackage?.Id
  }

  handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    this.setState({ pricePackageId: value })
  }

  isDisabledSubmit = () => {
    const pricePackage = this.props.pricePackages.find((e) => e.Id.toString() === this.state.pricePackageId)
    const pricePackageId = this.getPricePackageId()
    return !this.state.pricePackageId || pricePackage?.Id.toString() === pricePackageId?.toString()
  }

  handleSubmit = () => {
    const pricePackage = this.props.pricePackages.find((e) => e.Id.toString() === this.state.pricePackageId)
    if (!pricePackage || !this.props.media) return
    const infos: ICartInfoDTO[] = [
      { key: 'Type', value: this.props.media.Type },
      { key: 'Price', value: `$ ${(pricePackage.Price ?? 0).toFixed(2)}` }
    ]
    const cart: ICartDTO = {
      id: this.props.media.Id.toString(),
      name: this.props.media.Name ?? '',
      description: this.props.media.Description,
      type: this.props.media.Type,
      imageUri: Helpers.getImageUrl(this.props.media.ResourceId ?? ''),
      infos,
      pricePackage: pricePackage
    }
    this.props.addCart(cart)
  }
}
export default ChooseLicense

const Text18 = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontWeight: 500,
  color: '#606060',
  fontSize: '18px'
})

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  backgroundColor: '#FFFFFF'
})

const Text = styled('div')({
  fontSize: '14px',
  fontWeight: 400,
  color: '#878787'
})

const CustomLabel = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})

const BigText = styled('h4')({
  display: 'inline-block',
  fontSize: '20px',
  fontWeight: 700
})

const CustomIconButton = styled(IconButton)({
  color: alpha('#0073e6', 0.8),
  '&:hover': {
    color: '#0073e6'
  }
})
