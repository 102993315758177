import { hocConnect } from '../../../Redux/hocs/hocConnect'
import { AppDispatch, RootState } from '../../../Redux/type'
import { fetchProfileThunk, fetchProfileUpdateThunk } from './Thunks'
import { IProfileDispatchRedux, IProfileStateRedux, TProfileMapRedux } from '../type'
import ViewBase from '../ViewBase'

const mapStateToProps = (state: RootState): IProfileStateRedux => ({
  Status: state.ProfileSlice.Status,
  ProfileSlice: state.ProfileSlice
})

const mapDispatchToProps = (dispatch: AppDispatch): IProfileDispatchRedux => {
  return {
    fetchInitial: () => {
      return dispatch(fetchProfileThunk())
      // return { abort: () => WindowScrollToTop() }
    },
    updateProfile: async (value) => {
      dispatch(fetchProfileUpdateThunk(value))
    }
  }
}
export const ProfileMapRedux: TProfileMapRedux = hocConnect(ViewBase, mapStateToProps, mapDispatchToProps)
