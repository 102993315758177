import { authService } from 'partner-oidc-auth'
import { EAppName, RoleKeyInternalSite } from '../models'

class AppProfileBase {
  window: Window & { [key: string]: any }
  constructor() {
    this.window = global.window
  }

  getAppName = () => this.window.env.REACT_APP_NAME as EAppName

  isAdmin = (roles?: string[]) => {
    return (roles ?? []).some((e) => e === RoleKeyInternalSite.Admin) && this.getAppName() === EAppName.ClientAdmin
  }

  getGraphqlSite = async () => {
    const roles = await authService.getRoles()
    const appName = this.getAppName()
    switch (appName) {
      case EAppName.ClientAdmin:
        return this.isAdmin(roles) ? RoleKeyInternalSite.Admin : RoleKeyInternalSite.Manage
      case EAppName.ClientApp:
      default:
        return RoleKeyInternalSite.User
    }
  }
}

export const AppProfile = new AppProfileBase()
