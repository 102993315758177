import { combineReducers } from 'redux'
import AppStatusSlice from './Reducers/Slices/AppStatusSlice'

import HomeSlice from '../Views/Home/redux/Slice'
import CartSlice from '../Views/Cart/redux/Slice'
import ExploreSlice from '../Views/Explore/redux/Slice'
import PricingSlice from '../Views/Pricing/redux/Slice'
import ProfileSlice from '../Views/Profile/redux/Slice'
import CheckOutSlice from '../Views/CheckOut/redux/Slice'
import MediaDetailSlice from '../Views/MediaDetail/redux/Slice'
import { OrderSlice } from '../Views/Order/Reduxes/Slice'
import { OrderDetailSlice } from '../Views/OrderDetail/Redux/Slice'
import { NotificationSlice } from '../Views/Notification/Redux/Slice'

const CombinedReducers = combineReducers({
  AppStatusSlice: AppStatusSlice.reducer,
  HomeSlice: HomeSlice.reducer,
  PricingSlice: PricingSlice.reducer,
  ExploreSlice: ExploreSlice.reducer,
  MediaDetailSlice: MediaDetailSlice.reducer,
  CartSlice: CartSlice.reducer,
  CheckOutSlice: CheckOutSlice.reducer,
  ProfileSlice: ProfileSlice.reducer,
  OrderSlice: OrderSlice.reducer,
  OrderDetailSlice: OrderDetailSlice.reducer,
  NotificationSlice: NotificationSlice.reducer
})

export default CombinedReducers
