import { FC } from 'react'
import { connect } from 'react-redux'
import QueryParam from '@coreprj/helpers/QueryParam'
import { fetchOrderDetailThunk } from './Thunks'
import { IOrderDetailMapDispatchRedux, IOrderDetailMapStateRedux, IOrderDetailProps, IOrderDetailRequired } from './type'
import ViewBase from '..'
import { AppDispatch, RootState } from '@coreprj/redux/type'
import { CreateHocLazy } from '@coreprj/redux/Core/HocLazy'

const mapStateToProps = (state: RootState): IOrderDetailMapStateRedux => ({
  Status: state.OrderDetailSlice.Status,
  currentId: state.OrderDetailSlice.currentId,
  dataDetail: state.OrderDetailSlice.dataDetail
})

const mapDispatchToProps = (dispatch: AppDispatch): IOrderDetailMapDispatchRedux => {
  return {
    FetchData(params) {
      const id = Array.isArray(params) ? params[0].id : params.id
      return dispatch(fetchOrderDetailThunk({ dataRequired: { id } }))
    },
    Create: async (model) => {},
    Update: async (Id, model) => {},
    Delete: async (Id) => {}
  }
}

const OrderDetailLazy = CreateHocLazy<IOrderDetailProps, IOrderDetailRequired>(ViewBase)
const WrapParam: FC<IOrderDetailProps> = (p) => {
  const params = QueryParam.Gets<IOrderDetailRequired>('id')
  return <OrderDetailLazy {...p} params={{ id: parseInt(params.id?.toString() ?? '-1') }} />
}
export const OrderDetailMapRedux = connect(mapStateToProps, mapDispatchToProps)(WrapParam)
