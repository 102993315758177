import EventEmitter from 'events'
import React from 'react'
import { EMediaType } from '../../../Models/enums'

interface KeyAudioEvent {
  play: (type: EMediaType) => void
}

type TMap = Record<keyof KeyAudioEvent, any[]>

export class AudioSingleEventEmitter extends EventEmitter<TMap> {
  constructor() {
    super()
    this.eventKeys = new Set<string>()
  }
  eventKeys: Set<string>
  addListener<K extends keyof KeyAudioEvent>(eventName: K, listener: KeyAudioEvent[K]) {
    if (!this.eventKeys.has(eventName)) {
      this.eventKeys.add(eventName)
    } else {
      super.removeAllListeners(eventName)
    }
    super.addListener(eventName, listener as any)
    return this
  }
  removeListener<K extends keyof KeyAudioEvent>(eventName: K, listener: KeyAudioEvent[K]) {
    if (this.eventKeys.has(eventName)) {
      this.eventKeys.delete(eventName)
    }
    super.removeAllListeners(eventName)
    return this
  }
}

export interface IRecommendedMediasContext {
  audioEvents: AudioSingleEventEmitter
}
export const RecommendedMediasContext = React.createContext<IRecommendedMediasContext>({} as any)
