import { IPrice } from '@coreprj/types/models'
import { CRUDServiceBase } from '@coreprj/http/CRUDServiceBase'

class PriceServiceBase extends CRUDServiceBase<IPrice, number> {
  constructor() {
    super(`/api/v1/guest/Price`)
  }
}
const PriceService = new PriceServiceBase()
export default PriceService
