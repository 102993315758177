import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container, styled } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import OrderDetailContent from '@coreprj/shareds/Pages/OrderDetailContent'
import { NavigateKey } from '../../Route'
import { TextBase } from '../../Components/Text'
import { IOrderDetailProps } from './Redux/type'

interface IProps extends IOrderDetailProps {}

export default class ViewBase extends Component<IProps> {
  render() {
    return (
      <Container maxWidth={false}>
        <ButtonBack to={`${NavigateKey.Profile}${NavigateKey.Order}`}>
          <KeyboardBackspaceIcon />
          <TextBase sx={{ fontSize: '16px', fontWeight: 600 }}>back</TextBase>
        </ButtonBack>
        <OrderDetailContent key={new Date().getTime().toString()} data={this.props.dataDetail} />
      </Container>
    )
  }
}

const ButtonBack = styled(Link)({
  paddingTop: '32px',
  display: 'flex',
  alignItems: 'center',
  gap: '9px',
  textDecoration: 'none!important',
  color: '#818181',
  '&:hover': { color: '#006bd6' }
})
