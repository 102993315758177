import { Dictionary } from '@reduxjs/toolkit'
import { ICartDTO } from '../type'
import { EMediaType } from '../../../Models/enums'
import { ICart, IOrderDetail, IResourceInfoOrderDetail } from '../../../Models'
import { EPriceType } from '@coreprj/types/enum'

export class DataMapping {
  static cartsCheckout = (value: ICartDTO[]): ICart[] => {
    return value.map<ICart>((item) => ({
      ProductId: item.id,
      PricePackageId: item.pricePackage?.Id ?? 0,
      Amount: item.jsonContent?.links?.length ?? 0,
      SocialUrl: JSON.stringify(item.jsonContent?.links ?? '[]'),
      Type: item.pricePackage?.Type ?? EPriceType.Media,
      ProductName: item.name
    }))
  }

  static cartsRedux = (value: IOrderDetail[], cartLocals: ICartDTO[]): ICartDTO[] => {
    const obj = cartLocals.reduce<Dictionary<ICartDTO>>((a, b) => {
      a[b.id] = b
      return a
    }, {})
    return value.map<ICartDTO>((item) => {
      const info: IResourceInfoOrderDetail = JSON.parse(item.ResourceInfo)
      return {
        id: item.ResourceId,
        name: info.Name,
        type: obj[item.ResourceId]?.type ?? EMediaType.Video,
        imageUri: obj[item.ResourceId]?.imageUri,
        description: info.Name,
        jsonContent: { links: JSON.parse(item.SocialUrl) },
        pricePackage: obj[item.ResourceId]?.pricePackage,
        amount: item.Amount,
        price: item.Price
      }
    })
  }
}
