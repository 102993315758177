import { FC, useEffect } from 'react'
import { Box, Button, Checkbox, Container, FormControlLabel, Stack, TextField, styled } from '@mui/material'
import { AppConstant } from '../../utilities'
import { useNavigate } from 'react-router-dom'
import { NavigateKey } from '../../Route/NavigateKey'

const imageSource = process.env.PUBLIC_URL + '/images/bg-home.png'

const SubmitMediaScreen: FC = () => {
  const navigate = useNavigate()

  const navTo = (key: NavigateKey) => () => {
    navigate(key)
  }

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <Stack alignItems="stretch">
      <CustomBgImage>
        <Content>
          <Title sx={{ color: '#fff' }}>Ready to start generating income?</Title>
        </Content>
        <Box className="box-dark" />
      </CustomBgImage>
      <Container>
        <Stack gap="10px" py="50px" alignItems="flex-start">
          <Stack direction="row" gap="10px" pb="35px">
            <Title>Have questions? Check out our</Title>
            <TitleLink onClick={navTo(NavigateKey.FQAs)}>FQAs</TitleLink>
          </Stack>
          <TextField id="Link" label="Your media repository (etc: dropbox, google drive, ...)*" variant="outlined" sx={{ width: '85%' }} />
          <TextField id="FirstName" label="First name *" variant="outlined" sx={{ width: '35%' }} />
          <TextField id="LastName" label="Last name *" variant="outlined" sx={{ width: '35%' }} />
          <TextField id="Email" label="Email address *" variant="outlined" sx={{ width: '35%' }} />
          <TextField id="Phone" label="Phone number *" variant="outlined" sx={{ width: '35%' }} />
          <FormControlLabel
            control={<Checkbox />}
            label="I have read and agree to the Terms of Service and Terms of Submission *"
          />
          <FormControlLabel control={<Checkbox />} label="I am 13 years of age or older. *" />
          <Box height="10px" />
          <Button variant="contained" color="primary" sx={{ width: '350px' }} disabled>
            Submit Media
          </Button>
        </Stack>
      </Container>
    </Stack>
  )
}

export default SubmitMediaScreen

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '36px',
  fontWeight: 700,
})

const TitleLink = styled(Title)({
  color: '#1976D2',
  transition: 'all linear 0.2s',
  cursor: 'pointer',
  borderBottom: '2px solid #1976D2',
  padding: '0 2.5px',
})

const CustomBgImage = styled('div')({
  backgroundImage: `url(${imageSource})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  display: 'flex',
  '& .box-dark': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.45)',
  },
})

const Content = styled(Container)({
  position: 'relative',
  zIndex: 1,
  height: `calc(50vh - ${AppConstant.headerHeight})`,
  display: 'flex',
  alignItems: 'flex-end',
  paddingBottom: '20px',
})
