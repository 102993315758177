import React, { Component, Fragment } from 'react'
import { Box, IconButton } from '@mui/material'
import { GlobalModalContext, IGlobalModalContext } from '@coreprj/shareds/GlobalModal'
import { ITableTemplateState, TableTemplateContext } from '@coreprj/shareds/Tables/TemplateTable'
import DeleteIcon from '@mui/icons-material/Delete'

interface IActionPannel {
  Delete?: JSX.Element
  Edit?: JSX.Element
}
const EmptyDiv = () => <></>

export default class CustomActionPannel extends Component<IActionPannel> {
  onDelete = () => {
    this.ModalContext?.ShowModal({
      backdropActivated: true,
      ContentModal: () => this.props.Delete || <EmptyDiv />,
      sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    })
  }
  getButtons = function* (that: CustomActionPannel, state: ITableTemplateState) {
    if (that.props.Edit) yield <Fragment key='Edit'>{that.props.Edit}</Fragment>
    if (that.props.Delete)
      yield (
        <IconButton onClick={that.onDelete} key={'Delete'} color='error'>
          <DeleteIcon />
        </IconButton>
      )
  }

  ModalContext?: IGlobalModalContext
  render() {
    return (
      <GlobalModalContext.Consumer>
        {(context) => {
          this.ModalContext = context
          return (
            <TableTemplateContext.Consumer>
              {({ state }) => {
                return <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', gap: '3px' }}>{Array.from(this.getButtons(this, state))}</Box>
              }}
            </TableTemplateContext.Consumer>
          )
        }}
      </GlobalModalContext.Consumer>
    )
  }
}
