import React, { Component } from 'react'
import { Grid } from '@mui/material'
import ProfileContent from '@coreprj/shareds/Pages/ProfileContent'
import { EProfileFormType } from '@coreprj/shareds/Pages/ProfileContent/type'
import { IProfileReduxProps } from './type'
import { BackLinkPricing, BackLinkFQAs } from './ui/others'

interface IProps extends IProfileReduxProps {}

export default class ViewBase extends Component<IProps> {
  render = () => {
    const data = this.props.ProfileSlice.profileContentData
    return (
      <ProfileContent
        status={this.props.Status}
        data={{
          [EProfileFormType.Personal]: data[EProfileFormType.Personal],
          [EProfileFormType.Payment]: data[EProfileFormType.Payment],
          [EProfileFormType.Business]: data[EProfileFormType.Business]
        }}
        components={{ orderView: { top: this.renderOrderViewTop() } }}
        events={{ onUpdate: this.props.updateProfile }}
      />
    )
  }

  renderOrderViewTop = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BackLinkPricing />
        </Grid>
        <Grid item xs={12} md={6}>
          <BackLinkFQAs />
        </Grid>
      </Grid>
    )
  }
}
