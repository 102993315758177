import { EPriceType } from '@coreprj/types/enum'
import { IPricePackageDTO, IPricingConfigDTO } from './type'

export const getPricingConfigs = (value: IPricePackageDTO[]): IPricingConfigDTO[] => [
  {
    type: EPriceType.Media,
    info: {
      name: 'Media package',
      description: 'For editorial use in social media, youtube videos, blogs & websites.'
    },
    data: value.filter((e) => e.Type === EPriceType.Media)
  },
  {
    type: EPriceType.Pack,
    info: {
      name: 'Pack package',
      description: 'Pack package description'
    },
    data: value.filter((e) => e.Type === EPriceType.Pack),
    disabled: true
  }
]
