import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import ListAltIcon from '@mui/icons-material/ListAlt'

interface IProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
}

export default class ButtonDetail extends Component<IProps> {
  render() {
    return (
      <Tooltip title='Details'>
        <IconButton color='info' {...{ component: Link, to: this.props.to, target: this.props.target }} sx={{ cursor: 'pointer' }}>
          <ListAltIcon />
        </IconButton>
      </Tooltip>
    )
  }
}
