import { Link } from 'react-router-dom'
import { EMediaType } from '../../../Models/enums'
import { NavigateKey } from '../../../Route'
import QueryParam from '../../../utilities/QueryParam'
import { IFilterDTO } from '../type'

class HelpersBase {
  parseId = (value?: string): number | undefined => {
    if (typeof value === 'string') return parseInt(value)
    else if (typeof value === 'number') return value
  }

  parseMediaType = (value?: string): EMediaType | undefined => {
    switch (value) {
      case EMediaType.Audio.toLocaleLowerCase():
      case EMediaType.Audio.toString():
        return EMediaType.Audio
      case EMediaType.Video.toLocaleLowerCase():
      case EMediaType.Video.toString():
      default:
        return EMediaType.Video
    }
  }

  createExploreUri = (value: IFilterDTO) => {
    const filterString = QueryParam.stringify(value)
    return `${NavigateKey.Explore}${filterString}`
  }
  
  mapLink = (uri: string, disabledBlank?: boolean) => ({
    component: Link,
    to: uri,
    target: disabledBlank ? '_self' : '_blank',
  })
  
  mapLinkExplore = (value: IFilterDTO, isBlank?: boolean) => {
    const uri = this.createExploreUri(value)
    return { component: Link, to: uri, target: isBlank ? '_blank' : '_self' }
  }
}
const Helpers = new HelpersBase()
export default Helpers


