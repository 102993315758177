export enum ELazyStatus {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error'
}

export enum ECheckOutValidateStatus {
  Pending = 'Pending',
  Loading = 'Loading',
  Valid = 'Valid'
}

export enum EMediaType {
  Video = 'Video',
  Audio = 'Audio'
}

export type ECartType = EMediaType | 'Pack'

export enum EPaymentMethod {
  PayPal = 'PayPal'
}
