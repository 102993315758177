import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createEntityAdapter,
  createSlice,
  Draft,
  EntityAdapter,
  EntityState,
  IdSelector,
  PayloadAction,
  Slice,
  SliceCaseReducers,
  Update,
  ValidateSliceCaseReducers
} from '@reduxjs/toolkit'
import { ISliceBase, LazyStatus } from './types'
import { NoInfer } from 'react-redux'
export const CreateCRUDSlice = <TModel, TSliceState extends ISliceBase<TModel> = ISliceBase<TModel>>(
  name: string,
  selectId?: IdSelector<TModel>,
  Initial: (state: ISliceBase<TModel>) => TSliceState = (s) => s as TSliceState,
  extraReducers?: (builder: ActionReducerMapBuilder<NoInfer<TSliceState>>, adapter: EntityAdapter<TModel>) => void
) => {
  const SliceAdapter = createEntityAdapter<TModel>({
    selectId
  })
  // Define the initial state using that type
  const initialState: TSliceState = Initial({
    Status: LazyStatus.Loading,
    data: SliceAdapter.getInitialState()
  })

  const ModelSlice = createSlice({
    name,
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      Update: (state, action: PayloadAction<Update<TModel>>) => {
        SliceAdapter.updateOne(state.data as EntityState<TModel>, action.payload)
      },
      Add: (state, action: PayloadAction<TModel>) => {
        SliceAdapter.addOne(state.data as EntityState<TModel>, action.payload)
      },
      Remove: (state, action: PayloadAction<string>) => {
        SliceAdapter.removeOne(state.data as EntityState<TModel>, action.payload)
      },
      RemoveAll: (state) => {
        SliceAdapter.removeAll(state.data as EntityState<TModel>)
      },
      UpsetMany: (state, action: PayloadAction<TModel[]>) => {
        SliceAdapter.removeAll(state.data as EntityState<TModel>)
        SliceAdapter.upsertMany(state.data as EntityState<TModel>, action.payload)
      }
    },
    extraReducers: (builder) => extraReducers && extraReducers(builder, SliceAdapter)
  })
  return ModelSlice
}

interface ICreateCRUDSliceOption<TModel, TSliceState extends ISliceBase<TModel>, CaseReducers extends SliceCaseReducers<TSliceState>> {
  name: string
  selectId?: IdSelector<TModel>
  Initial?: (state: ISliceBase<TModel>) => TSliceState
  extraReducers?: (builder: ActionReducerMapBuilder<NoInfer<TSliceState>>, adapter: EntityAdapter<TModel>) => void
  reducers?: ValidateSliceCaseReducers<TSliceState, CaseReducers>
}

export const CRUDActions = <TModel, TSliceState extends ISliceBase<TModel>>(SliceAdapter: EntityAdapter<TModel>) => {
  return {
    Update: (state: Draft<TSliceState>, action: PayloadAction<Update<TModel>>) => {
      SliceAdapter.updateOne(state.data as EntityState<TModel>, action.payload)
    },
    Add: (state: Draft<TSliceState>, action: PayloadAction<TModel>) => {
      SliceAdapter.addOne(state.data as EntityState<TModel>, action.payload)
    },
    Remove: (state: Draft<TSliceState>, action: PayloadAction<string | number>) => {
      SliceAdapter.removeOne(state.data as EntityState<TModel>, action.payload)
    },
    RemoveAll: (state: Draft<TSliceState>) => {
      SliceAdapter.removeAll(state.data as EntityState<TModel>)
    },
    UpsetMany: (state: Draft<TSliceState>, action: PayloadAction<TModel[]>) => {
      SliceAdapter.removeAll(state.data as EntityState<TModel>)
      SliceAdapter.upsertMany(state.data as EntityState<TModel>, action.payload)
    }
  }
}

export const CreateCRUDSlice2 =
  <TModel>() =>
  <CR extends SliceCaseReducers<TSliceState>, TSliceState extends ISliceBase<TModel> = ISliceBase<TModel>>(
    options: ICreateCRUDSliceOption<TModel, TSliceState, CR>
  ) => {
    const SliceAdapter = createEntityAdapter<TModel>({
      selectId: options.selectId
    })
    // Define the initial state using that type

    const Initial = options.Initial ? options.Initial : (x: any) => x
    const initialState: TSliceState = Initial({
      Status: LazyStatus.Loading,
      data: SliceAdapter.getInitialState()
    })

    const ModelSlice = createSlice({
      name: options.name,
      // `createSlice` will infer the state type from the `initialState` argument
      initialState,
      reducers: {
        ...CRUDActions(SliceAdapter),
        ...(options.reducers ?? {})
      },
      extraReducers: (builder) => options.extraReducers && options.extraReducers(builder, SliceAdapter)
    })
    return ModelSlice as Slice<TSliceState, ReturnType<typeof CRUDActions> & CR, string>
  }
export const buildThunkDefault = <TModel>(
  builder: ActionReducerMapBuilder<NoInfer<ISliceBase<TModel>>>,
  SliceAdapter: EntityAdapter<TModel>,
  fetchThunk: AsyncThunk<TModel[], any, any>
) => {
  builder
    .addCase(fetchThunk.fulfilled, (state, action) => {
      if (state.requestedId !== action.meta.requestId) return
      state.Status = LazyStatus.Loaded

      SliceAdapter.removeAll(state.data as EntityState<TModel>)
      SliceAdapter.upsertMany(state.data as EntityState<TModel>, action)
    })
    .addCase(fetchThunk.rejected, (state, action) => {
      if (state.requestedId !== action.meta.requestId) return
      state.Status = LazyStatus.Error
    })
    .addCase(fetchThunk.pending, (state) => {
      state.Status = LazyStatus.Loading
    })

  return builder
}
