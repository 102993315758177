/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchCheckOutThunk } from './Thunks'
import { AppDispatch, RootState } from '../../../Redux/type'
import { ICheckOutDispatchRedux, ICheckOutStateRedux, TCheckOutMapRedux } from '../type'
import ViewBase from '../ViewBase'
import { hocConnectV2 } from '../../../Redux/hocs/hocConnect.v2'
import CheckOutError from '../ui/CheckOutError'

// ========= ========= ========= View Base ========= ========= =========
const mapStateToProps = (state: RootState): ICheckOutStateRedux => ({
  Status: state.CheckOutSlice.Status,
  CheckOutSlice: state.CheckOutSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): ICheckOutDispatchRedux => {
  return {
    fetchInitial: () => {
      return dispatch(fetchCheckOutThunk())
    },
  }
}

export const CheckOutMapRedux: TCheckOutMapRedux = hocConnectV2<any, ICheckOutStateRedux, ICheckOutDispatchRedux>(
  ViewBase,
  mapStateToProps,
  mapDispatchToProps,
  { ErrorScreen: CheckOutError }
)
