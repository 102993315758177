import { ModelType } from 'graphql-ts-client-api'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { applicationUser$ } from '../../__generated/media-store/fetchers'
import { media$$, mediaPage$ } from '../../__generated/media-store/fetchers'
import { category$, categoryDetail$$, category$$, categoryPage$ } from '../../__generated/media-store/fetchers'

const MediaSelector = media$$
  .categoryDetails(categoryDetail$$.category(category$.id.name.type.dateCreated.dateUpdated))
  .user(applicationUser$.displayName)
export type TMedia = ModelType<typeof MediaSelector>
export type TMediasFilterParams = Parameters<typeof mediaStoreGraphDbContext$.medias>[0]
export const MediasQuery = (pamras: TMediasFilterParams) => {
  const MediaPage = mediaPage$.items(MediaSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.medias(pamras, MediaPage))
}

const CategorySelector = category$$
export type TCategory = ModelType<typeof CategorySelector>
export const MediasVsCategoriesQuery = (pamras: TMediasFilterParams) => {
  const MediaPage = mediaPage$.items(MediaSelector).hasNextPage.hasPreviousPage.totalItems
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.medias(pamras, MediaPage).categories(pamras, CategoryPage))
}

type TCategoriesFilterParams = Parameters<typeof mediaStoreGraphDbContext$.categories>[0]
export type TMediaFilterParams = Parameters<typeof mediaStoreGraphDbContext$.media>[0]
export const CategoriesAndCategoryDetailByMediaId = (
  params: TMediaFilterParams,
  categoriesFilter: TCategoriesFilterParams = {}
) => {
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.media(params, MediaSelector).categories(categoriesFilter, CategoryPage))
}
