const localKey = 'notification_data'

export const setLocalStorageNotification = (notificationIds: number[]) => {
  localStorage.removeItem(localKey)
  localStorage.setItem(localKey, JSON.stringify(notificationIds))
}

export const getLocalStorageNotification = (): number[] => {
  try {
    const data = localStorage.getItem(localKey)
    return JSON.parse(data ?? '[]')
  } catch (error) {
    return []
  }
}

export const setSingleLocalStorageNotification = (notificationId: number) => {
  const setIds = new Set(getLocalStorageNotification())
  setIds.add(notificationId)
  setLocalStorageNotification(Array.from(setIds))
}
