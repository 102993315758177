import React from 'react'
import { Box, styled } from '@mui/material'
import { ICategory } from '../Models'

interface ICategoryItemProps {
  data: ICategory
}

export const CategoryItem: React.FC<ICategoryItemProps> = (props) => {
  const { data } = props
  const bgImage = process.env.PUBLIC_URL + data.ImageUri
  return (
    <Container>
      <AvatarBox sx={{ backgroundImage: `url(${bgImage})` }} />
      <Box sx={{ py: '5px' }}>
        <Title>{data.Name}</Title>
        <SubTitle>{data.Description}</SubTitle>
      </Box>
    </Container>
  )
}

export default CategoryItem

const Container = styled('div')({
  display: 'flex',
  gap: '15px',
  backgroundColor: '#ffffff',
  maxWidth: '450px',
  overflow: 'hidden',
  borderRadius: '4px',
  boxShadow: '0 2px 14px rgba(0,0,0,0)',
  transition: 'all linear 0.3s',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0 2px 14px rgba(0,0,0,0.23)',
  },
})

const AvatarBox = styled('div')({
  height: '84px',
  width: '84px',
  backgroundColor: '#FFF',
  boxShadow: '0 2px 14px rgba(0,0,0,0.23)',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
})

const Title = styled('div')({
  fontSize: '20px',
  fontWeight: 700,
})

const SubTitle = styled('div')({
  fontSize: '16px',
  fontWeight: 500,
  color: '#878787',
})
