import React, { Component, FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Box, Typography, styled } from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import { EMediaType } from '../../Models/enums'
import { IMediaFilterDTO, IMediaTypeTabsReduxProps } from './type'
import QueryParam from '../../utilities/QueryParam'
import { NavigateKey } from '../../Route/NavigateKey'

interface IProps {
  defaultValue?: EMediaType
  onChange?: (value: EMediaType) => void
}

const MediaTypeTabsBase: FC<IProps> = (props) => {
  const location = useLocation()

  const getClassName = (value: EMediaType) => {
    const mediaType = props.defaultValue
    if (location.pathname === NavigateKey.Explore && mediaType === value) return 'active'
    return ''
  }

  const handleClick = (value: EMediaType) => {
    props.onChange && props.onChange(value)
  }

  const getPath = (value: EMediaType) => {
    const filter: IMediaFilterDTO = { mediaType: value }
    return `${NavigateKey.Explore}${QueryParam.stringify(filter)}`
  }

  return (
    <Box sx={{ display: 'flex', gap: '25px' }}>
      <Item
        className={getClassName(EMediaType.Video)}
        onClick={() => handleClick(EMediaType.Video)}
        to={getPath(EMediaType.Video)}
      >
        <OndemandVideoIcon />
        <Text>Videos</Text>
      </Item>
      <Item
        className={getClassName(EMediaType.Audio)}
        onClick={() => handleClick(EMediaType.Audio)}
        to={getPath(EMediaType.Audio)}
      >
        <MusicNoteIcon />
        <Text>Audios</Text>
      </Item>
    </Box>
  )
}

export default class MediaTypeTabs extends Component<IMediaTypeTabsReduxProps> {
  render() {
    const value = this.props.ExploreSlice.filter.mediaType ?? EMediaType.Video
    return <MediaTypeTabsBase defaultValue={value} onChange={this.handleChange} />
  }

  handleChange = (value: EMediaType) => {
    this.props.putFilter('mediaType', value)
  }
}

const Text = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: '500',
  whiteSpace: 'nowrap',
})

const Item = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  color: '#606060',
  cursor: 'pointer',
  borderBottom: '1px solid transparent',
  transition: 'all linear 0.2s',
  textDecoration: 'unset !important',
  '&.active': {
    color: '#1976D2',
    borderBottom: '1px solid #1976D2',
  },
  '&:hover': {
    borderBottom: '1px solid #606060',
  },
})
