import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface CategoryDetailFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'CategoryDetail', T, TVariables> {

    on<XName extends ImplementationType<'CategoryDetail'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): CategoryDetailFetcher<
        XName extends 'CategoryDetail' ?
        T & X :
        WithTypeName<T, ImplementationType<'CategoryDetail'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'CategoryDetail'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): CategoryDetailFetcher<T, TVariables>;


    readonly __typename: CategoryDetailFetcher<T & {__typename: ImplementationType<'CategoryDetail'>}, TVariables>;


    readonly id: CategoryDetailFetcher<T & {readonly "id": number}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CategoryDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": CategoryDetailFetcher<Omit<T, 'id'>, TVariables>;


    readonly mediaId: CategoryDetailFetcher<T & {readonly "mediaId": number}, TVariables>;

    "mediaId+"<
        XAlias extends string = "mediaId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"mediaId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CategoryDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~mediaId": CategoryDetailFetcher<Omit<T, 'mediaId'>, TVariables>;


    readonly categoryId: CategoryDetailFetcher<T & {readonly "categoryId": number}, TVariables>;

    "categoryId+"<
        XAlias extends string = "categoryId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"categoryId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CategoryDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~categoryId": CategoryDetailFetcher<Omit<T, 'categoryId'>, TVariables>;


    media<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Media', X, XVariables>
    ): CategoryDetailFetcher<
        T & {readonly "media"?: X}, 
        TVariables & XVariables
    >;

    media<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "media", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Media', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"media", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): CategoryDetailFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    category<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Category', X, XVariables>
    ): CategoryDetailFetcher<
        T & {readonly "category"?: X}, 
        TVariables & XVariables
    >;

    category<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "category", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Category', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"category", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): CategoryDetailFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: CategoryDetailFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CategoryDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": CategoryDetailFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: CategoryDetailFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): CategoryDetailFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": CategoryDetailFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const categoryDetail$: CategoryDetailFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "CategoryDetail", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                "mediaId", 
                "categoryId", 
                {
                    category: "REFERENCE", 
                    name: "media", 
                    targetTypeName: "Media", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "category", 
                    targetTypeName: "Category", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const categoryDetail$$ = 
    categoryDetail$
        .id
        .mediaId
        .categoryId
        .dateCreated
        .dateUpdated
;
