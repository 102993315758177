import React, { FC, ComponentType, PropsWithChildren, Component, useCallback, useRef, useState } from 'react'
import { GlobalModalContext } from '@coreprj/shareds/GlobalModal'
import { Box, Button, Card, DialogActions, DialogContent, DialogTitle, IconButton, Paper, SxProps, Theme, Typography, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ErrorAll, IFormBase } from './Subs'
import { CreateInputForm } from './InputFormBase'
import { FormValidator } from './ValidateForm/FormValidator'

interface IFromTitleProps {
  title: string
  close: () => void
}
const FromTitle: FC<PropsWithChildren<IFromTitleProps>> = (props) => {
  return (
    <CustomDialogTitle {...{ component: 'div' }}>
      {/* <data.Icon color='primary' /> */}
      <Typography component='h3' variant='subtitle1' sx={{ mt: '2px', fontWeight: 700 }}>
        {props.title}
      </Typography>
      <Box flex={1} />
      <BtnClose onClick={props.close}>
        <CloseIcon />
      </BtnClose>
    </CustomDialogTitle>
  )
}

const headerHeight = 48

const CustomDialogTitle = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
  height: `${headerHeight}px`,
  padding: '0 12px',
  margin: '-8px -12px 0',
  gap: '6px'
})

const BtnClose = styled(IconButton)({
  flex: '0 0 auto',
  color: '#3c3c3c',
  '& svg': { transition: 'all 0.2s' },
  '&:hover svg': { color: '#ff200c' }
})

const CustomPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 18px'
})

export function CreateForm<TModel, TProps extends IFormBase<TModel>>(FormValidate: FormValidator<Partial<TModel>>, FormInfo: ComponentType<TProps>) {
  interface IProps {
    title: string
    onSubmit: (data: Partial<TModel>) => Promise<void>
    data?: TModel
    CloseModel?: 'Success' | 'Fail'
  }

  const Template = CreateInputForm<TModel>()

  const Form: FC<IProps> = (props) => {
    const closeModel = useRef<() => void>()
    const [isLoading, setIsLoading] = useState(false)
    const onSubmit = useCallback(
      async (p: Partial<TModel>) => {
        try {
          setIsLoading(true)
          await props.onSubmit(p)
          ;(!props.CloseModel || props.CloseModel === 'Success') && closeModel.current && closeModel.current()
        } catch {
          ;(!props.CloseModel || props.CloseModel === 'Fail') && closeModel.current && closeModel.current()
        } finally {
          setIsLoading(false)
        }
      },
      [props]
    )

    const FormContent = FormInfo as ComponentType<IFormBase<TModel>>
    return (
      <CustomPaper sx={isLoading ? { opacity: 0.9, pointerEvents: 'none' } : {}}>
        <GlobalModalContext.Consumer>
          {({ CloseModal }) => {
            closeModel.current = CloseModal
            return (
              <>
                <FromTitle title={props.title} close={CloseModal} />
                <Template.InputFormBase FormValidate={FormValidate} onSubmit={onSubmit}>
                  <Template.InputFormContext.Consumer>
                    {({ onBlur, MessageError }) => {
                      return (
                        <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '1000px' }}>
                          <ErrorAll MessageError={MessageError}>
                            <FormContent onBlur={onBlur} MessageError={MessageError} Model={props.data} />
                          </ErrorAll>
                          <Box sx={{ m: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type='submit' color='success' variant='contained'>
                              Done
                            </Button>
                          </Box>
                        </Box>
                      )
                    }}
                  </Template.InputFormContext.Consumer>
                </Template.InputFormBase>
              </>
            )
          }}
        </GlobalModalContext.Consumer>
      </CustomPaper>
    )
  }
  return {
    ...Template,
    Form
  }
}

export function CreateFormInline<TModel, TProps extends IFormBase<TModel>>(
  FormValidate: FormValidator<Partial<TModel>>,
  FormInfo: ComponentType<TProps>
) {
  interface IProps {
    onSubmit: (data: Partial<TModel>) => Promise<void>
    data?: TModel
    disabled?: boolean
  }

  const Template = CreateInputForm<TModel>()

  const Form: FC<IProps> = (props) => {
    const onSubmit = useCallback(
      async (p: Partial<TModel>) => {
        try {
          await props.onSubmit(p)
        } catch {}
      },
      [props]
    )

    const FormContent = FormInfo as ComponentType<IFormBase<TModel>>
    return (
      <Template.InputFormBase FormValidate={FormValidate} onSubmit={onSubmit}>
        <Template.InputFormContext.Consumer>
          {({ onBlur, MessageError }) => (
            <ErrorAll MessageError={MessageError}>
              <FormContent onBlur={onBlur} MessageError={MessageError} Model={props.data} disabled={props.disabled} />
            </ErrorAll>
          )}
        </Template.InputFormContext.Consumer>
      </Template.InputFormBase>
    )
  }
  return {
    ...Template,
    Form
  }
}

export interface IFormDeleteProps<TModel> {
  title?: string
  onSubmit: (data: TModel) => Promise<void>
  data: TModel
}
export function CreateComfirm<TModel>() {
  return class FormDelete extends Component<PropsWithChildren<IFormDeleteProps<TModel>>> {
    render() {
      return (
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <GlobalModalContext.Consumer>
            {({ CloseModal }) => {
              return (
                <Card>
                  <DialogTitle id='alert-dialog-title'>{this.props.title ?? 'Are you sure delete?'}</DialogTitle>
                  <DialogContent>{this.props.children}</DialogContent>
                  <DialogActions>
                    <Button color='error' onClick={() => this.handleSubmit(CloseModal)} autoFocus>
                      yes
                    </Button>
                    <Button color='inherit' onClick={CloseModal}>
                      no
                    </Button>
                  </DialogActions>
                </Card>
              )
            }}
          </GlobalModalContext.Consumer>
        </Box>
      )
    }

    handleSubmit = (close: () => void) => {
      close()
      this.props.onSubmit(this.props.data)
    }
  }
}

export function CreateFormSingleValidate<TModel, TPropCommon extends IFormBase<TModel>>(
  FormValidate: FormValidator<Partial<TModel>>,
  FormInfo: ComponentType<TPropCommon>
) {
  interface IProps {
    onSubmit: (data: Partial<TModel>) => Promise<void>
    data?: TModel
    sx?: SxProps<Theme>
    disabled?: boolean
    InnerProps?: Omit<TPropCommon, keyof IFormBase<TModel>> & { innerRef?: (ref: any) => void }
  }

  const Template = CreateInputForm<TModel>()

  const Form: FC<IProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const onSubmit = useCallback(
      async (p: Partial<TModel>) => {
        try {
          setIsLoading(true)
          await props.onSubmit(p)
        } catch {
        } finally {
          setIsLoading(false)
        }
      },
      [props.onSubmit]
    )
    return (
      <Template.InputFormBase
        {...{ sx: { '& .MuiFormHelperText-root.Mui-error': { color: '#d32f2f!important' }, ...props.sx } }}
        FormValidate={FormValidate}
        onSubmit={onSubmit}
      >
        <Template.InputFormContext.Consumer>
          {({ onBlur, MessageError }) => {
            return (
              <FormInfo
                ref={props.InnerProps?.innerRef}
                {...(props.InnerProps ?? ({} as any))}
                onBlur={onBlur}
                MessageError={MessageError}
                Model={props.data}
                disabled={isLoading || props.disabled}
              />
            )
          }}
        </Template.InputFormContext.Consumer>
      </Template.InputFormBase>
    )
  }
  return {
    ...Template,
    Form
  }
}
