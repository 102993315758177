import { ApplicationPaths } from 'partner-oidc-auth'
import ApiAuthorizationRoutes from '@coreprj/auth-oidc-ui/ApiAuthorizationRoutes'
import { IRoute } from './type'
import { NavigateKey } from './NavigateKey'

import { HomeMapRedux } from '../Views/Home/redux/Map'
import { CartMapRedux } from '../Views/Cart/redux/Map'
import { PricingMapRedux } from '../Views/Pricing/redux/Map'
import { ProfileMapRedux } from '../Views/Profile/redux/Map'
import { ExploreMapRedux } from '../Views/Explore/redux/Map'
import { CheckOutMapRedux } from '../Views/CheckOut/redux/Map'
import { MediaDetailMapRedux } from '../Views/MediaDetail/redux/Map'

import FQAsScreen from '../Views/FQAsScreen'
import ReportScreen from '../Views/ReportScreen'
import CheckOutComplete from '../Views/CheckOutComplete'
import SubmitMediaScreen from '../Views/SubmitMediaScreen'
import PrivacyPolicyPage from '../Views/PrivacyPolicyScreen'
import AccessDenied from '../Views/AccessDenied'
import OrderMapRedux from '../Views/Order/Reduxes/Container'
import ProfileLayout from '../Views/Shareds/ProfileLayout'
import { OrderDetailMapRedux } from '../Views/OrderDetail/Redux/Container'
import AboutUsPage from '../Views/AboutUs'

const MapApiAuthorizationRoutes = () => {
  return ApiAuthorizationRoutes.map<IRoute>((item, index) => {
    return { element: item.element, Path: item.path }
  })
}

export const Routers: IRoute[] = [
  // {
  //   Path: '/test',
  //   element: < />,
  // },
  {
    Path: NavigateKey.InitialPath,
    element: <HomeMapRedux />
  },
  {
    Path: NavigateKey.Home,
    element: <HomeMapRedux />
  },
  {
    Path: NavigateKey.Explore,
    element: <ExploreMapRedux />
  },
  {
    Path: NavigateKey.Cart,
    element: <CartMapRedux />
  },
  {
    Path: NavigateKey.CheckOut,
    element: <CheckOutMapRedux />,
    roles: [[]]
  },
  {
    Path: NavigateKey.CheckOutComplete,
    element: <CheckOutComplete />
  },
  {
    Path: NavigateKey.Report,
    element: <ReportScreen />
  },
  {
    Path: NavigateKey.Detail,
    element: <MediaDetailMapRedux />
  },
  {
    Path: NavigateKey.SubmitMedia,
    element: <SubmitMediaScreen />
  },
  {
    Path: NavigateKey.FQAs,
    element: <FQAsScreen />
  },
  {
    Path: NavigateKey.Policy,
    element: <PrivacyPolicyPage />
  },
  {
    Path: NavigateKey.Pricing,
    element: <PricingMapRedux />
  },
  {
    Path: NavigateKey.Profile,
    element: <ProfileLayout />,
    roles: [[]],
    Children: [
      {
        Path: '/',
        element: <ProfileMapRedux />,
        roles: [[]]
      },
      {
        Path: NavigateKey.Order,
        element: <OrderMapRedux />,
        roles: [[]]
      },
      {
        Path: NavigateKey.OrderDetail,
        element: <OrderDetailMapRedux />,
        roles: [[]]
      },
      {
        Path: NavigateKey.AboutUs,
        element: <AboutUsPage />,
        roles: [[]]
      }
    ]
  },
  {
    Path: ApplicationPaths.AccessDenied,
    element: <AccessDenied />
  },
  ...MapApiAuthorizationRoutes()
]
