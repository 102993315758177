import { connect } from 'react-redux'
import { AppDispatch, RootState } from '../../../Redux/type'
import { removeAllCartThunk } from '../../Cart/redux/Thunks'
import { IPayPalDispatchRedux, IPayPalStateRedux } from '../type'
import PaypalMethod from '../ui/PaymentMethods/Paypal'
import CheckOutSlice from './Slice'

// ========= ========= ========= PayPal Redux ========= ========= =========
const mapStateToProps = (state: RootState): IPayPalStateRedux => ({
  CheckOutSlice: state.CheckOutSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): IPayPalDispatchRedux => {
  return {
    removeAll: () => {
      dispatch(removeAllCartThunk())
    },
    changeStatus: (value) => {
      dispatch(CheckOutSlice.actions.changeStatus(value))
    },
  }
}

export const PaypalMethodMapRedux = connect(mapStateToProps, mapDispatchToProps)(PaypalMethod)
