import React, { FC } from 'react'
import { IHomeReduxProps } from './redux/type'
import Layout from './ui/Layout'
import OurPartner from './ui/OurPartner'
import Categories from './ui/Categories'
import SearchInput from './ui/SearchInput'
import PricingContent from '../Pricing/PricingContent'
import VideoGalleries from './ui/VideoGalleries'
import AudioGalleries from './ui/AudioGalleries'
import { ICategory } from '@coreprj/types/models'
import { EGalleryKey, ELazyStatus } from '@coreprj/types/enum'

interface IProps extends IHomeReduxProps {}

const ViewBase: FC<IProps> = (props) => {
  const getGalleryBoxData = () => {
    const isCheck = (value: string) => value === EGalleryKey.Videos1 || value === EGalleryKey.Videos2
    return props.HomeSlice.Galleries.filter((e) => isCheck(e.Key))
  }

  const handleClickCategory = (value: ICategory) => {
    // navFilter({ Type: value.Type, Keywords: value.Name })
  }

  const getPlayListData = () => {
    const isCheck = (value: string) => {
      return (
        value === EGalleryKey.Audio1 ||
        value === EGalleryKey.Audio2 ||
        value === EGalleryKey.Audio3 ||
        value === EGalleryKey.Audio4 ||
        value === EGalleryKey.Audio5
      )
    }
    return props.HomeSlice.Galleries.filter((e) => isCheck(e.Key))
  }

  return (
    <Layout
      isLoading={props.Status === ELazyStatus.Loading}
      components={{
        search: <SearchInput />,
        ourPartner: <OurPartner />,
        category: <Categories data={props.HomeSlice.Categories} onClick={handleClickCategory} />,
        video: <VideoGalleries data={getGalleryBoxData()} />,
        audio: <AudioGalleries data={getPlayListData()} />,
        package: <PricingContent data={props.HomeSlice.Prices} isId />
      }}
    />
  )
}
export default ViewBase
