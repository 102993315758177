import React, { Component } from 'react'
import { ICartDTO, ICheckOutReduxProps } from './type'
import Layout from './ui/Layout'
import CartListContent from './ui/CartListContent'
import InformationPayment from './ui/InformationPayment'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { NavigateKey } from '../../Route'
import { WindowScrollToTop } from '../../utilities'

interface IProps extends ICheckOutReduxProps {}

interface IContentProps extends IProps {
  navigate: NavigateFunction
}

class ViewBaseContent extends Component<IContentProps> {
  render() {
    const cartData = this.getCartData()
    return (
      <Layout
        status={this.props.Status}
        components={{
          cart: <CartListContent data={cartData} />,
          payment: <InformationPayment />,
        }}
      />
    )
  }

  componentDidMount(): void {
    WindowScrollToTop()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.CheckOutSlice.loaded && this.props.CheckOutSlice.carts.length < 1) {
      this.props.navigate(NavigateKey.Home)
    }
  }

  getCartData = (): ICartDTO[] => {
    return this.props.CheckOutSlice.carts
  }
}

const ViewBase: React.FC<IProps> = (props) => {
  return <ViewBaseContent {...props} navigate={useNavigate()} />
}
export default ViewBase
