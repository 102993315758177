import { Component } from 'react'
import { Stack, styled } from '@mui/material'
import { IMediaPlayerProps } from './type'
import { VideoPlayer } from '../../../../Components'
import Utilities from '../../../../utilities'

export class VideoMediaPlayerLayout extends Component<IMediaPlayerProps> {
  render() {
    const { data } = this.props
    return (
      <Stack gap="10px">
        {/* {this.renderCategory()} */}
        <Text>by BERLINTOMEK</Text>
        {data && <VideoPlayer data={data} />}
        <Title>{data ? data.Name : 'Exo 2/Bold/20px'}</Title>
        <SubTitle>{data ? data.Description : 'Exo 2/Medium/16px'}</SubTitle>
        <Stack direction="row" gap="25px">
          <Text>Size: {data ? Utilities.formatFileSize(data.Size) : 'Exo 2/Regular/14px'}</Text>
          <Text>{!!data ? Utilities.formatDatetime(data.DateCreated) : 'Date Added: Exo 2/Regular/14px'} </Text>
        </Stack>
      </Stack>
    )
  }
  renderCategory = () => {
    const { data } = this.props
    let text: any = data?.CategoryNames
    if (Array.isArray(text)) text = text.join(', ')
    return <Text>{text ? text : 'by BERLINTOMEK'}</Text>
  }
}
export default VideoMediaPlayerLayout

const Title = styled('div')({
  fontSize: '20px',
  fontWeight: 700,
})

const SubTitle = styled('div')({
  fontSize: '16px',
  fontWeight: 500,
  color: '#878787',
})

const Text = styled('div')({
  fontSize: '14px',
  fontWeight: 400,
  color: '#878787',
})
