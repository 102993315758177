import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, Stack } from '@mui/material'
import { Text18, TextBig } from '../../Components/Text'
import SuccessIconAnimate from './SuccessIconAnimate'

export default class CheckOutComplete extends Component {
  render() {
    return (
      <Container>
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <SuccessIconAnimate />
          <TextBig sx={{ textTransform: 'upercase' }}>Checkout success!</TextBig>
          <Text18> Thank you for purchasing our products, have a good day</Text18>
          <Button color="primary" variant="outlined" sx={{ minWidth: '220px', mt: '24px' }} component={Link} to="/">
            Continue
          </Button>
        </Stack>
      </Container>
    )
  }
}
