import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface NotificationStatusFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'NotificationStatus', T, TVariables> {

    on<XName extends ImplementationType<'NotificationStatus'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): NotificationStatusFetcher<
        XName extends 'NotificationStatus' ?
        T & X :
        WithTypeName<T, ImplementationType<'NotificationStatus'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'NotificationStatus'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): NotificationStatusFetcher<T, TVariables>;


    readonly __typename: NotificationStatusFetcher<T & {__typename: ImplementationType<'NotificationStatus'>}, TVariables>;


    readonly notificationId: NotificationStatusFetcher<T & {readonly "notificationId": number}, TVariables>;

    "notificationId+"<
        XAlias extends string = "notificationId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"notificationId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): NotificationStatusFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~notificationId": NotificationStatusFetcher<Omit<T, 'notificationId'>, TVariables>;


    readonly userId: NotificationStatusFetcher<T & {readonly "userId"?: string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationStatusFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": NotificationStatusFetcher<Omit<T, 'userId'>, TVariables>;


    notificationInfo<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'NotificationInfo', X, XVariables>
    ): NotificationStatusFetcher<
        T & {readonly "notificationInfo"?: X}, 
        TVariables & XVariables
    >;

    notificationInfo<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "notificationInfo", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'NotificationInfo', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"notificationInfo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): NotificationStatusFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: NotificationStatusFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): NotificationStatusFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": NotificationStatusFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: NotificationStatusFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): NotificationStatusFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": NotificationStatusFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const notificationStatus$: NotificationStatusFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "NotificationStatus", 
            "EMBEDDED", 
            [], 
            [
                "notificationId", 
                {
                    category: "SCALAR", 
                    name: "userId", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "notificationInfo", 
                    targetTypeName: "NotificationInfo", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const notificationStatus$$ = 
    notificationStatus$
        .notificationId
        .userId
        .dateCreated
        .dateUpdated
;
