/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchMediaDetailThunk } from './Thunks'
import { hocConnect } from '../../../Redux/hocs/hocConnect'
import { AppDispatch, RootState } from '../../../Redux/type'
import { IMediaDetailDispatchRedux, IMediaDetailStateRedux, TMediaDetailMapRedux } from '../type'
import ViewBase from '../ViewBase'

// ========= ========= ========= View Base ========= ========= =========
const mapStateToProps = (state: RootState): IMediaDetailStateRedux => ({
  Status: state.MediaDetailSlice.Status,
  MediaDetailSlice: state.MediaDetailSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): IMediaDetailDispatchRedux => {
  return {
    fetchData: (value) => {
      dispatch(fetchMediaDetailThunk(value))
    },
  }
}

export const MediaDetailMapRedux: TMediaDetailMapRedux = hocConnect<any, IMediaDetailStateRedux, IMediaDetailDispatchRedux>(
  ViewBase,
  mapStateToProps,
  mapDispatchToProps
)
