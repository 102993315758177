import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {BillingStatus} from '../enums';
import type {BillingType} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface BillingFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'Billing', T, TVariables> {

    on<XName extends ImplementationType<'Billing'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): BillingFetcher<
        XName extends 'Billing' ?
        T & X :
        WithTypeName<T, ImplementationType<'Billing'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'Billing'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): BillingFetcher<T, TVariables>;


    readonly __typename: BillingFetcher<T & {__typename: ImplementationType<'Billing'>}, TVariables>;


    readonly id: BillingFetcher<T & {readonly "id": number}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": BillingFetcher<Omit<T, 'id'>, TVariables>;


    readonly userId: BillingFetcher<T & {readonly "userId": string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": BillingFetcher<Omit<T, 'userId'>, TVariables>;


    readonly paymentInfoId: BillingFetcher<T & {readonly "paymentInfoId"?: number}, TVariables>;

    "paymentInfoId+"<
        XAlias extends string = "paymentInfoId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"paymentInfoId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BillingFetcher<
        T & {readonly [key in XAlias]?: number}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~paymentInfoId": BillingFetcher<Omit<T, 'paymentInfoId'>, TVariables>;


    readonly name: BillingFetcher<T & {readonly "name"?: string}, TVariables>;

    "name+"<
        XAlias extends string = "name", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"name", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BillingFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~name": BillingFetcher<Omit<T, 'name'>, TVariables>;


    readonly description: BillingFetcher<T & {readonly "description"?: string}, TVariables>;

    "description+"<
        XAlias extends string = "description", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"description", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BillingFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~description": BillingFetcher<Omit<T, 'description'>, TVariables>;


    readonly status: BillingFetcher<T & {readonly "status": BillingStatus}, TVariables>;

    "status+"<
        XAlias extends string = "status", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"status", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: BillingStatus} : 
                {readonly [key in XAlias]: BillingStatus}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~status": BillingFetcher<Omit<T, 'status'>, TVariables>;


    readonly noDetail: BillingFetcher<T & {readonly "noDetail": boolean}, TVariables>;

    "noDetail+"<
        XAlias extends string = "noDetail", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"noDetail", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: boolean} : 
                {readonly [key in XAlias]: boolean}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~noDetail": BillingFetcher<Omit<T, 'noDetail'>, TVariables>;


    readonly payout: BillingFetcher<T & {readonly "payout": number}, TVariables>;

    "payout+"<
        XAlias extends string = "payout", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"payout", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~payout": BillingFetcher<Omit<T, 'payout'>, TVariables>;


    readonly transactionId: BillingFetcher<T & {readonly "transactionId"?: string}, TVariables>;

    "transactionId+"<
        XAlias extends string = "transactionId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"transactionId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BillingFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~transactionId": BillingFetcher<Omit<T, 'transactionId'>, TVariables>;


    readonly type: BillingFetcher<T & {readonly "type": BillingType}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: BillingType} : 
                {readonly [key in XAlias]: BillingType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": BillingFetcher<Omit<T, 'type'>, TVariables>;


    paymentInfo<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'PaymentInfo', X, XVariables>
    ): BillingFetcher<
        T & {readonly "paymentInfo"?: X}, 
        TVariables & XVariables
    >;

    paymentInfo<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "paymentInfo", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'PaymentInfo', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"paymentInfo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BillingFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly datePaid: BillingFetcher<T & {readonly "datePaid"?: string}, TVariables>;

    "datePaid+"<
        XAlias extends string = "datePaid", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"datePaid", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BillingFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~datePaid": BillingFetcher<Omit<T, 'datePaid'>, TVariables>;


    readonly dateCreated: BillingFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": BillingFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: BillingFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): BillingFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": BillingFetcher<Omit<T, 'dateUpdated'>, TVariables>;


    user<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): BillingFetcher<
        T & {readonly "user"?: X}, 
        TVariables & XVariables
    >;

    user<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "user", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"user", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): BillingFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;
}

export const billing$: BillingFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "Billing", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                "userId", 
                {
                    category: "SCALAR", 
                    name: "paymentInfoId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "name", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "description", 
                    undefinable: true
                }, 
                "status", 
                "noDetail", 
                "payout", 
                {
                    category: "SCALAR", 
                    name: "transactionId", 
                    undefinable: true
                }, 
                "type", 
                {
                    category: "REFERENCE", 
                    name: "paymentInfo", 
                    targetTypeName: "PaymentInfo", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "datePaid", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated", 
                {
                    category: "REFERENCE", 
                    name: "user", 
                    targetTypeName: "ApplicationUser", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const billing$$ = 
    billing$
        .id
        .userId
        .paymentInfoId
        .name
        .description
        .status
        .noDetail
        .payout
        .transactionId
        .type
        .datePaid
        .dateCreated
        .dateUpdated
;
