export enum ELogic {
  And = 'And',
  Or = 'Or',
}

export enum EOperator {
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  LessThanOrEqual = 'LessThanOrEqual',
  NotEqual = 'NotEqual',
  Equal = 'Equal',
  Contains = 'Contains',
}

export enum EOrder {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

interface FieldFilter<TModel> {
  Logic?: ELogic
  Operator?: EOperator
  FieldName: keyof TModel
  Value: string
}

interface ScopeFilter<TModel> {
  Logic?: ELogic
  Scopes: PropFilter<TModel>[]
}

export type PropFilter<TModel> = FieldFilter<TModel> | ScopeFilter<TModel>

export interface PropOrderBy<TModel> {
  Type?: EOrder
  FieldName: keyof TModel
}

export interface IFilter<TModel> {
  Page?: number
  Amount?: number
  PropFilters?: PropFilter<TModel>[]
  PropOrders?: PropOrderBy<TModel>[]
}
