import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { fetchCheckOutThunk } from './Thunks'
import { ICheckOutStateSlice } from '../type'
import { ELazyStatus } from '../../../Models/enums'

// Define the initial state using that type
const initialState: ICheckOutStateSlice = {
  Status: ELazyStatus.Loading,
  carts: [],
}

const CheckOutSlice = createSlice({
  name: 'CheckOutSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    changeStatus: (state, action: PayloadAction<ELazyStatus>) => {
      state.Status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckOutThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded

        state.carts = action.payload.carts
        state.loaded = true
        state.referenceId = action.payload.referenceId
      })
      .addCase(fetchCheckOutThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchCheckOutThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
      })
  },
})
export default CheckOutSlice
