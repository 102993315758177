import React, { Component } from 'react'
import { Box, Button, styled, Paper, Typography, Divider, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import 'react-quill/dist/quill.snow.css'
import { INotification } from '@coreprj/types/models'
import TextEditor from '@coreprj/shareds/TextEditor'

interface IProps {
  data: INotification[]
  onRead: () => void
  onClose: () => void
}

interface IState {
  indexSelected: number
}

export default class NotifyDetail extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { indexSelected: 0 }
  }

  render() {
    const { data } = this.props
    const item = data[this.state.indexSelected]
    if (!item) return <></>
    return (
      <Wrapper sx={{ backgroundColor: '#fff' }}>
        <Box sx={{ padding: '8px 16px 2px', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Title variant='subtitle1' noWrap sx={{ mt: '12px', width: '100%' }}>
              {item.Title}
            </Title>
            <Box flex={1} />
            <CustomIconButton onClick={this.props.onClose}>
              <CloseIcon />
            </CustomIconButton>
          </Box>
          <Divider flexItem />
        </Box>
        <TextEditorWrapper>
          <TextEditor key={item.Id} name='' defautValue={item.Content} readOnly={true} />
        </TextEditorWrapper>
        <FlexCenter sx={{ justifyContent: 'flex-end', padding: '6px 16px 12px', width: '100%' }}>
          <Button color='error' onClick={this.props.onRead} sx={{ textTransform: 'unset', fontWeight: 600 }}>
            {item.IsRead ? 'Close' : 'Read'}
          </Button>
        </FlexCenter>
      </Wrapper>
    )
  }
}

const Wrapper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  width: 'calc(100vw - 48px)',
  margin: '0 auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  outline: 'none',
  // [theme.breakpoints.up('sm')]: { width: theme.breakpoints.values.sm },
  [theme.breakpoints.up('md')]: { width: theme.breakpoints.values.md }
}))

const Title = styled(Typography)({
  color: '#222f44',
  fontWeight: 600,
  letterSpacing: '1.1px',
  wordSpacing: '1.5px',
  lineHeight: 1.5
})

const TextEditorWrapper = styled(Box)({
  width: '100%',
  minHeight: '350px',
  maxHeight: 'calc(100vh - 180px)',
  overflow: 'auto',
  margin: '0 4px',
  padding: '0 8px',
  '& .ql-toolbar.ql-snow': { display: 'none' },
  '& .ql-container.ql-snow': { border: 'none' },
  '& .ql-editor': {
    paddingLeft: 0,
    paddingRight: 0,
    height: 'unset'
  }
})

const FlexCenter = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
})

const CustomIconButton = styled(IconButton)({
  flex: '0 0 auto',
  color: '#434343',
  '& svg': { transition: 'all 0.2s' },
  '&:hover svg': { color: '#ff200c' }
})
