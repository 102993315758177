import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { IMediaDTO } from '../type'
import { MediaItem } from '../../../Components'
import { EMediaType } from '../../../Models/enums'

interface IProps {
  data: IMediaDTO[]
}

export default class Content extends Component<IProps> {
  render() {
    return (
      <Grid container spacing={2} sx={{ backgroundColor: '#fff' }}>
        {this.props.data.map((e) => {
          const repon = { sm: 6, md: 3 }
          if (e.Type === EMediaType.Audio) repon.sm = repon.md = 12
          return (
            <Grid item xs={12} sm={repon.sm} md={repon.md} key={e.Id}>
              <MediaItem data={e} />
            </Grid>
          )
        })}
      </Grid>
    )
  }
}
