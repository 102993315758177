import { FC, Fragment, ReactNode, useState, MouseEvent } from 'react'
import { Box, Button, Menu, MenuItem, SxProps, Theme, Typography, styled } from '@mui/material'
import TheatersIcon from '@mui/icons-material/Theaters'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { EMediaType } from '../../../../Models/enums'

interface ISetting {
  value: EMediaType
  display: string
  icon: ReactNode
}

const settings: Record<EMediaType, ISetting> = {
  [EMediaType.Video]: {
    value: EMediaType.Video,
    display: 'Videos',
    icon: <TheatersIcon />,
  },
  [EMediaType.Audio]: {
    value: EMediaType.Audio,
    display: 'Audios',
    icon: <MusicNoteIcon />,
  },
}

interface IProps {
  status: EMediaType
  onChange?: (status: EMediaType) => void
}
export const MediaTypeMenu: FC<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const onOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <CustomButton
        id="btn-explore-menu-appbar"
        aria-controls={open ? 'explore-menu-appbar' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={onOpen}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        color="inherit"
      >
        {settings[props.status].display}
      </CustomButton>
      <Menu
        keepMounted
        sx={{ mt: '45px' }}
        id="explore-menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ elevation: 0, sx: sxPaperProps }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <Box>
          {Object.values(settings).map((e) => (
            <CustomMenu
              key={e.value}
              onClick={() => {
                onClose()
                if (props.onChange) props.onChange(e.value)
              }}
              className={e.value === props.status ? 'active' : ''}
            >
              {e.icon}
              <Typography textAlign="center">{e.display}</Typography>
            </CustomMenu>
          ))}
        </Box>
      </Menu>
    </Fragment>
  )
}

export default MediaTypeMenu

const sxPaperProps: SxProps<Theme> = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
}

const CustomButton = styled(Button)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: '500',
  color: '#606060',
})

const CustomMenu = styled(MenuItem)({
  minWidth: '150px',
  transition: 'all linear 0.2s',
  display: 'flex',
  gap: '15px',
  '&: hover': {
    backgroundColor: '#dedede',
  },
  '&.active': {
    color: '#1976D2',
  },
})
