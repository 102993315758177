import React, { Component } from 'react'
import Layout from './ui/Layout'
import PricingContent from './PricingContent'
import { IPricingReduxProps } from './type'
import { ELazyStatus } from '../../Models/enums'

interface IProps extends IPricingReduxProps {}

export default class ViewBase extends Component<IProps> {
  render() {
    return (
      <Layout
        isLoading={this.props.Status === ELazyStatus.Loading}
        components={{
          content: <PricingContent data={this.props.PricingSlice.pricePackages} />,
        }}
      />
    )
  }
}
