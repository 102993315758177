import { createAsyncThunk } from '@reduxjs/toolkit'
import { ICategoryDTO, IMediaDTO, IPricePackageDTO, IQueryParams } from '../type'
import MediaService from '../services/MediaService'
import CategoryService from '../services/CategoryService'
import PricePackageService from '../services/PricePackageService'
import { EPriceType } from '@coreprj/types/enum'

interface IMediaDetailResult {
  media?: IMediaDTO
  recommends: IMediaDTO[]
  categories: ICategoryDTO[]
  pricePackages: IPricePackageDTO[]
}

export const fetchMediaDetailThunk = createAsyncThunk<IMediaDetailResult, IQueryParams>('fetchMediaDetailThunk', async (params, thunkAPI) => {
  // const state = thunkAPI.getState() as { MediaDetailSlice: IMediaDetailStateSlice }
  const [media, recommends, categories, prices] = await Promise.all([
    MediaService.detail(params.id, thunkAPI.signal).catch(() => undefined),
    MediaService.all({ mediaType: params.type }, thunkAPI.signal),
    CategoryService.all({ mediaType: params.type }, thunkAPI.signal).catch(() => []),
    PricePackageService.all({ pricePackagetype: EPriceType.Media }, thunkAPI.signal).catch(() => [])
  ])
  return { media, recommends: recommends.Data, categories, pricePackages: prices }
})
