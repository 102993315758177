import { IMediaFilterDTO, IMediaDTO } from '../type'
import { EMediaType } from '../../../Models/enums'
import { ICategory, IMedia } from '../../../Models'
import { ELogic, EOperator, EOrder, IFilter, PropFilter } from '../../../Models/filter'
import QueryParam from '../../../utilities/QueryParam'
import ConstantDefault from '../helpers/ConstantDefault'

type TMediaFilter = IFilter<IMedia>
type TCategoryFilter = IFilter<ICategory>

interface IFilterParmasDTO extends Omit<IMediaFilterDTO, 'page'> {
  page?: string
  sortField?: string
  sortType?: string
}

class FilterMappingBase {
  initilal = (): IMediaFilterDTO => {
    return { ...this.byChangeMediaType(), ...this.getQueryParams() }
  }

  byChangeMediaType = (mediaType?: EMediaType): IMediaFilterDTO => {
    const obj: IMediaFilterDTO = { page: ConstantDefault.page, amount: ConstantDefault.pageCount }
    if (mediaType) obj.mediaType = mediaType
    return obj
  }

  private getQueryParams = (): IMediaFilterDTO => {
    const obj: IFilterParmasDTO = QueryParam.GetAll()
    const objRes = { ...obj, page: obj.page ? parseInt(obj.page) : ConstantDefault.page }
    if (objRes.sortField && objRes.sortType) {
      objRes.sort = { field: objRes.sortField as keyof IMediaDTO, type: objRes.sortType as EOrder }
    }
    if (!!objRes.sortField) delete objRes.sortField
    if (!!objRes.sortType) delete objRes.sortType
    return objRes
  }

  setQueryParams = (value: IMediaFilterDTO) => {
    const obj: IFilterParmasDTO = { ...value, page: value.page?.toString() }
    delete obj.amount
    if (!value.page || value.page < 2) delete obj.page
    if (!value.searchKey) delete obj.searchKey
    if (obj.sort) {
      obj.sortField = obj.sort?.field
      obj.sortType = obj.sort?.type
    }
    delete obj.sort
    QueryParam.Put(obj)
  }

  parseMedia = (value: IMediaFilterDTO): TMediaFilter => {
    const page = value.page ? value.page - 1 : 0
    const filter: TMediaFilter = { Page: page, Amount: value.amount }

    let propFilters: PropFilter<IMedia>[] = [
      { FieldName: 'Type', Value: value.mediaType ?? EMediaType.Video, Operator: EOperator.Equal, Logic: ELogic.And },
    ]

    if (value.searchKey) {
      propFilters.push({
        Logic: ELogic.And,
        Scopes: [
          { FieldName: 'Name', Value: value.searchKey, Operator: EOperator.Contains },
          { FieldName: 'Description', Value: value.searchKey, Operator: EOperator.Contains, Logic: ELogic.Or },
        ],
      })
    }

    if (value.categories) {
      propFilters.push({ FieldName: 'CategoryNames', Operator: EOperator.Contains, Value: value.categories, Logic: ELogic.And })
    }

    if (value.sort) filter.PropOrders = [{ FieldName: value.sort.field, Type: value.sort.type }]

    filter.PropFilters = propFilters
    return filter
  }

  parseCategory = (value: IMediaFilterDTO): TCategoryFilter => {
    const filter: TCategoryFilter = {}
    let propFilters: PropFilter<ICategory>[] = [
      { FieldName: 'Type', Value: value.mediaType ?? EMediaType.Video, Operator: EOperator.Equal },
    ]
    filter.PropFilters = propFilters
    return filter
  }
}

const FilterMapping = new FilterMappingBase()
export default FilterMapping
