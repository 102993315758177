import React, { Component } from 'react'
import { Box, Container, Divider, Stack, styled } from '@mui/material'

interface IProps {
  isLoading?: boolean
  numberDataDisplayedString: string
  components: {
    top: JSX.Element
    categories: JSX.Element
    content: JSX.Element
    pagination: JSX.Element
  }
}

export default class Layout extends Component<IProps> {
  render() {
    const { components } = this.props
    return (
      <Container>
        <Stack sx={{ overflowX: 'hidden', gap: '20px', py: '25px', minHeight: '100vh' }}>
          {components.top}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>{components.categories}</Box>
            {components.pagination}
          </Box>
          <Box minHeight="500px">{components.content}</Box>
          <Divider sx={{ mt: '24px' }} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Text sx={{ flex: 1 }}>{this.props.numberDataDisplayedString}</Text>
            {components.pagination}
          </Box>
        </Stack>
      </Container>
    )
  }
}

const Text = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060',
})
