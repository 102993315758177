import { FC, PropsWithChildren } from 'react'
import { Fade, styled } from '@mui/material'

interface IProps {
  IsLazy?: boolean
}

export const LazyLoadView: FC<PropsWithChildren<IProps>> = (props) => {
  return (
    <>
      {props.children}
      <Fade in={props.IsLazy}>
        <Wrapper />
      </Fade>
    </>
  )
}

const Wrapper = styled('div')({
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  position: 'fixed',
  background: 'rgb(0,0,0,0.15)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100,
})
