import Graphql from '@coreprj/graphql/base'

export class GraphQLServiceBase {
  protected graphql: typeof Graphql.MediaStore
  protected queries: typeof Graphql.QMediaStore
  protected createBuilder: typeof Graphql.createRequestBuilder
  constructor() {
    this.graphql = Graphql.MediaStore
    this.queries = Graphql.QMediaStore
    this.createBuilder = Graphql.createRequestBuilder
  }
}
export default GraphQLServiceBase
