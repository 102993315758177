import { Add, Edit, Delete, Dashboard } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React, { Component } from 'react'
import { ITableTemplateState, TableTemplateContext } from '.'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import { GlobalModalContext, IGlobalModalContext } from '@coreprj/shareds/GlobalModal'

interface CRUDPannelProps {
  Create?: JSX.Element
  Edit?: JSX.Element
  Details?: JSX.Element
  Delete?: JSX.Element
  Title?: string
}

export default class CRUDPannel extends Component<CRUDPannelProps> {
  constructor(props: any) {
    super(props)
  }
  IsMultiple = (state: ITableTemplateState) => {
    return (state.selectionIds?.length ?? 0) > 1
  }
  IsDisplayAction = (state: ITableTemplateState) => {
    return !!state.selectionIds?.length
  }

  getButtons = function* (that: CRUDPannel, state: ITableTemplateState) {
    if (that.props.Edit)
      yield (
        <Button
          onClick={that.onEdit}
          key={'Edit'}
          sx={{ width: 100, height: 30 }}
          color='info'
          disabled={that.IsMultiple(state)}
          startIcon={<Edit />}
        >
          Edit
        </Button>
      )
    if (that.props.Delete)
      yield (
        <Button onClick={that.onDelete} key={'Delete'} sx={{ width: 100, height: 30 }} color='error' startIcon={<Delete />}>
          Delete
        </Button>
      )
    if (that.props.Details)
      yield (
        <Button
          onClick={that.onDetails}
          key={'Details'}
          sx={{ width: 100, height: 30 }}
          color='inherit'
          disabled={that.IsMultiple(state)}
          startIcon={<AppRegistrationIcon />}
        >
          Details
        </Button>
      )
  }

  onCreate = () => {
    this.ModalContext?.ShowModal({
      ContentModal: () => this.props.Create || <EmptyDiv />,
      sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    })
  }
  onEdit = () => {
    this.ModalContext?.ShowModal({
      ContentModal: () => this.props.Edit || <EmptyDiv />,
      sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    })
  }
  onDelete = () => {
    this.ModalContext?.ShowModal({
      ContentModal: () => this.props.Delete || <EmptyDiv />,
      sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    })
  }
  onDetails = () => {
    this.ModalContext?.ShowModal({
      ContentModal: () => this.props.Details || <EmptyDiv />,
      sx: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    })
  }
  ModalContext?: IGlobalModalContext
  render() {
    return (
      <GlobalModalContext.Consumer>
        {(context) => {
          this.ModalContext = context
          return (
            <TableTemplateContext.Consumer>
              {({ state }) => {
                const buttons = Array.from(this.getButtons(this, state))
                return (
                  <Box sx={{ height: 56, alignItems: 'center', padding: '0 9px', display: 'flex' }}>
                    <Box>
                      <Typography sx={{ fontWeight: 'bold' }}>{this.props.Title}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }} />
                    {this.props.Create && (
                      <Button onClick={this.onCreate} key={'Create'} sx={{ height: 30 }} variant='contained' startIcon={<Add />}>
                        Create
                      </Button>
                    )}
                    {buttons.length > 0 && (
                      <Box sx={{ display: this.IsDisplayAction(state) ? 'flex' : 'none' }}>{Array.from(this.getButtons(this, state))}</Box>
                    )}
                  </Box>
                )
              }}
            </TableTemplateContext.Consumer>
          )
        }}
      </GlobalModalContext.Consumer>
    )
  }
}
const EmptyDiv = () => <></>
