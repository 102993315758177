import React, { FC, Fragment, useState, MouseEvent } from 'react'
import { Button, Menu, MenuItem, SxProps, Theme, Typography, styled } from '@mui/material'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { SortMenuConfig } from './Config'
import { EOrder } from '../../../../Models/filter'

export interface ISortMenuProps {
  onChange?: (value?: EOrder) => void
  value?: EOrder
}

interface IProps extends ISortMenuProps {}

const SortMenu: FC<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const onOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (value?: EOrder) => {
    onClose()
    props.onChange && props.onChange(value)
  }

  const getButtonTitleString = () => {
    return props.value ? SortMenuConfig[props.value].display : 'Sort'
  }

  return (
    <Fragment>
      <CustomButton
        id="btn-sort-filter"
        aria-controls={open ? 'sort-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={onOpen}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        color="inherit"
      >
        {getButtonTitleString()}
      </CustomButton>

      <Menu
        keepMounted
        id="sort-menu"
        sx={{ mt: '45px' }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ elevation: 0, sx: sxPaperProps }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {/* <CustomDivider textAlign="left">Name</CustomDivider> */}
        <CustomMenu onClick={() => handleClick()} className={!props.value ? 'active' : ''}>
          <Typography textAlign="center">Nomal</Typography>
          <DoNotDisturbIcon />
        </CustomMenu>
        {Object.values(SortMenuConfig).map((e) => {
          const className = e.value === props.value ? 'active' : ''
          return (
            <CustomMenu key={e.value} onClick={() => handleClick(e.value)} className={className}>
              <Typography textAlign="center">{e.display}</Typography>
              {e.icon}
            </CustomMenu>
          )
        })}
      </Menu>
    </Fragment>
  )
}
export default SortMenu

const sxPaperProps: SxProps<Theme> = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  marginTop: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    marginLeft: -0.5,
    marginRight: 1,
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
}

// const CustomDivider = styled(Divider)({
//   '& .MuiDivider-wrapper': {
//     fontSize: '10px',
//     fontWeight: 700,
//   },
// })

const CustomButton = styled(Button)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: '500',
  color: '#606060',
})

const CustomMenu = styled(MenuItem)({
  minWidth: '150px',
  transition: 'all linear 0.2s',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '15px',
  paddingLeft: '30px',
  color: '#606060',
  '&: hover': {
    backgroundColor: '#dedede',
  },
  '&.active': {
    color: '#1976D2',
  },
})
