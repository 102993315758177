import { styled } from '@mui/material'
import { FC, useEffect, useRef } from 'react'

interface IProps {
  children: React.ReactNode
  isScrollToRight?: boolean
}
export const ScrollContainer: FC<IProps> = ({ children, isScrollToRight }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = scrollContainerRef.current

    if (container && isScrollToRight) {
      container.scrollLeft = container.scrollWidth
    }

    const handleWheel = (evt: WheelEvent) => {
      if (!container) return
      evt.preventDefault()
      container.scrollLeft += evt.deltaY
    }

    if (container) {
      container.addEventListener('wheel', handleWheel)
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel)
      }
    }
  }, [isScrollToRight])

  return <Container ref={scrollContainerRef}>{children}</Container>
}

export default ScrollContainer

const Container = styled('div')({
  overflowX: 'auto',
  flex: 1,
  /* width */
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  /* Track */
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    transition: 'all linear 0.2s',
  },
  '&:hover::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(135,135,135,0.15)',
    borderRadius: '4px',
    transition: 'all linear 0.2s',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    background: '#878787',
  },
  /* Handle on hover */
  '::-webkit-scrollbar-thumb:hover': {
    background: '#606060',
  },
})
