import { IGallery } from '@coreprj/types/models'
import { CRUDServiceBase } from '@coreprj/http/CRUDServiceBase'

class GalleryGuestServiceBase extends CRUDServiceBase<IGallery, number> {
  constructor() {
    super(`/api/v1/guest/Gallery`)
  }
}
const GalleryGuestService = new GalleryGuestServiceBase()
export default GalleryGuestService
