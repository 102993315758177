import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import CombinedReducers from './CombinedReducers'

const store = configureStore({
  reducer: CombinedReducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([thunkMiddleware])
  },
})

export default store
