import dayjs, { Dayjs } from 'dayjs'
import React, { FC, useCallback } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { IInputControl } from '@coreprj/shareds/Forms/ValidateForm/types'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { FormControl, FormHelperText, InputBase, styled } from '@mui/material'
import UiStyleds from '../UI/Styled'

interface DatePickersProps extends IInputControl {
  format?: string
  onChange?: (data: Date | null) => void
  onBlur?: () => void
  hiddenUnderline?: boolean
}
export const DatePickers: FC<DatePickersProps> = (props) => {
  const [value, setValue] = React.useState<Dayjs | null>(props.defaultValue ? dayjs(props.defaultValue) : dayjs())
  const handleChange = useCallback(
    (newValue: Dayjs | null) => {
      setValue(newValue)
      props.onChange && props.onChange(newValue?.toDate() ?? null)
    },
    [props]
  )
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomDatePicker
        label={props.title}
        format={props.format || 'MM/DD/YYYY'}
        views={['day', 'month', 'year']}
        value={value}
        onChange={handleChange}
        disabled={props.disabled}
        slotProps={{
          textField: {
            onBlur: props.onBlur,
            fullWidth: true,
            error: props.error,
            helperText: props.message,
            variant: 'standard'
          }
        }}
      />
      <input hidden name={props.name} value={value?.toISOString()} onChange={() => {}} />
    </LocalizationProvider>
  )
}

const CustomDatePicker = styled(DatePicker<Dayjs>)({
  '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
    borderBottom: 'none !important'
  }
})

interface IInputFieldProps extends IInputControl {}

export const InputField: FC<IInputFieldProps> = (props) => {
  const keytext = props.name + new Date().getTime()
  return (
    <FormControl fullWidth error={props.error}>
      <InputBase
        aria-describedby={keytext}
        size='small'
        onBlur={props.onBlur}
        name={props.name}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      <FormHelperText component='div' id={keytext} sx={{ position: 'absolute', left: 0, bottom: '-8px', m: 0 }}>
        <UiStyleds.Text12>{props.message}</UiStyleds.Text12>
      </FormHelperText>
    </FormControl>
  )
}
