import { FetchDelay } from '@coreprj/helpers/base'
import { CreateHttpService } from '@coreprj/http/Getway'
import ServiceBase, { ProcessError } from '@coreprj/http/ServiceBase'
import Graphql from '@coreprj/graphql/base'
import { authService } from 'partner-oidc-auth'

export type TNotificationInfo = Graphql.QMediaStore.TNotificationInfo
export type TNotificationStatus = Graphql.QMediaStore.TNotificationStatus
type TNotificationInfosGraphQlResult = Graphql.TGraphQlResult<TNotificationInfo>
type TNotificationInfosFilterParams = Graphql.QMediaStore.TNotificationInfosFilterParams
type TAllReponse = {
  NotificationInfos?: TNotificationInfosGraphQlResult
}

class NotificationServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`/api/v1/user/NoticationUser`))
  }

  getGraphql = async () => {
    if (await authService.isAuthenticated()) {
      return Graphql.MediaStore
    } else {
      return Graphql.GeustMediaStore
    }
  }

  All = async (params: TNotificationInfosFilterParams, signal?: AbortSignal): Promise<TAllReponse | undefined> => {
    try {
      const dataReponse: TAllReponse = {}
      if (await authService.isAuthenticated()) {
        const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.NotificationInfosQuery(params), { signal, delay: 0 })
        dataReponse.NotificationInfos = res.notification.notificationInfos as TNotificationInfosGraphQlResult
      } else {
        const res = await Graphql.GeustMediaStore.Query(Graphql.QMediaStore.GuestNotificationInfosQuery(params), { signal, delay: 0 })
        dataReponse.NotificationInfos = res.notification.notificationInfos as TNotificationInfosGraphQlResult
      }
      return dataReponse
    } catch (error: any) {
      ProcessError(error)
    }
  }

  ReadNotification = async (id: number, signal?: AbortSignal): Promise<undefined> => {
    return FetchDelay(async () => {
      if (await authService.isAuthenticated()) {
        const data = await this.TryPushNotify(this.Put<any>, 'UpdateRead', { IsRead: true }, { signal, params: { NotificationId: id } })
        return data
      } else {
        return true
      }
    }, 500)
  }
}
const NotificationService = new NotificationServiceBase()
export default NotificationService
