import React, { Component, PropsWithChildren, createContext } from 'react'
import { FormValidator, PartialError } from '../ValidateForm/FormValidator'
import { ConvertFormDataToJson, GetErrorFromResponse, SingleValidate } from '../ValidateForm/Common'
import { Box, SxProps, Theme } from '@mui/material'

interface InputFormBaseProps<TModel> {
  FormValidate: FormValidator<Partial<TModel>>
  onSubmit: (data: Partial<TModel>) => Promise<void>
  sx?: SxProps<Theme>
}
interface InputFormBaseState<TModel> {
  MessageError: PartialError<TModel>
  modelState?: Partial<TModel>
  onBlur: (keyName: keyof TModel) => void
}
export function CreateInputForm<TModel>() {
  const InputFormContext = createContext<InputFormBaseState<TModel>>({
    onBlur: () => {},
    MessageError: {}
  })
  class InputFormBase extends Component<PropsWithChildren<InputFormBaseProps<TModel>>, InputFormBaseState<TModel>> {
    constructor(props: InputFormBaseProps<TModel>) {
      super(props)
      this._formValidate = props.FormValidate
      this.state = {
        onBlur: this.onBlur,
        MessageError: {}
      }
    }
    _formValidate: FormValidator<Partial<TModel>>
    onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
      e.preventDefault()
      const { MessageError } = this.state
      const formData = new FormData(e.target as HTMLFormElement)
      const model = ConvertFormDataToJson<TModel>(formData)
      this.setState({ modelState: model })
      const messageErrors = this._formValidate.run(model)
      if (messageErrors) {
        this.setState({ MessageError: messageErrors as PartialError<TModel> })
        if (Object.keys(messageErrors).length > 0) return
      }
      await this.props.onSubmit(model).catch((error) => {
        const messageError = GetErrorFromResponse(error, model)
        this.setState({ MessageError: { ...MessageError, ...(messageError || {}) } })
      })
    }
    onBlur = (keyName: keyof TModel) => {
      if (!this._form) return
      const { MessageError } = this.state
      const formData = new FormData(this._form)
      const model = ConvertFormDataToJson(formData)
      this.setState({ modelState: model })
      const error = SingleValidate<TModel, Partial<TModel>>(keyName, model, MessageError, this._formValidate) || {}
      this.setState({ MessageError: error as PartialError<TModel> })
    }
    _form: HTMLFormElement | null = null
    render() {
      return (
        <Box component='form' sx={this.props.sx} ref={(ref: HTMLFormElement) => (this._form = ref)} onSubmit={this.onSubmit}>
          <InputFormContext.Provider value={this.state}>{this.props.children}</InputFormContext.Provider>
        </Box>
      )
    }
  }

  return {
    InputFormContext,
    InputFormBase
  }
}
