import { IVideoStream } from '../../../Models'
import { CreateHttpService } from '@coreprj/graphql/base'
import ServiceBase from '@coreprj/http/ServiceBase'
import { FetchDelay } from '../../../utilities'

class UploadServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Upload`))
  }
  _UrlGetVideoStream = '/GetVideoStream'
  GetVideoStream = (resourceId: string, signal?: AbortSignal) => {
    return FetchDelay(async () => {
      const url = `${this._UrlGetVideoStream}/${resourceId}`
      const data = await this.PushNotify(this.TryGet<IVideoStream[]>, url, {
        signal,
      })
      return (data !== null ? data : []) as IVideoStream[]
    }, 500)
  }
}

const UploadService = new UploadServiceBase()
export default UploadService
