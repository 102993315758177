import { EGalleryKey } from '@coreprj/types/enum'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IPagingModel } from '@coreprj/shareds/Tables/ModelFilter'
import { ICategory, IGallery, IGalleryDetail, IPrice } from '@coreprj/types/models'
import { CreateGalleryDetailGuestService } from '@coreprj/services/GalleryDetailGuestService'
import PriceGuestService from '@coreprj/services/PriceGuestService'
import GalleryGuestService from '@coreprj/services/GalleryGuestService'
import CategoryGuestService from '@coreprj/services/CategoryGuestService'

type TGalleryDetail = {
  [EGalleryKey.Videos1]?: IPagingModel<IGalleryDetail>
  [EGalleryKey.Videos2]?: IPagingModel<IGalleryDetail>
}

interface IHomeThunk {
  Categories?: IPagingModel<ICategory>
  Galleries?: IPagingModel<IGallery>
  Prices?: IPagingModel<IPrice>
  GalleryDetail?: TGalleryDetail
}

export const fetchHomeThunk = createAsyncThunk<IHomeThunk>('fetchHomeThunk', async (_, thunkAPI) => {
  const defaultData = { Data: [], Amount: 0, Page: 0, Total: 0 }
  const [Categories, Galleries, Prices] = await Promise.all([
    CategoryGuestService.All({}, thunkAPI.signal).catch((): IPagingModel<ICategory> => defaultData),
    GalleryGuestService.All({}, thunkAPI.signal).catch((): IPagingModel<IGallery> => defaultData),
    PriceGuestService.All({}, thunkAPI.signal).catch((): IPagingModel<IPrice> => defaultData)
  ])

  const gallery1 = Galleries?.Data.find((x) => x.Key === EGalleryKey.Videos1)
  const gallery2 = Galleries?.Data.find((x) => x.Key === EGalleryKey.Videos2)
  const [gVideo1, gVideo2] = await Promise.all([
    CreateGalleryDetailGuestService(gallery1?.Id ?? -1)
      .All({}, thunkAPI.signal)
      .catch((): IPagingModel<IGalleryDetail> => defaultData),
    CreateGalleryDetailGuestService(gallery2?.Id ?? -1)
      .All({}, thunkAPI.signal)
      .catch((): IPagingModel<IGalleryDetail> => defaultData)
  ])

  return {
    Categories,
    Galleries,
    Prices,
    GalleryDetail: { Videos1: gVideo1, Videos2: gVideo2 }
  }
})
