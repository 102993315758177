import { createAsyncThunk } from '@reduxjs/toolkit'
import { mapOrder } from '../Services/DataMapping'
import { ApiAlertContext } from '@coreprj/shareds/AlertGlobal'
import GraphQLOrderService from '@coreprj/services/GraphQLOrderService'
import { ICurrentDetailBase, IOrderDetailRequired, IOrderDetailStateSlice } from './type'

// ========= ========= ========= Main Redux ========= ========= =========
interface IParamsThunk {
  dataRequired?: IOrderDetailRequired
}

interface IResponseThunk extends ICurrentDetailBase {}

export const fetchOrderDetailThunk = createAsyncThunk<IResponseThunk, IParamsThunk | undefined>('fetchOrderDetailThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as { OrderDetailSlice: IOrderDetailStateSlice }
  if (!param?.dataRequired?.id) {
    ApiAlertContext.ApiAlert?.PushError('Order not found!')
    const dataResponse: IResponseThunk = { currentId: state.OrderDetailSlice.currentId ?? -1 }
    return dataResponse
  }
  const result = await GraphQLOrderService.single({ id: param.dataRequired.id }, thunkAPI.signal)
  const dataResponse: IResponseThunk = {
    currentId: param.dataRequired.id,
    dataDetail: mapOrder(result)
  }
  return dataResponse
})
