import React, { Component } from 'react'
import { Box } from '@mui/material'
import { EPaymentMethod } from '../../../../Models/enums'
import { PaypalMethodMapRedux } from '../../redux/OtherMap'
import { Text14, Text18 } from '../../../../Components/Text'
import PaymentMethodAccordions from './PaymentMethodAcodians'

interface IProps {}

interface IState {
  paymentMethod?: EPaymentMethod
}

export default class InformationPayment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { paymentMethod: EPaymentMethod.PayPal }
  }

  render() {
    return (
      <Box>
        <Text18 sx={{ fontWeight: 600 }}>Payment</Text18>
        <Text14 sx={{ fontStyle: 'initial' }}>All transactions are secure and encrypted.</Text14>
        <PaymentMethodAccordions
          value={this.state.paymentMethod}
          onChange={this.handleChangeMethod}
          config={[{ title: 'PayPal', type: EPaymentMethod.PayPal, body: this.renderPayPalContent() }]}
        />
      </Box>
    )
  }

  renderPayPalContent = () => (
    <Box>
      <Box sx={{ height: '48px' }}>
        <PaypalMethodMapRedux />
      </Box>
    </Box>
  )

  handleChangeMethod = (value: EPaymentMethod) => {
    if (value === this.state.paymentMethod) {
      this.setState({ paymentMethod: undefined })
    } else {
      this.setState({ paymentMethod: value })
    }
  }
}
