import React, { Component, PropsWithChildren } from 'react'
import { Box, LinearProgress } from '@mui/material'

interface ILazySpinnerProps {
  loading?: boolean
}
export default class LazySpinner extends Component<PropsWithChildren<ILazySpinnerProps>> {
  render() {
    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <LinearProgress sx={{ display: this.props.loading === true ? 'block' : 'none', position: 'absolute', left: 0, right: 0 }} />
        {this.props.children}
      </Box>
    )
  }
}
