import { IMediaFilterDTO } from '../type'
import { ICategory } from '../../../Models'
import { FetchDelay } from '../../../utilities'
import { CreateHttpService } from '@coreprj/graphql/base'
import FilterMapping from './FilterMapping'
import ServiceBase from '@coreprj/http/ServiceBase'

class CategoryServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Category`))
  }

  private urlAll = '/All'
  filter = (filter: IMediaFilterDTO, signal?: AbortSignal): Promise<ICategory[]> => {
    return FetchDelay(async () => {
      try {
        const filterReq = FilterMapping.parseCategory(filter)
        const res = await this.PushNotify(this.Post<any>, this.urlAll, filterReq, { signal })
        return res['Data'] as ICategory[]
      } catch (error) {
        return []
      }
    }, 500)
  }
}

const CategoryService = new CategoryServiceBase()
export default CategoryService
