import { Component } from 'react'
import { Box, Stack, styled } from '@mui/material'
import { IMediaPlayerProps } from './type'
import Utilities from '../../../../utilities'
import { AudioPlayer } from '../../../../Components'
import { AudioPlayEventProvider } from './EventProvider'

export class AudioMediaPlayerLayout extends Component<IMediaPlayerProps> {
  baseUrl = '/api/v1/guest/Upload/Play?ResourceId='
  render() {
    const { data } = this.props
    return (
      <Stack gap="10px">
        {/* <Text>Tags or Categories/Exo 2/Regular/14px</Text> */}
        <Text>by BERLINTOMEK</Text>
        {data && (
          <AudioPlayer
            key={data.Id}
            autoPlay={{
              play: true,
              EventProvider: AudioPlayEventProvider,
            }}
            tracksUrl={[this.baseUrl + data.ResourceId]}
          />
        )}
        <Box height="10px" />
        <Stack gap="20px">
          <Title>{data ? data.Name : 'Exo 2/Bold/20px'}</Title>
          <Text>{data ? data.Description : 'Author/Exo 2/Medium/16px'}</Text>
          {/* <Text>Views: Exo 2/Regular/14px</Text> */}
          <Stack direction="row" gap="25px">
            <Text>Size: {data ? Utilities.formatFileSize(data.Size) : 'Exo 2/Regular/14px'}</Text>
            <Text>{!!data ? Utilities.formatDatetime(data.DateCreated) : 'Date Added: Exo 2/Regular/14px'} </Text>
          </Stack>
        </Stack>
        <Text>{data && data.Description ? data.Description : 'Exo 2/Regular/14px'}</Text>
      </Stack>
    )
  }
}
export default AudioMediaPlayerLayout

const Title = styled('div')({
  fontSize: '20px',
  fontWeight: 700,
})

const Text = styled('div')({
  fontSize: '14px',
  fontWeight: 400,
  color: '#878787',
})
