import { Box, Typography, styled } from '@mui/material'

export const FormWrap = styled(Box)({
  padding: '24px 0 0 24px',
  border: '1px solid #DADCE0',
  marginTop: '24px',
  borderRadius: '4px',
})

export const Text16 = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  color: '#606060',
})

const UiStyleds = { FormWrap, Text16 }
export default UiStyleds
