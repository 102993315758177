import { createAsyncThunk } from '@reduxjs/toolkit'
import { ICartDTO } from '../type'
import CartLocalStorage from '../helpers/CartLocalStorage'
import CheckOutService from '../services/CheckoutService'
import { DataMapping } from '../services/DataMapping'
import QueryParam from '../../../utilities/QueryParam'

interface IQParams {
  referenceId: string
}

const setQueryParams = (value: IQParams) => {
  QueryParam.Put(value)
}

const getQueryParams = (): Partial<IQParams> => {
  const all = QueryParam.GetAll() as Partial<IQParams>
  return all
}

interface ICartResult {
  carts: ICartDTO[]
  referenceId: string
}

export const fetchCheckOutThunk = createAsyncThunk<ICartResult>('fetchCheckOutThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { CartSlice: ICartStateSlice }
  const localData = CartLocalStorage.getData()
  const qParams = getQueryParams()
  const res = await CheckOutService.createOrder(localData, qParams.referenceId, thunkAPI.signal)
  const carts = DataMapping.cartsRedux(res.OrderDetails, localData)
  if (res.ReferenceId) setQueryParams({ referenceId: res.ReferenceId })
  return { carts, referenceId: res.ReferenceId }
})
