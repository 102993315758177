import { EOrderPayment, EOrderStatus } from '@coreprj/types/enum'
import { IConvertGraphQLFilterOptions } from '@coreprj/http/Getway'
import { IOrder } from '@coreprj/types/models'
import { TOrder } from '@coreprj/services/GraphQLOrderService'

export const getConvertGraphQLFilterOptions = (): IConvertGraphQLFilterOptions<Omit<IOrder, 'CategorieNames'>, TOrder> => {
  return {
    quickFilters: ['Name', 'Id', 'Status'],
    mapQuickFilters: {
      Id: 'id',
      UserId: 'userId',
      Name: 'name',
      Description: 'description',
      ReferenceId: 'referenceId',
      Status: 'status',
      Method: 'method',
      TransactionId: 'transactionId',
      TransactionInfo: 'transactionInfo',
      DateCreated: 'dateCreated'
    }
  }
}

export const mapOrdersDataTable = (items: TOrder[] = []): IOrder[] => {
  return items.map<IOrder>((item) => ({
    Id: item.id,
    UserId: item.userId,
    Name: item.name,
    DisplayName: item.user?.displayName ?? '',
    Description: item.description ?? '',
    ReferenceId: item.referenceId,
    Status: item.status as EOrderStatus,
    Method: item.method as EOrderPayment,
    TransactionId: item.transactionId ?? '',
    TransactionInfo: item.transactionInfo ?? '',
    DateCreated: item.dateCreated
  }))
}

export const mapOrderRequest = (item: Partial<IOrder>): Partial<IOrder> => {
  return item
}
