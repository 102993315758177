import { Button, Stack, Typography } from '@mui/material'
import React, { Component } from 'react'

export default class ErrorsScreen extends Component {
  render() {
    return (
      <Stack sx={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h1">Errors Something</Typography>
        <Typography variant="subtitle1">Please try again :{'(('}</Typography>
        <Button variant="outlined" color="inherit" LinkComponent="a" href="/">
          Try again
        </Button>
      </Stack>
    )
  }
}
