import React, { Component, FC } from 'react'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import CheckOutService from '../../services/CheckoutService'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { NavigateKey } from '../../../../Route'
import { IPayPalReduxProps } from '../../type'
import { ELazyStatus } from '../../../../Models/enums'

export interface IPaypalMethodProps extends IPayPalReduxProps {}

interface IPaypalMethodBaseProps extends IPaypalMethodProps {
  navigate: NavigateFunction
}

class PaypalMethodBase extends Component<IPaypalMethodBaseProps> {
  Token = ''
  render() {
    return (
      <PayPalScriptProvider
        options={{
          clientId: 'AbrzR6ytqr_zTr52zwDyhXKSCzvoGXCgjuLD7qSR3auevSPXIK5Sso__1z1eU5wQz6zJ-JmBaK-MlZE4',
          disableFunding: 'paylater', // Disable PayPal Pay Later button
        }}
      >
        <PayPalButtons
          style={{ height: 42 }}
          createOrder={this.handleCreateOrder}
          onApprove={async (data, actions) => {
            this.handleApprove(data.orderID)
          }}
          fundingSource="paypal"
        />
      </PayPalScriptProvider>
    )
  }

  handleCreateOrder = async (): Promise<string> => {
    const referenceId = this.getReferenceId()
    if (!referenceId) return ''
    const res = await CheckOutService.payPalCreate(referenceId)
    this.Token = res?.Token ?? ''
    return res?.Id ?? ''
  }

  handleApprove = async (orderId: string) => {
    try {
      this.props.changeStatus(ELazyStatus.Loading)
      await CheckOutService.payPalCapture({ orderId, token: this.Token })
      this.props.removeAll()
      this.props.navigate(NavigateKey.CheckOutComplete)
    } catch (error) {
      // Xử lý khi có lỗi lúc Approve
      console.log(error)
    }
    this.props.changeStatus(ELazyStatus.Loaded)
  }

  getReferenceId = () => {
    return this.props.CheckOutSlice.referenceId
  }
}

const PaypalMethod: FC<IPaypalMethodProps> = (props) => {
  return <PaypalMethodBase {...props} navigate={useNavigate()} />
}

export default PaypalMethod
