import { FetchDelay } from '../../../utilities'
import { CreateHttpService } from '@coreprj/graphql/base'
import { IOrderDetail, IPaypalOrder } from '../../../Models'
import ServiceBase from '@coreprj/http/ServiceBase'
import { DataMapping } from './DataMapping'
import { ICartDTO } from '../type'

interface IValidateResult {
  ReferenceId: string
  OrderDetails: IOrderDetail[]
}

class CheckOutServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/user/Checkout`))
  }

  private urlCreateOrder = '/CreateOrder'
  createOrder = (value: ICartDTO[], referenceId?: string, signal?: AbortSignal): Promise<IValidateResult> => {
    return FetchDelay(async () => {
      const bodyRequest = DataMapping.cartsCheckout(value)
      const result = await this.PushNotify(this.Post<IValidateResult>, this.urlCreateOrder, bodyRequest, { signal, params: { referenceId } })
      return result
    }, 700)
  }

  private urlPayPalCreate = (referenceId: string) => `/paypal/Create?referenceId=${referenceId}`
  payPalCreate = (referenceId: string, signal?: AbortSignal): Promise<IPaypalOrder | null | undefined> => {
    return FetchDelay(async () => {
      const result = await this.TryPushNotify(this.Post<IPaypalOrder>, this.urlPayPalCreate(referenceId), {}, { signal })
      return result
    }, 1500)
  }

  private urlPayPalCapture = '/paypal/Capture'
  payPalCapture = (value: { orderId: string; token: string }, signal?: AbortSignal): Promise<number> => {
    return FetchDelay(async () => {
      const params = `?ApprovedId=${value.orderId}&Token=${value.token}`
      const result = await this.PushNotify(this.Get<number>, this.urlPayPalCapture + params, { signal })
      return result
    }, 1500)
  }
}

const CheckOutService = new CheckOutServiceBase()
export default CheckOutService
