import { fetchHomeThunk } from './Thunks'
import { hocConnect } from '../../../Redux/hocs/hocConnect'
import { AppDispatch, RootState } from '../../../Redux/type'
import { IHomeDispatchRedux, IHomeStateRedux, THomeMapRedux } from './type'
import ViewBase from '../ViewBase'

const mapStateToProps = (state: RootState): IHomeStateRedux => ({
  Status: state.HomeSlice.Status,
  HomeSlice: state.HomeSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): IHomeDispatchRedux => {
  return {
    fetchInitial: () => {
      return dispatch(fetchHomeThunk())
      // return { abort: () => WindowScrollToTop() }
    },
  }
}
export const HomeMapRedux: THomeMapRedux = hocConnect(ViewBase, mapStateToProps, mapDispatchToProps)
