import React, { Component } from 'react'
import { ELazyStatus, EMediaType } from '../../Models/enums'
import { EOrder } from '../../Models/filter'
import { WindowScrollToTop } from '../../utilities'
import { IExploreReduxProps, ICategoryDTO, IMediaDTO } from './type'
import Layout from './ui/Layout'
import TopBar from './ui/TopBar'
import Content from './ui/Content'
import CustomPagination from './ui/CustomPagination'
import RecommendedCategories from './ui/RecommendedCategories'

interface IProps extends IExploreReduxProps {}

export default class ViewBase extends Component<IProps> {
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const check1 = prevProps.ExploreSlice.filter.page !== this.props.ExploreSlice.filter.page
    const check2 = prevProps.ExploreSlice.filter.mediaType !== this.props.ExploreSlice.filter.mediaType
    const check3 = prevProps.ExploreSlice.medias.ids.length !== this.props.ExploreSlice.medias.ids.length
    if (check1 || check2 || check3) {
      WindowScrollToTop()
    }
  }

  render() {
    const categories = this.getCategories().map((e) => e.Name)
    const categorySelected = this.props.ExploreSlice.filter.categories

    const medias = this.getMedias()
    const mediaTotal = this.props.ExploreSlice.mediaInfo.total ?? 0
    const page = this.props.ExploreSlice.filter.page ?? 0
    const amount = this.props.ExploreSlice.filter.amount ?? 0
    const totalString = this.getTotalString(page, amount, mediaTotal)
    const count = Math.ceil(mediaTotal / amount)

    return (
      <Layout
        isLoading={this.props.Status === ELazyStatus.Loading}
        numberDataDisplayedString={totalString}
        components={{
          top: this.renderTopBar(mediaTotal),
          categories: <RecommendedCategories data={categories} onClick={this.handleClickCategory} selected={categorySelected} />,
          content: <Content data={medias} />,
          pagination: <CustomPagination count={count} page={page} onChange={this.handleChangePagination} />,
        }}
      />
    )
  }

  renderTopBar = (count: number) => (
    <TopBar
      count={count}
      subFilterString={this.getSubFilter()}
      sortMenuProps={{
        onChange: this.handleChangeSortMenu,
        value: this.props.ExploreSlice.filter.sort?.type,
      }}
    />
  )

  getSubFilter = (): string => {
    const { filter } = this.props.ExploreSlice
    const list: string[] = [(filter.mediaType ?? EMediaType.Video).toString()]
    if (filter.searchKey) list.push(filter.searchKey)
    return `search by: ${list.join(' | ')}`
  }

  getTotalString = (page: number, amount: number, total: number) => {
    const numFrom = (page - 1) * amount + 1
    const numTo = Math.min(page * amount, total)
    return `${numFrom}-${numTo} of ${total} items`
  }

  getMedias = () => {
    const { ids, entities } = this.props.ExploreSlice.medias
    const list = ids.map((id) => entities[id])
    return list as IMediaDTO[]
  }

  getCategories = () => {
    const list = Object.values(this.props.ExploreSlice.categories.entities)
    return list as ICategoryDTO[]
  }

  handleChangeSortMenu = (value?: EOrder) => {
    this.props.putFilter('sort', value ? { field: 'Name', type: value } : undefined)
  }

  handleChangePagination = (page: number) => {
    this.props.putFilter('page', page)
  }

  handleClickCategory = (value?: string) => {
    this.props.putFilter('categories', value)
  }
}

// Wrapper component to use hooks in class component
// const withLocation = (WrappedComponent: any) => {
//   return (props: any) => {
//     const location = useLocation();
//     return <WrappedComponent {...props} location={location} />;
//   };
// };
