import { IJsonContentPricePackage } from '../../../Models'
import { ICartDTO } from '../type'

class HelpersBase {
  maxLink = 10

  getImageUrl = (resourceId: string) => {
    return '/api/v1/guest/Upload/VideoThumb/' + resourceId
  }

  getTotal = (value: ICartDTO[]) => {
    const sum = value.reduce<number>((a, b) => {
      const price = b.price ?? 0
      const amount = b.amount ?? 0
      a += price * amount
      return a
    }, 0)
    return sum.toFixed(2)
  }

  getSubTotal = (value: ICartDTO) => {
    const price = value.price ?? 0
    const amount = value.amount ?? 0
    return price * amount
  }

  getColor = (value: ICartDTO) => {
    try {
      const obj: IJsonContentPricePackage = JSON.parse(value.pricePackage?.JsonContent ?? '')
      return obj.color
    } catch (error) {
      return '#979797'
    }
  }
}

const Helpers = new HelpersBase()
export default Helpers
