import React, { Component } from 'react'
import { IconButton, SxProps, Theme, Badge } from '@mui/material'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

interface IProps {
  count: number
  onClick: (target: HTMLElement) => void
  sx?: SxProps<Theme>
}

export default class BadgeIcon extends Component<IProps> {
  render() {
    return (
      <IconButton component='div' sx={this.props.sx} onClick={(event) => this.props.onClick(event.currentTarget)}>
        <Badge component='div' badgeContent={this.props.count} color='error'>
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
    )
  }
}
