/* eslint-disable @typescript-eslint/no-explicit-any */
import { connect } from 'react-redux'
import { fetchCartThunk, fetchCheckOutValidateThunk } from './Thunks'
import { hocConnect } from '../../../Redux/hocs/hocConnect'
import { AppDispatch, RootState } from '../../../Redux/type'
import { ICartDispatchRedux, ICartStateRedux, TAddToCartButtonMapRedux, TCartMapRedux } from '../type'
import CartSlice from './Slice'
import ViewBase from '../ViewBase'
import CartBadge from '../CartBadge'
import ChooseLicense from '../ChooseLicense'
import AddToCartButton from '../AddToCartButton'

// ========= ========= ========= View Base ========= ========= =========
const mapStateToProps = (state: RootState): ICartStateRedux => ({
  Status: state.CartSlice.Status,
  CartSlice: state.CartSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): ICartDispatchRedux => {
  return {
    fetchInitial: () => {
      return dispatch(fetchCartThunk())
    },
    addCart: (value) => {
      dispatch(CartSlice.actions.addCart(value))
    },
    updateCart: (value) => {
      dispatch(CartSlice.actions.updateCart(value))
    },
    removeCart: (id) => {
      dispatch(CartSlice.actions.removeCart(id))
    },
    checkOutValidate: async () => {
      await dispatch(fetchCheckOutValidateThunk())
    },
  }
}

export const CartMapRedux: TCartMapRedux = hocConnect<any, ICartStateRedux, ICartDispatchRedux>(
  ViewBase,
  mapStateToProps,
  mapDispatchToProps
)

export const ChooseLicenseMapRedux = connect(mapStateToProps, mapDispatchToProps)(ChooseLicense)
export const CartBadgeMapRedux = connect(mapStateToProps, mapDispatchToProps)(CartBadge)
export const AddToCartButtonMapRedux: TAddToCartButtonMapRedux = connect(mapStateToProps, mapDispatchToProps)(AddToCartButton)
