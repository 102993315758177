import { Component, ComponentType } from 'react'
import { connect, ConnectedComponent } from 'react-redux'
import { AppDispatch, IReturnDispatch, RootState, TDispatchRedux, TStateRedux } from '../type'
import { ELazyStatus } from '../../Models/enums'
import ErrorsScreen from '../../Views/ErrorsScreen'

type ActionMapStateToProps<TState> = (state: RootState) => TState

type ActionMapDispatchToProps<TProp> = (dispatch: AppDispatch, props?: any) => TProp

interface OptionsHocLazy<TActionParam> {
  externalParams?: TActionParam
}

interface hocComponentProp<TActionParam> {
  externalParams?: TActionParam
}

export const hocConnect = function <
  TActionParam,
  TMapState extends TStateRedux,
  TMapDispatch extends TDispatchRedux<TActionParam> = TDispatchRedux<TActionParam>,
  TComponentProp = any
>(
  WrappedComponent: ComponentType<any>,
  actionState: ActionMapStateToProps<TMapState> | null = null,
  actionProp: ActionMapDispatchToProps<TMapDispatch> | null = null,
  options: OptionsHocLazy<TActionParam> | null = null
) {
  type Props = hocComponentProp<TActionParam> & TMapState & TMapDispatch & TComponentProp
  class HocComponent extends Component<Props> {
    renderContent = () => {
      switch (this.props.Status) {
        case ELazyStatus.Loaded:
        case ELazyStatus.Loading:
          return <WrappedComponent {...this.props} />
        case ELazyStatus.Error:
          return <WrappedComponent {...this.props} />
        default:
          return <ErrorsScreen />
      }
    }

    componentDidMount = () => {
      const param = Object.assign({}, options?.externalParams ?? {}, this.props.externalParams ?? {})
      this.DispatchReturn = this.props.fetchInitial ? this.props.fetchInitial(param) : undefined
    }
    DispatchReturn?: IReturnDispatch
    componentWillUnmount() {
      if (this.DispatchReturn) {
        const { abort = () => {} } = this.DispatchReturn
        abort()
      }
    }
    render = () => {
      return this.renderContent()
    }
  }
  const CustomActionProp = (dispatch: AppDispatch) => {
    return {
      ...(actionProp ? actionProp(dispatch) : {}),
    }
  }
  const Comp = connect(actionState, CustomActionProp, null, {
    forwardRef: true,
  })(HocComponent as any)
  return Comp as ConnectedComponent<ComponentType<TComponentProp>, TComponentProp>
}
