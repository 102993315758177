import { IPricePackage } from '../../../Models'
import { CreateHttpService } from '@coreprj/graphql/base'
import ServiceBase from '@coreprj/http/ServiceBase'
import { FetchDelay } from '../../../utilities'
import { IPagingModel } from '@coreprj/shareds/Tables/ModelFilter'

class PricePackageServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/guest/Price`))
  }

  private urlAll = '/All'
  all = (signal?: AbortSignal): Promise<IPricePackage[]> => {
    return FetchDelay(async () => {
      try {
        const res = await this.PushNotify(this.Post<IPagingModel<IPricePackage>>, this.urlAll, { signal })
        return res['Data'] as IPricePackage[]
      } catch (error: any) {
        console.log(`Error MediaService.All ${error.message}`)
        return []
      }
    }, 500)
  }
}

const PricePackageService = new PricePackageServiceBase()
export default PricePackageService
