import React, { Component, FC, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { authService } from 'partner-oidc-auth'
import { IRoute, IAppRouterProps, IAppRouterState } from './type'
import { AuthorizeRoute } from '@coreprj/auth-oidc-ui/AuthorizeRoute'

import { RoleKeyExternalSite } from './RoleKeyExternalSite'
import Layout from '../Views/Shareds/Layout'

export * from './NavigateKey'
;(window as any).authService = authService
interface OptionPage {
  title?: string
  prefix?: string
}
interface PageRouteProps {
  title?: string
  prefix?: string
}

export const PageRoute: FC<React.PropsWithChildren<PageRouteProps>> = (props) => {
  useEffect(() => {
    const title = (props.title ?? '') === '/' ? '' : props.title ?? ''
    const tmps = [props.prefix || '', title]
    document.title = tmps
      .filter((x) => x)
      .join('-')
      .replace(/\W+/, '-')
  }, [props.prefix, props.title])
  return <>{props.children}</>
}

export const WrapPageRoute = (WrapComponent: JSX.Element, option?: OptionPage) => {
  const InnerPageRoute = (props: any) => {
    const { title, ...other } = props

    option = option ? option : {}
    return (
      <PageRoute title={option.title ?? title} prefix={option.prefix}>
        {React.cloneElement(WrapComponent, other)}
      </PageRoute>
    )
  }
  return InnerPageRoute
}

// const ElementVirtual: FC<React.PropsWithChildren> = (props) => {
//     return props.children
// }
const removeFirstSlash = (router: string) => {
  if (router.startsWith('/')) {
    return router.substring(1)
  }
  return router
}

export class AppRouter extends Component<IAppRouterProps, IAppRouterState> {
  /**
   *
   */
  constructor(props: IAppRouterProps) {
    super(props)
    this.state = {
      roles: []
    }
    this.Initial([])
    this.subId = authService.subscribe(this.updateAuthentication)
  }
  isComponentMounted: boolean = true
  WrapRouter: JSX.Element[] = []
  subId: number = -1

  updateRole = async () => {
    const roles2 = (await authService.getRoles()) ?? []

    const roles1 = this.state.roles
    const temp1 = new Set(this.state.roles)
    const temp2 = new Set(roles2)

    if (roles1.filter((x) => !temp2.has(x)).length !== roles2.filter((x) => !temp1.has(x)).length) {
      this.Initial(roles2)
    }
    this.setState({ roles: roles2 })
  }

  IsAllowAccess = (role: string, roles?: string[][]) => {
    if (!roles || !roles.length || !roles[0].length) return true
    return roles.every((x) => x.some((y) => y.toLowerCase() === role.toLowerCase()))
  }

  RenderRouter = (roles: string[], router: IRoute) => {
    const path = router.Path
    if ('Children' in router) {
      return router.Children ? (
        <Route key={router.Path} path={router.Path} element={router.element}>
          {router.Children.map((item) => this.RenderRouter(roles, Object.assign({}, item, { Path: removeFirstSlash(item.Path) })))}
        </Route>
      ) : (
        <Route />
      )
    }

    const IsAllow = roles && roles.length ? roles.some((role) => this.IsAllowAccess(role, router.roles)) : true

    const Element = !IsAllow ? <></> : router.roles ? <AuthorizeRoute element={router.element} path={path} roles={router.roles} /> : router.element

    const ComponentRoute = WrapPageRoute(Element, {
      prefix: 'dino',
      title: router.Title ?? router.Path?.toLocaleLowerCase() ?? ''
    })

    return <Route key={router.Path} path={path} element={<ComponentRoute />} />
  }

  Initial = (roles: string[]) => {
    this.WrapRouter = this.props.Routers.filter((x) => x.Hiden !== false).map((route) => this.RenderRouter(roles, route))
  }
  componentDidUpdate(prevProps: Readonly<IAppRouterProps>, prevState: Readonly<object>, snapshot?: any): void {
    if (prevProps.Routers !== this.props.Routers) {
      this.Initial(this.state.roles ?? [])
    }
  }
  updateAuthentication = async () => {
    if (this.isComponentMounted) {
      await this.updateRole()
    }
  }
  componentWillUnmount(): void {
    this.isComponentMounted = false
    authService.unsubscribe(this.subId)
  }
  componentDidMount = () => {
    this.updateRole()
  }

  getRootUrl = () => {
    return authService.getReturnUrl()
  }

  render() {
    return (
      <Routes>
        <Route path='/' element={<Layout />} errorElement={this.props.errorElement}>
          {this.WrapRouter}
        </Route>
      </Routes>
    )
  }
}

export const HideMenuRole = (role: RoleKeyExternalSite) => {
  return !Object.values(RoleKeyExternalSite).some((x) => x === role)
}
