import { INotification, IOrder } from '@coreprj/types/models'
import { IConvertGraphQLFilterOptions } from '@coreprj/http/Getway'
import { TNotificationInfo } from '@coreprj/services/NotificationService'
import { ENotificationAreaType, ENotificationSendType } from '@coreprj/types/enum'
import { getLocalStorageNotification } from './LocalStorageNotification'

export const getConvertGraphQLFilterOptions = (): IConvertGraphQLFilterOptions<INotification, TNotificationInfo> => {
  return {
    quickFilters: ['Id'],
    mapQuickFilters: {
      Id: 'id',
      Title: 'title',
      SubTitle: 'subTitle',
      Content: 'content',
      DateExpired: 'dateExpired',
      SendType: 'sendType',
      AreaType: 'areaType',
      DateCreated: 'dateCreated',
      DateUpdated: 'dateUpdated'
    }
  }
}

export const getReadedNotificationIds = (items: TNotificationInfo[] = []) => {
  const setIds = items.reduce<Set<number>>((a, b) => {
    b.notificationStatuses?.map((x) => x?.notificationId && a.add(x.notificationId))
    return a
  }, new Set<number>([]))
  return Array.from(setIds)
}

export const mapNotificationsDataTable = (items: TNotificationInfo[] = []): INotification[] => {
  let temp = getReadedNotificationIds(items)
  if (temp.length < 1) temp = getLocalStorageNotification()
  const sIds = new Set(temp)
  return items.map<INotification>((item) => {
    const data: INotification = {
      Id: item.id,
      Title: item?.title ?? '',
      SubTitle: item?.subTitle ?? '',
      Content: item?.content ?? '',
      DateExpired: item?.dateExpired ?? '',
      SendType: item?.sendType as ENotificationSendType,
      AreaType: item?.areaType as ENotificationAreaType,
      IsRead: sIds.has(item.id),
      DateCreated: item?.dateCreated ?? ''
    }
    return data
  })
}

export const mapOrderRequest = (item: Partial<IOrder>): Partial<IOrder> => {
  return item
}
