import { IOrderDetailStateSlice } from './type'
import { fetchOrderDetailThunk } from './Thunks'
import { IOrder } from '@coreprj/types/models'
import { CreateCRUDSlice2 } from '@coreprj/redux/Core/CRUDSlice2'
import { LazyStatus } from '@coreprj/redux/Core/types'

export const OrderDetailSlice = CreateCRUDSlice2<IOrder>()({
  name: 'OrderDetailSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const init: IOrderDetailStateSlice = { ...x, currentId: -1 }
    return init
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchOrderDetailThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = LazyStatus.Loaded

        state.currentId = action.payload.currentId
        state.dataDetail = action.payload.dataDetail
      })
      .addCase(fetchOrderDetailThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = LazyStatus.Error
      })
      .addCase(fetchOrderDetailThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = LazyStatus.Loading
      })
  },
  reducers: {}
})
