import { createSlice } from '@reduxjs/toolkit'
import { IPricingStateSlice } from '../type'
import { ELazyStatus } from '../../../Models/enums'
import { fetchPricingThunk } from './Thunks'

// Define the initial state using that type
const initialState: IPricingStateSlice = {
  Status: ELazyStatus.Loaded,
  pricePackages: [],
}

const PricingSlice = createSlice({
  name: 'PricingSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPricingThunk.fulfilled, (state, action) => {
        state.Status = ELazyStatus.Loaded
        state.pricePackages = action.payload.pricePackages
      })
      .addCase(fetchPricingThunk.rejected, (state) => {
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchPricingThunk.pending, (state) => {
        state.Status = ELazyStatus.Loading
        state.pricePackages = []
      })
  },
})
export default PricingSlice
