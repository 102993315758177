import { Tooltip, TooltipProps } from '@mui/material'
import dayjs from 'dayjs'

export default class TableFormater {
  static formatSize = (size: number): string => {
    if (typeof size !== 'number' || Number.isNaN(size) === true) {
      return 'unknown size'
    }

    if (size <= 0) {
      return '0 bytes'
    }

    const abbreviations = ['bytes', 'KiB', 'MiB', 'GiB']
    const index = Math.floor(Math.log(size) / Math.log(1024))

    return `${+(size / Math.pow(1024, index)).toPrecision(3)} ${abbreviations[index]}`
  }

  static formatDuration = (duration: number): string => {
    if (typeof duration !== 'number' || Number.isNaN(duration) === true) {
      return '00:00:00'
    }

    // Round the duration to the nearest second
    const totalSeconds = Math.round(duration)

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    // Format each component to ensure it has two digits
    const hoursString = String(hours).padStart(2, '0')
    const minutesString = String(minutes).padStart(2, '0')
    const secondsString = String(seconds).padStart(2, '0')

    // Combine the components into a single string
    return `${hoursString}:${minutesString}:${secondsString}`
  }

  static formatDateString = 'DD/MM/YYYY HH:mm:ss'
  static formatDate = (dateString?: string) => {
    return dateString ? dayjs(dateString).format(TableFormater.formatDateString) : ''
  }

  static tooltip = (value: string, tooltipProps?: Omit<TooltipProps, 'children'>) => {
    return (
      <Tooltip {...tooltipProps} title={tooltipProps ? tooltipProps.title : value}>
        <span>{value}</span>
      </Tooltip>
    )
  }

  static tooltipDate = (dateString?: string) => {
    const { tooltip, formatDate, formatDateString } = TableFormater
    return tooltip(formatDate(dateString), { title: formatDateString })
  }

  static formatCurrency = (value?: string | number): string => {
    const dString = '$ '.concat((0).toFixed(2))
    if (!value) return dString
    try {
      let num = parseInt((value ?? '0').toString())
      return '$ '.concat(num.toFixed(0))
    } catch (error) {
      return dString
    }
  }
}
