import React, { Component } from 'react'
import { Box, Divider, Stack, styled } from '@mui/material'
import SortMenu, { ISortMenuProps } from './SortMenu'

interface IProps {
  count: number
  subFilterString?: string
  sortMenuProps?: ISortMenuProps
}

export default class TopBar extends Component<IProps> {
  render() {
    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <Divider orientation="vertical" />
          <SortMenu value={this.props.sortMenuProps?.value} onChange={this.props.sortMenuProps?.onChange} />
        </Box>
        <Divider />
        <Stack gap="10px" py="10px">
          <Title>{this.props.count} results</Title>
          <SubTitle>{this.props.subFilterString}</SubTitle>
        </Stack>
      </Box>
    )
  }
}

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '36px',
  fontWeight: 600,
  color: '#606060',
})

const SubTitle = styled('p')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 600,
  color: '#c2c2c2',
  fontStyle: 'italic'
})
