import { FC } from 'react'
import { EMediaType } from '../../Models/enums'
import VideoBox from './VideoBox'
import AudioBox from './AudioBox'
import { IProducItemProps } from './type'

const _comp = {
  [EMediaType.Video]: VideoBox,
  [EMediaType.Audio]: AudioBox,
}
export const MediaItem: FC<IProducItemProps> = (props) => {
  const Comp = _comp[props.data.Type]
  return <Comp {...props} />
}
export default MediaItem
