import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchExploreThunk } from './Thunks'
import { EMediaType, ELazyStatus } from '../../../Models/enums'
import { IMediaDTO, IExploreStateSlice, IMediaFilterDTO, ICategoryDTO, TFilterField } from '../type'
import FilterMapping from '../services/FilterMapping'

const MediaSliceAdapter = createEntityAdapter<IMediaDTO>({ selectId: (x) => x.Id })
const CategoriesSliceAdapter = createEntityAdapter<ICategoryDTO>({ selectId: (x) => x.Id })

// Define the initial state using that type
const initialState: IExploreStateSlice = {
  Status: ELazyStatus.Loading,
  medias: MediaSliceAdapter.getInitialState(),
  categories: CategoriesSliceAdapter.getInitialState(),
  filter: FilterMapping.initilal(),
  mediaInfo: {},
}

const ExploreSlice = createSlice({
  name: 'ExploreSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<ELazyStatus>) => {
      state.Status = action.payload
    },
    updateFilter: (state: any, action: PayloadAction<TFilterField<IMediaFilterDTO>>) => {
      if (action.payload.key === 'mediaType') {
        state.filter = FilterMapping.byChangeMediaType(action.payload.value as EMediaType)
      } else {
        state.filter[action.payload.key] = action.payload.value
      }
      FilterMapping.setQueryParams(state.filter)
    },
    fetchFromQueryParams: (state) => {
      state.filter = FilterMapping.initilal()
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExploreThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded

        CategoriesSliceAdapter.removeAll(state.categories)
        CategoriesSliceAdapter.addMany(state.categories, action.payload.categories)

        MediaSliceAdapter.removeAll(state.medias)
        MediaSliceAdapter.addMany(state.medias, action.payload.medias)
        state.mediaInfo.total = action.payload.mediaTotal
      })
      .addCase(fetchExploreThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchExploreThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
      })

    // builder
    //   .addCase(fetchExploreFilterMediaThunk.fulfilled, (state, action) => {
    //     if (state.requestId !== action.meta.requestId) return
    //     state.status = ELazyStatus.Loaded

    //     MediaSliceAdapter.removeAll(state.medias)
    //     MediaSliceAdapter.addMany(state.medias, action.payload.medias)
    //     state.mediaInfo.total = action.payload.mediaTotal
    //   })
    //   .addCase(fetchExploreFilterMediaThunk.rejected, (state, action) => {
    //     if (state.requestId !== action.meta.requestId) return
    //     state.status = ELazyStatus.Error
    //   })
    //   .addCase(fetchExploreFilterMediaThunk.pending, (state, action) => {
    //     state.requestId = action.meta.requestId
    //     state.status = ELazyStatus.Loading
    //   })
  },
})
export default ExploreSlice
