import { Box, Typography, styled } from '@mui/material'

export default class UiStyleds {
  static FormWrap = styled(Box)({
    padding: '24px 0 0 24px',
    border: '1px solid #DADCE0',
    marginTop: '24px',
    borderRadius: '4px',
    position: 'relative',
    overflow: 'hidden',
    '& .MuiFormHelperText-root.Mui-error p': {
      color: '#FF3419 !important'
    }
  })

  static Text12 = styled(Typography)({
    fontFamily: "'Exo 2', sans-serif",
    fontSize: '12px',
    color: '#606060'
  })

  static Text14 = styled(Typography)({
    fontFamily: "'Exo 2', sans-serif",
    fontSize: '14px',
    color: '#606060'
  })

  static Text16 = styled(Typography)({
    fontFamily: "'Exo 2', sans-serif",
    fontSize: '16px',
    color: '#606060'
  })

  static Text18 = styled(Typography)({
    fontFamily: "'Exo 2', sans-serif",
    fontSize: '18px',
    color: '#606060'
  })

  static Text20 = styled(Typography)({
    fontFamily: "'Exo 2', sans-serif",
    fontSize: '20px',
    color: '#606060'
  })
}
