import React, { ChangeEventHandler, FC, FormEventHandler, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Divider, IconButton, InputBase, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { AppConstant } from '../../../../utilities'
import { SupportDisplayData } from '../../../../utilities/SupportDisplayData'
import MediaTypeMenu from './MediaTypeMenu'
import { EMediaType } from '@coreprj/types/enum'
import { createExploreUri } from '../../helpers'

const imageSource = process.env.PUBLIC_URL + '/images/bg-home.png'

interface IProps {
  defaultValue?: string | null
}

export const SearchInput: FC<IProps> = (props) => {
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState(props.defaultValue ?? '')
  const [mediaType, setMediaType] = useState(EMediaType.Video)

  const getData = (status: EMediaType) => {
    if (status === EMediaType.Audio) return SupportDisplayData.SearchBoxAudioData
    return SupportDisplayData.SearchBoxVideoData
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault()
    const explorePath = createExploreUri({ searchKey: inputValue, mediaType })
    navigate(explorePath)
  }

  const handleMenuChange = (status: EMediaType) => {
    setMediaType(status)
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const value = event.target.value
    setInputValue(value)
  }

  const data = getData(mediaType)
  return (
    <CustomBgImage>
      <Container>
        <Title>{data.title}</Title>
        <SubTitle sx={{ width: { xs: '100%', sm: '65%', md: '45%' } }}>{data.subTitle}</SubTitle>
        <Box height='10px' />
        <CustomWrapper onSubmit={onSubmit} sx={{ maxWidth: { xs: '85vw', sm: '75vw', md: '50vw' } }}>
          <IconButton sx={{ p: '10px' }} aria-label='menu' type='submit'>
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder='Keywords'
            inputProps={{ 'aria-label': 'Keywords' }}
            defaultValue={inputValue}
            onChange={handleInputChange}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
          <MediaTypeMenu status={mediaType} onChange={handleMenuChange} />
        </CustomWrapper>
      </Container>
      <Box className='box-dark' />
    </CustomBgImage>
  )
}
export default SearchInput

const Container = styled('div')({
  textAlign: 'center',
  height: `calc(50vh - ${AppConstant.headerHeight})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '5px',
  position: 'relative',
  zIndex: '1'
})

const Title = styled('h3')({
  fontSize: '36px',
  fontWeight: 700,
  color: '#fff'
})

const SubTitle = styled('p')({
  fontSize: '16px',
  fontWeight: 500,
  margin: '15px auto 0',
  color: '#fff',
  height: '38px'
})

const CustomWrapper = styled('form')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
  borderRadius: '28px',
  padding: '4px 16px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 14px rgba(0,0,0,0.23)'
})

const CustomBgImage = styled('div')({
  backgroundImage: `url(${imageSource})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  '& .box-dark': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.45)'
  }
})
