import { Box, Container, Grid } from '@mui/material'
import { Component } from 'react'
import { Outlet } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'

interface IProps {}

export default class ProfileLayout extends Component<IProps> {
  render() {
    return (
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12} md={2}>
            <ProfileMenu />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ minHeight: 'calc(100vh - var(--height-header))' }}>
              <Outlet />
              <Box sx={{ height: '25vh' }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  }
}
