import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppBar, Box, Container, Divider, Fade, LinearProgress, Toolbar, styled } from '@mui/material'
import { NavigateKey } from '../../../Route'
import { RootState } from '../../../Redux/type'
import { CartBadgeMapRedux } from '../../Cart/redux/Map'
import { SearchInputMapRedux } from '../../Explore/redux/Map'
import { LoginMenu } from '@coreprj/auth-oidc-ui/LoginMenu'
import { LoadingAppState } from '../../../Redux/Reducers/Slices/AppStatusSlice'
import { AvatarButton } from '@coreprj/auth-oidc-ui/Components/AvatarButton'
import HeaderNav from './HeaderNav'
import LogoBase64 from './LogoBase64'
import { ELazyStatus } from '../../../Models/enums'
import { NotificationMapRedux } from '../../Notification/Redux/Container'

export const HeaderHeight = '96px'

interface IAppHeaderProps extends LoadingAppState {
  fixed?: boolean
  isSearch?: boolean
}

export const AppHeader: FC<IAppHeaderProps> = (props) => {
  const { fixed, isSearch } = props
  return (
    <>
      {fixed && <Box sx={{ height: HeaderHeight }} />}
      <CustomAppBar sx={{ position: fixed ? 'fixed' : 'static' }}>
        <Box sx={{ padding: { xs: '0', md: '0 35px' }, display: 'flex', alignItems: 'center', height: HeaderHeight }}>
          <Container>
            <Toolbar disableGutters sx={{ gap: '15px' }}>
              <LogoWrapper to={NavigateKey.Home}>
                <Box component='img' src={LogoBase64} sx={{ height: '48px' }} />
                <Logo sx={{ fontSize: { sm: '28px', xs: '20px' } }}>BERLINTOMEK</Logo>
              </LogoWrapper>
              {isSearch && (
                <>
                  <Box width='20px' />
                  <SearchInputMapRedux />
                </>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <HeaderNav />
              <Divider orientation='vertical' sx={{ height: '28px', display: { xs: 'none', md: 'block' } }} />
              <NotificationMapRedux />
              <CartBadgeMapRedux />
              <Divider orientation='vertical' sx={{ height: '28px' }} />
              <LoginMenu
                AvatarButton={(p) => {
                  const { profilePath, ...other } = p
                  return <AvatarButton {...other} profilePath={NavigateKey.Profile} />
                }}
              />
            </Toolbar>
          </Container>
        </Box>
        <Fade in={props.Status === ELazyStatus.Loading}>
          <Box sx={{ width: '100%', marginTop: '-4px' }}>
            <LinearProgress />
          </Box>
        </Fade>
      </CustomAppBar>
    </>
  )
}

const mapStateToProps = (state: RootState): LoadingAppState => ({
  Status: state.AppStatusSlice.Status
})

export default connect(mapStateToProps)(AppHeader)

const CustomAppBar = styled(AppBar)({
  backgroundColor: '#FFFFFF',
  boxShadow: '0 2px 14px rgba(0,0,0,0.23)',
  color: '#0f0f0f'
})

const LogoWrapper = styled(Link)({
  textDecoration: 'none!important',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  grap: '6px'
})

const Logo = styled('h2')({
  fontSize: '36px',
  fontWeight: 500,
  color: '#0f0f0f'
})
