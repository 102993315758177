import { NavigateKey } from '../../../Route'
import { EMediaType } from '../../../Models/enums'
import { createExploreUri } from '../../Explore/helpers'
import { putFilterExplore } from '../../Explore/redux/Map'

export interface IFilterDTO {
  mediaType?: EMediaType
}

interface INavigateConfigs {
  title: string
  items: { name: string; key: string; onClick?: () => void }[]
}

export const getNavigateConfigs = (): INavigateConfigs[] => [
  {
    title: 'Our product',
    items: [
      {
        name: 'Videos',
        key: createExploreUri({ mediaType: EMediaType.Video }),
        onClick: () => putFilterExplore('mediaType', EMediaType.Video),
      },
      {
        name: 'Audios',
        key: createExploreUri({ mediaType: EMediaType.Audio }),
        onClick: () => putFilterExplore('mediaType', EMediaType.Audio),
      },
    ],
  },
  {
    title: 'Support',
    items: [
      { name: 'FQAs', key: NavigateKey.FQAs },
      { name: 'Pricing', key: NavigateKey.Pricing },
      { name: 'Submit Media', key: NavigateKey.SubmitMedia },
      { name: 'Privacy Policy', key: NavigateKey.Policy },
    ],
  },
]
