import { IRequestParamResult, RequestParam } from '@coreprj/graphql/Requests/RequestParam'
import Axios, { AxiosRequestHeaders } from 'axios'
import Graphql from '@coreprj/graphql/base'
import { ELogic, EOperator, EOrder, IModelFilter, PropFilter } from '@coreprj/shareds/Tables/ModelFilter'
import { IFetchModel } from '@coreprj/shareds/Tables/TemplateTable'

export const HttpService = Axios.create({
  baseURL: ''
})

export const CreateHttpService = (baseURL: string, headers?: AxiosRequestHeaders) => {
  return Axios.create({
    baseURL,
    headers
  })
}

// TODO chuyển sang helper
// ========= ========= ========= Convert Table To GraphQL Request Params ========= ========= =========
interface IMapOperatorFunctionFilterParams<TGraphQlModel extends Object> {
  operator: EOperator
  requestParam: RequestParam<TGraphQlModel>
  fieldName: keyof TGraphQlModel
  value: string
}

const mapOperatorFunctionFilter = <TGraphQlModel extends Object>(params: IMapOperatorFunctionFilterParams<TGraphQlModel>) => {
  switch (params.operator) {
    case EOperator.Contains:
      return params.requestParam.filterContains(params.fieldName, params.value)
    case EOperator.GreaterThan:
    case EOperator.GreaterThanOrEqual:
      return params.requestParam.filterGreater(params.fieldName, params.value)
    default:
      return params.requestParam.filter(params.fieldName, params.value)
  }
}

export interface IConvertGraphQLFilterOptions<TModel, TGraphQlModel extends Object> {
  mapQuickFilters: { [key in keyof TModel]: keyof TGraphQlModel }
  quickFilters: (keyof TModel)[]
}

export const ConvertTableToRequestGraphQLFilter = <TModel, TGraphQlModel extends Object>(
  model: Partial<IFetchModel>,
  options: IConvertGraphQLFilterOptions<TModel, TGraphQlModel>
): IRequestParamResult<TGraphQlModel> => {
  const pSize = model.PageInfo?.pageSize ?? 25
  const nSkip = pSize * (model.PageInfo?.page ?? 0)
  let requestParam = Graphql.createRequestBuilder<TGraphQlModel>({ ignoreEmpty: true }).take(pSize).skip(nSkip)

  if (model.FilterModel?.items && (model.FilterModel?.quickFilterValues?.length ?? 0) < 1) {
    const listFilter = model.FilterModel?.items?.filter((x) => !!x.value)
    if (listFilter.length === 1) {
      requestParam = requestParam.skip(0)
      requestParam = mapOperatorFunctionFilter({
        fieldName: options.mapQuickFilters[listFilter[0].field as keyof TModel],
        value: listFilter[0].value,
        operator: parseInt(listFilter[0].operator),
        requestParam: requestParam
      })
    } else {
      requestParam = requestParam.skip(0).scope(
        (rParams) => {
          listFilter.map((x) => {
            rParams = mapOperatorFunctionFilter({
              fieldName: options.mapQuickFilters[x.field as keyof TModel],
              value: x.value,
              operator: parseInt(x.operator),
              requestParam: rParams
            })
          })
          return rParams
        },
        { logic: 'And' }
      )
    }
  }
  if ((model.FilterModel?.quickFilterValues?.length ?? 0) > 0 && options.quickFilters.length > 0) {
    const text = model.FilterModel?.quickFilterValues?.join(' ').trim()
    if (text) {
      requestParam = requestParam.skip(0).scope(
        (rParams) => {
          options.quickFilters.map((x) => {
            rParams = rParams.filterContains(options.mapQuickFilters[x], text)
          })
          return rParams
        },
        { logic: 'And' }
      )
    }
  }

  if (model.GridSortModel) {
    model.GridSortModel.map((x) => {
      if (options.mapQuickFilters[x.field as keyof TModel]) {
        requestParam = requestParam.sort(options.mapQuickFilters[x.field as keyof TModel], {
          direction: x.sort === 'asc' ? 'ASC' : 'DESC'
        })
      }
    })
  }

  return requestParam.build()
}

// ========= ========= ========= Convert Table To API Request Params ========= ========= =========
export const ConvertTableToRequestFilter = <TModel>(
  model: Partial<IFetchModel>,
  quickFilters: (keyof TModel)[] = []
): Partial<IModelFilter<TModel>> => {
  const filters: Partial<IModelFilter<TModel>> = {
    Amount: model.PageInfo?.pageSize ?? 25,
    Page: model.PageInfo?.page ?? 0
  }

  if (model.FilterModel?.items) {
    filters.PropFilters = model.FilterModel?.items
      ?.filter((x) => !!x.value)
      .map<PropFilter<TModel>>((x) => ({
        FieldName: x.field as any,
        Value: x.value,
        Operator: parseInt(x.operator),
        Logic: model.FilterModel?.logicOperator === 'and' ? ELogic.And : ELogic.Or
      }))
    filters.Page = 0
  }

  if (model.FilterModel?.quickFilterValues && quickFilters.length) {
    if (!filters.PropFilters) {
      filters.PropFilters = []
    }

    const text = model.FilterModel?.quickFilterValues?.join(' ').trim()

    text &&
      filters.PropFilters.push({
        Logic: ELogic.And,
        Scopes: quickFilters.map((x) => {
          return {
            FieldName: x,
            Value: text,
            Logic: ELogic.Or,
            Operator: EOperator.Contains
          }
        })
      })
    filters.Page = 0
  }

  if (model.GridSortModel) {
    filters.PropOrders = model.GridSortModel.map((x) => ({
      FieldName: x.field as any,
      Type: x.sort === 'asc' ? EOrder.Ascending : EOrder.Descending
    }))
  }

  return filters
}
