import React, { Component } from 'react'
import { INotificationProps } from './Redux/type'
import NotifyIcon from './NotifyIcon'

interface IProps extends INotificationProps {}

export default class ViewBase extends Component<IProps> {
  render() {
    return <NotifyIcon {...this.props} />
  }
}
