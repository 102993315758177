import React, { Component } from 'react'
import { styled } from '@mui/material'
import Slider, { Settings } from 'react-slick'
import { IGallery } from '@coreprj/types/models'

const ControlArrow = styled('div')({
  display: 'block',
  '&::before': {
    color: '#9c9c9c'
  }
})

const SliderSettings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 3,
  arrows: true,
  nextArrow: <ControlArrow />,
  prevArrow: <ControlArrow />,
  responsive: [
    { breakpoint: 1025, settings: { slidesToShow: 4, slidesToScroll: 2 } },
    { breakpoint: 769, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
  ]
}

interface IProps {
  data: IGallery[]
  renderItem: (value: IGallery, index: number) => JSX.Element
}

export default class Carousel extends Component<IProps> {
  render() {
    return <CustomSlider {...SliderSettings}>{this.props.data.map(this.props.renderItem)}</CustomSlider>
  }
}

const CustomSlider = styled(Slider)({
  marginBottom: '35px',
  marginTop: '6px'
  // '& .slick-next': {
  //   display: 'block',
  //   right: '50px',
  //   top: 'calc(100% + 15px)',
  //   zIndex: 5,
  // },
  // '& .slick-prev': {
  //   display: 'block',
  //   left: '50px',
  //   top: 'calc(100% + 15px)',
  //   zIndex: 5,
  // },
})
