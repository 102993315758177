import React, { Component } from 'react'
import { Stack, Divider, Container, Box, Grid, styled } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { IPricingConfigDTO } from '../../type'
import PricingItem from './Item'
import { PricingItemsSkeleton } from '../../../../Components/Skeletons'
import { Link } from 'react-router-dom'
import { NavigateKey } from '../../../../Route'

interface IProps {
  data: IPricingConfigDTO
}

class PricingSection extends Component<IProps> {
  render() {
    const { info } = this.props.data
    return (
      <Box>
        <Divider />
        <Container>
          <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
            <Box sx={{ borderRight: '1px solid rgba(0,0,0,0.12)', flexGrow: 1, py: '20px', minHeight: '150px' }}>
              <Title>{info.name ?? 'Package Title'}</Title>
              <Text sx={{ mt: '6px' }}>{info.description ?? 'Package Description'}</Text>
            </Box>
            <Stack minWidth="200px" p="20px" gap="5px" justifyContent="flex-end" alignItems="flex-start">
              <Text sx={{ fontWeight: 700 }}>Read more</Text>
              <DescriptionLine id="btn-pp" {...{ component: Link, to: NavigateKey.Policy }}>
                <Box component="span"> Privacy Policy</Box>
                <ArrowRightAltIcon />
              </DescriptionLine>
            </Stack>
          </Box>
        </Container>
        <Divider />
        {this.renderContent()}
      </Box>
    )
  }

  renderContent = () => {
    const { data } = this.props.data
    if (data.length < 1) return <PricingItemsSkeleton />
    return (
      <Container sx={{ py: '54px' }}>
        <Grid container spacing={4}>
          {data.map((item, index) => (
            <Grid item key={index} xs={12} md={4}>
              <PricingItem data={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  }
}

export default PricingSection

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '36px',
  fontWeight: 700,
})

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060',
})

const DescriptionLine = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '5px 0 0',
  cursor: 'pointer',
  borderBottom: '1px solid #1976D2',
  color: '#1976D2',
  textDecoration: 'unset !important',
  '& span': {
    fontFamily: "'Exo 2', sans-serif",
    fontSize: '16px',
    textTransform: 'capitalize',
    fontWeight: '500',
  },
})
