import { ICategory, IMedia } from '../../../Models'
import { ICategoryDTO, IMediaDTO } from '../type'

class DataMappingBase {
  media = (item: IMedia): IMediaDTO => ({ ...item })
  medias = (items: IMedia[]): IMediaDTO[] => {
    return items.map(this.media)
  }

  category = (item: ICategory): ICategoryDTO => ({ ...item })
  categories = (items: ICategory[]): ICategoryDTO[] => {
    return items.map(this.category)
  }
}

const DataMapping = new DataMappingBase()
export default DataMapping
