import { ModelType } from 'graphql-ts-client-api'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { applicationUser$ } from '../../__generated/media-store/fetchers'
import { order$$, orderPage$ } from '../../__generated/media-store/fetchers'

const OrderSelector = order$$.user(applicationUser$.displayName)
export type TOrder = ModelType<typeof OrderSelector>
export type TOrdersFilterParams = Parameters<typeof mediaStoreGraphDbContext$.orders>[0]
export const OrdersQuery = (pamras: TOrdersFilterParams) => {
  const OrderPage = orderPage$.items(OrderSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.orders(pamras, OrderPage))
}
