import React, { Component } from 'react'
import { Box, styled } from '@mui/material'
import { IJsonContent, IPricePackageDTO } from '../../type'
import { AddToCartButtonMapRedux } from '../../../Cart/redux/Map'
import Helpers from '../../helpers'
import { EPriceType } from '@coreprj/types/enum'

interface IProps {
  data: IPricePackageDTO
}

export default class PricingItem extends Component<IProps> {
  jsonContent: IJsonContent
  constructor(props: IProps) {
    super(props)
    this.jsonContent = Helpers.getServicesByCart(props.data)
  }

  render() {
    return (
      <Box>
        <Wrap sx={{ borderBottom: `8px solid ${this.jsonContent.color}` }}>
          <LicenseText>{this.props.data.Name}</LicenseText>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '9px' }}>
            <PriceText>$ {this.props.data.Price}</PriceText>
            <Text sx={{ fontSize: '16px', marginBottom: '9px' }}>/per clip</Text>
          </Box>
          <Text sx={{ marginTop: '12px' }}>{this.props.data.Description}</Text>
        </Wrap>
        {this.renderServices()}
      </Box>
    )
  }

  renderControls = (item: IPricePackageDTO) => {
    if (item.Type !== EPriceType.Pack) return
    return (
      <>
        <Box height='20px' />
        {<AddToCartButtonMapRedux pricePackage={item} />}
      </>
    )
  }

  renderServices = () => {
    const list = this.jsonContent.services ?? []
    return (
      <Ul>
        {list.map((item, index) => (
          <Box key={index} component='li'>
            <Text sx={{ fontWeight: item.bold ? 600 : 400 }}>{item.text}</Text>
          </Box>
        ))}
      </Ul>
    )
  }
}

const Wrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '275px',
  gap: '5px',
  padding: '50px 36px',
  borderRadius: '6px',
  backgroundColor: '#fdfdfd',
  border: '1px solid rgb(216, 216, 216)',
  transition: '0.3s'
})

const Ul = styled('ul')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  marginTop: '18px',
  '& li': {
    display: 'flex',
    alignItems: 'center',
    gap: '9px',
    borderRadius: '6px',
    height: '56px',
    padding: '0 36px',
    backgroundColor: '#f4f4f4',
    boxShadow: '0 2px 14px rgba(0,0,0,0.05)'
  }
})

const LicenseText = styled('h5')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '22px',
  fontWeight: 500
})

const PriceText = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '48px',
  fontWeight: 700
})

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060'
})
