import { Link } from 'react-router-dom'
import QueryParam from '../../../utilities/QueryParam'
import { NavigateKey } from '../../../Route/NavigateKey'
import { IMediaFilter } from '@coreprj/types/client'
import { ICategory, IGalleryDetail, IMedia } from '@coreprj/types/models'
import { ECategoryType, EMediaStatus, EMediaType } from '@coreprj/types/enum'

export const createExploreUri = (value: IMediaFilter) => {
  const filterString = QueryParam.stringify(value)
  return `${NavigateKey.Explore}${filterString}`
}

export const mapLink = (uri: string, disabledBlank?: boolean) => ({
  component: Link,
  to: uri,
  target: disabledBlank ? '_self' : '_blank'
})

export const mapLinkExplore = (value: IMediaFilter, isBlank?: boolean) => {
  const uri = createExploreUri(value)
  return { component: Link, to: uri, target: isBlank ? '_blank' : '_self' }
}

export const createImageUri = (value: string) => `${process.env.PUBLIC_URL}${value}`

export const mapGalleryDetailToMedia = (value: IGalleryDetail): IMedia => ({
  Id: value.MediaId,
  Name: value.MediaName,
  Duration: value.MediaDuration,
  Size: 0,
  Type: value.Type as EMediaType,
  ResourceId: value.ResourceId,
  UserCreatedId: '',
  Description: '',
  Extension: '',
  CategorieNames: [],
  Status: EMediaStatus.Pending,
  Reason: '',
  DateCreated: ''
})

export type TCategoryByType = { [key in ECategoryType]: ICategory[] }
export const filterCategoriesByType = (items: ICategory[]): TCategoryByType => ({
  [ECategoryType.Video]: items.filter((x) => x.Type === ECategoryType.Video),
  [ECategoryType.Audio]: items.filter((x) => x.Type === ECategoryType.Audio)
})
