import React, { Component } from 'react'
import { motion } from 'framer-motion'
import { Box, Typography, styled } from '@mui/material'
import { IInputControl } from '@coreprj/shareds/Forms/ValidateForm/types'
import CopyBox from '@coreprj/shareds/CopyToClipboard/CoppyBox'

export class FieldSocialUrl extends Component<IInputControl> {
  render() {
    const list = this.parseData(this.props.defaultValue)
    return (
      <Box component='ul'>
        {list.map((item, index) => {
          return (
            <Box key={index} component='li'>
              <MotionItem
                sx={{ borderRadius: '6px' }}
                initial={{ opacity: 0.3, backgroundColor: '#dedede' }}
                animate={{ opacity: 1, backgroundColor: '#fafafa' }}
                transition={{ ease: 'linear', duration: 1 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className='noselect'>{index + 1}.</Typography>
                  <CustomText {...{ component: 'a', href: item, target: '_blank' }} sx={{ flex: 1 }} noWrap>
                    {item}
                  </CustomText>
                  <CopyBox content={item} />
                </Box>
              </MotionItem>
            </Box>
          )
        })}
      </Box>
    )
  }

  parseData = (value?: any): string[] => {
    try {
      if (Array.isArray(value)) return value
      return JSON.parse(value ?? '[]')
    } catch (error) {
      return []
    }
  }
}

const MotionItem = styled(motion.div)({
  '& > div': {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginTop: '4px',
    padding: '3px 9px'
  }
})

const CustomText = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  color: '#606060',
  flex: 1,
  textDecoration: 'unset !important',
  '&:hover': {
    color: '#1976d2'
  }
})

export const parseNameResourceInfo = (value?: string): string => {
  try {
    return JSON.parse(value ?? '{}')['Name'] ?? ''
  } catch (error) {
    return ''
  }
}
