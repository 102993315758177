import { FC } from 'react'
import { styled } from '@mui/material'
import { IMediaDTO } from '../../Models'
import HeightEqualWidth from '../HeightEqualWidth'

interface IProps {
  data: IMediaDTO
}

export const VideoPlayer: FC<IProps> = (props) => {
  const { ResourceData = [] } = props.data
  let steamUrl = ''
  if (ResourceData.length > 0 && ResourceData[0].StreamUrl) steamUrl = ResourceData[0].StreamUrl
  return (
    <HeightEqualWidth ratio={720 / 1280} sx={{ width: '100%' }}>
      {steamUrl && <CustomVideo src={steamUrl} controls={true} sx={{ boxShadow: 2 }} />}
    </HeightEqualWidth>
  )
}

const CustomVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  backgroundColor: '#979797',
})

export default VideoPlayer
