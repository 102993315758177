import { FC, useEffect, useState } from 'react'
import { IconButton, SxProps, Theme, TooltipProps } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CopyToClipboard from '@coreprj/shareds/CopyToClipboard'

interface IProps {
  content?: string
  sxIconProps?: SxProps<Theme>
  TooltipProps?: Partial<TooltipProps>
}

const CopyBox: FC<IProps> = (props) => {
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setIsCopied(false)
    }, 2000)

    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [isCopied])

  const Icon = isCopied ? DoneIcon : ContentCopyIcon
  return (
    <CopyToClipboard TooltipProps={{ placement: 'left', ...props.TooltipProps }}>
      {({ copy }) => (
        <IconButton
          onClick={() => {
            const { content = '' } = props
            setIsCopied(true)
            copy(content)
          }}
        >
          <Icon fontSize='small' sx={{ color: '#606060', ...props.sxIconProps }} />
        </IconButton>
      )}
    </CopyToClipboard>
  )
}

export default CopyBox
