import './index.css'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { AlertGlobal } from '@coreprj/shareds/AlertGlobal'
import ReactDOM from 'react-dom/client'
import AuthLoading from '@coreprj/auth-oidc-ui/AuthLoading'
import { AppRouter } from './Route'
import { Routers } from './Route/Config'
import store from './Redux'
import reportWebVitals from './reportWebVitals'
import Error404 from './Views/Shareds/Error404'

import './polyfill'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

let baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
baseUrl = baseUrl?.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl ?? ''}>
      <AuthLoading>
        <AppRouter Routers={Routers} errorElement={<Error404 />} />
      </AuthLoading>
    </BrowserRouter>
    <AlertGlobal />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
