import { fetchPricingThunk } from './Thunks'
import { hocConnect } from '../../../Redux/hocs/hocConnect'
import { AppDispatch, RootState } from '../../../Redux/type'
import { IPricingDispatchRedux, IPricingStateRedux } from '../type'
import ViewBase from '../ViewBase'

const mapStateToProps = (state: RootState): IPricingStateRedux => ({
  Status: state.PricingSlice.Status,
  PricingSlice: state.PricingSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): IPricingDispatchRedux => {
  return {
    fetchInitial: () => {
      dispatch(fetchPricingThunk())
      return { abort() {} }
    },
  }
}

export const PricingMapRedux = hocConnect(ViewBase, mapStateToProps, mapDispatchToProps)
