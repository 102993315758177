import { ModelType } from 'graphql-ts-client-api'
import { createRequestBuilder } from '../../Requests/RequestParam'
import { isGuid } from '../../Requests/helper'
import { query$, mediaStoreGraphDbContext$, authenticationGraphDbContext$ } from '../../__generated/media-store/fetchers'
import { category$$, categoryPage$ } from '../../__generated/media-store/fetchers'
import { applicationUser$, applicationUserPage$ } from '../../__generated/media-store/fetchers'

const SelectUserSelector = applicationUser$.id.displayName.userName
export type TSelectUser = ModelType<typeof SelectUserSelector>
type TSelectUserInside = ModelType<typeof applicationUser$.id.displayName.userName.email>
export const SelectUsers = (keyword = '') => {
  let query = createRequestBuilder<TSelectUserInside>({ ignoreEmpty: true }).take(50)

  if (isGuid(keyword)) query = query.filter('id', keyword)
  else query = query.filterContains('email', keyword).filterContains('userName', keyword)

  return query$.auth(authenticationGraphDbContext$.users(query.build(), applicationUserPage$.items(SelectUserSelector)))
}

const SelectCategorySelector = category$$
export type TSelectCategory = ModelType<typeof SelectCategorySelector>
export const SelectCategorys = (keyword = '') => {
  let query = createRequestBuilder<TSelectCategory>({ ignoreEmpty: true }).take(50)

  if (isGuid(keyword)) query = query.filter('id', keyword)
  // else query = query.filterContains('email', keyword).filterContains('userName', keyword)

  return query$.mediaStore(mediaStoreGraphDbContext$.categories(query.build(), categoryPage$.items(SelectCategorySelector)))
}
