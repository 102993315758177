import { FC } from 'react'
import { Link } from 'react-router-dom'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Box, Container, Divider, Stack, SvgIconTypeMap, Grid, styled, Typography } from '@mui/material'
import RedditIcon from '@mui/icons-material/Reddit'
import TwitterIcon from '@mui/icons-material/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'
import LanguageIcon from '@mui/icons-material/Language'
import PinterestIcon from '@mui/icons-material/Pinterest'
import InstagramIcon from '@mui/icons-material/Instagram'
import { getNavigateConfigs } from './Configs'

interface IIcon {
  link: string
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
}
const icons: IIcon[] = [
  { link: 'http://google.com', icon: YouTubeIcon },
  { link: 'http://google.com', icon: InstagramIcon },
  { link: 'http://google.com', icon: TwitterIcon },
  { link: 'http://google.com', icon: PinterestIcon },
  { link: 'http://google.com', icon: RedditIcon },
]

interface IProps {}

const AppFooter: FC<IProps> = () => {
  const configs = getNavigateConfigs()
  return (
    <Box>
      <Divider />
      <Container sx={{ py: '108px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Stack gap="25px" justifyContent="flex-start">
              <Stack direction="row">
                {icons.map(({ icon: Icon, link }, i) => (
                  <SocialIcon key={i} href={link} target="_blank">
                    <Icon sx={{ color: '#606060' }} />
                  </SocialIcon>
                ))}
              </Stack>
              <Stack direction="row" gap="5px" alignItems="center">
                <LanguageIcon sx={{ color: '#606060' }} />
                <Text>English</Text>
              </Stack>
              <Box flex="1" minHeight="30px" />
              <CopyrightText sx={{ display: { xs: 'none', md: 'block' } }}>© 2024, BERLINTOMEK Inc.</CopyrightText>
            </Stack>
          </Grid>
          {configs.map((config) => (
            <Grid key={config.title} item xs={6} sm={3} md={2}>
              <Stack gap="10px" alignItems="flex-start">
                <Title>{config.title}</Title>
                <Box height="5px" />
                {config.items.map((item, index) => (
                  <TextHover key={index} {...{ component: Link, to: item.key, onClick: item.onClick }}>
                    {item.name}
                  </TextHover>
                ))}
              </Stack>
            </Grid>
          ))}
          <Grid item xs={12} md={4}>
            <Stack gap="10px" sx={{ mt: { xs: '20px', sm: 0 } }}>
              <Title>About us</Title>
              <Box height="5px" />
              <Text>BERLINTOMEK is a company specializing in the sale and rental of videos and audios</Text>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mt: '25px', display: { xs: 'block', md: 'none' } }}>
            <CopyrightText>© 2024, BERLINTOMEK Inc.</CopyrightText>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default AppFooter

const CopyrightText = styled('p')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 400,
  color: '#606060',
})

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: 700,
  textTransform: 'uppercase',
  color: '#606060',
})

const Text = styled(Typography)({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: 500,
  color: '#0f0f0f',
})

const TextHover = styled(Text)({
  cursor: 'pointer',
  transition: 'linear 0.2s',
  color: '#606060',
  padding: '0 5px',
  borderBottom: '1px solid transparent',
  textDecoration: 'unset !important',
  '&:hover': {
    color: '#1976D2',
    padding: '0 7.5px',
    borderBottom: '1px solid #1976D2',
  },
})

const SocialIcon = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  padding: '5px 10px',
  '& svg': {
    transition: 'all linear 0.2s',
    color: '#606060',
  },
  '&:hover svg': {
    color: '#1976D2',
  },
})
