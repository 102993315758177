import React, { Component } from 'react'
import { Box, styled } from '@mui/material'
import Slider, { Settings } from 'react-slick'
import { createImageUri, mapLinkExplore } from '../../helpers'
import { CategoriesSkeletonV2 } from '../../../../Components/Skeletons'
import { ICategory } from '@coreprj/types/models'
import { EMediaType } from '@coreprj/types/enum'

const ControlArrow = styled('div')({
  display: 'block',
  '&::before': { color: '#9c9c9c' }
})

const SliderSettings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 2,
  arrows: true,
  nextArrow: <ControlArrow />,
  prevArrow: <ControlArrow />,
  responsive: [
    { breakpoint: 1025, settings: { slidesToShow: 4, slidesToScroll: 2 } },
    { breakpoint: 769, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
  ]
}

interface IProps {
  data: ICategory[]
}

export default class Carousel extends Component<IProps> {
  render() {
    if (this.props.data.length <= 0) return <CategoriesSkeletonV2 />
    return <CustomSlider {...SliderSettings}>{this.props.data.map(this.renderItem)}</CustomSlider>
  }

  renderItem = (item: ICategory, index: number) => (
    <Box key={index} sx={{ padding: '7.5px' }}>
      <Item {...mapLinkExplore({ categories: item.Name, mediaType: item.Type.toString() as EMediaType })}>
        <CustomImage>
          <Box sx={{ backgroundImage: `url(${createImageUri(item.ImageUri)})` }} />
        </CustomImage>
        <Text>{item.Name}</Text>
      </Item>
    </Box>
  )
}

const CustomSlider = styled(Slider)({
  margin: '0 -7.5px 35px',
  '& .slick-next': {
    display: 'block',
    right: '50px',
    top: 'calc(100% + 15px)',
    zIndex: 5
  },
  '& .slick-prev': {
    display: 'block',
    left: '50px',
    top: 'calc(100% + 15px)',
    zIndex: 5
  }
})

const CustomImage = styled(Box)({
  width: '100%',
  paddingBottom: '100%',
  borderRadius: '4px',
  boxShadow: '0 2px 15px rgba(0,0,0,0.1)',
  overflow: 'hidden',
  position: 'relative',
  '& > div': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%) scale(1)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'all .3s'
  },
  '&:hover > div': { transform: 'translate(-50%, -50%) scale(1.1)' }
})

const Text = styled('div')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: '500',
  color: '#606060',
  textAlign: 'center'
})

const Item = styled(Box)({
  textDecoration: 'unset !important'
})
