import React, { Component } from 'react'
import { Box } from '@mui/material'
import { INotification } from '@coreprj/types/models'
import GlobalModal, { IGlobalModalContext, mapGlobalModalContext } from '@coreprj/shareds/GlobalModal'
import { INotificationProps } from '../Redux/type'
import GlobalPopover, { IGlobalPopoverContext, mapGlobalPopoverContext } from './GlobalPopover'
import Content from './Content'
import BadgeIcon from './BadgeIcon'
import NotifyDetail from './NotifyDetail'

interface IProps extends INotificationProps {
  onRead?: (value: INotification) => void
}

export default class NotifyIcon extends Component<IProps> {
  render() {
    return (
      <GlobalModal>
        <GlobalPopover>
          {mapGlobalPopoverContext((context) => (
            <Box>
              <BadgeIcon count={this.getUnreadCount()} onClick={(target) => this.handleCickOpenPopup(target, context)} />
            </Box>
          ))}
        </GlobalPopover>
        {/* <NotifyPopupMapRedux /> */}
      </GlobalModal>
    )
  }

  handleCickOpenPopup = (target: HTMLElement, context: IGlobalPopoverContext) => {
    const data = this.getData()
    context.showPopover({
      anchorEl: target,
      content: () => {
        return mapGlobalModalContext((c) => (
          <Content
            data={data}
            onClickItem={(value) => {
              context.closePopover()
              this.handleClickDetail(value, c)
            }}
          />
        ))
      }
    })
  }

  handleClickDetail = (value: INotification, context: IGlobalModalContext) => {
    context.ShowModal({
      sxWrap: { outline: 'none' },
      backdropActivated: true,
      ContentModal: () => (
        <NotifyDetail
          data={[value]}
          onRead={() => {
            !value.IsRead && this.props.ReadNotification({ ...value, IsRead: true })
            context.CloseModal()
          }}
          onClose={context.CloseModal}
        />
      )
    })
  }

  getData = (): INotification[] => {
    const list = this.props.tableInfo.PageInfo.data
    return list
  }

  getUnreadData = () => {
    const data = this.getData()
    return data.filter((e) => !e.IsRead)
  }

  getUnreadCount = () => {
    const data = this.getData()
    return data.reduce((a, b) => {
      if (!b.IsRead) a++
      return a
    }, 0)
  }
}
