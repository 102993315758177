import React, { Component } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { Stack, Box, Tooltip, IconButton, styled, alpha } from '@mui/material'
// import PauseIcon from '@mui/icons-material/Pause'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { FormatTiming } from '../../utilities'
import { NavigateKey } from '../../Route/NavigateKey'
import { IMediaItemProps, IProducItemProps } from './type'
import { AudioPlayEventProvider } from '../../Views/MediaDetail/ui/MediaPlayerLayout/EventProvider'

interface IProps extends IProducItemProps, IMediaItemProps {}

export default class AudioBox extends Component<IProps> {
  componentLink = () => {
    const path = generatePath(NavigateKey.Detail, {
      type: this.props.data.Type.toLocaleLowerCase(),
      id: this.props.data.Id.toString(),
    })
    return { component: Link, to: path, target: this.props.isBlank ? '_blank' : '_self' }
  }
  render() {
    return (
      <Wrapper>
        <Info {...this.componentLink()} onClick={(e) => AudioPlayEventProvider.PushEventSource(e.nativeEvent)}>
          <WraperImage color={this.props.isPlaying ? '#767676' : '#1976D2'}>
            {this.props.isPlaying ? <PlayCircleOutlineIcon fontSize="large" /> : <MusicNoteIcon fontSize="large" />}
          </WraperImage>
          <Stack sx={{ gap: '5px', pt: '5px', width: 'calc(100% - 84px)', justifyContent: 'center' }}>
            <Title>{this.props.data.Name ?? 'Exo 2/Bold/16px'}</Title>
            <TextDes>{this.props.data ? this.props.data.Description : 'Author/Exo 2/Medium/16px'}</TextDes>
          </Stack>
        </Info>
        <Stack direction="row" gap="10px" p="0 15px" flex="0 0 auto">
          {[1, 2].map((e) => {
            return <Text key={e}>Tag name</Text>
          })}
        </Stack>
        <Text>{FormatTiming(this.props.data.Duration)}</Text>
        <Box width="35px" />
        <Box sx={{ display: 'flex', gap: '6px', paddingRight: '24px' }}>
          {/* <Box className="btn-open-detail">
          <Tooltip title="Play and purchase">
            <IconButton sx={{ flex: '0 0 auto' }} {...componentLink()}>
              <PlayCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box> */}
          <Tooltip title="Detail and purchase">
            <IconButton sx={{ flex: '0 0 auto' }} {...this.componentLink()}>
              <AddShoppingCartIcon sx={{ color: '#0ED197' }} />
            </IconButton>
          </Tooltip>
          {/* <CopyBox content={props.data.Name} TooltipProps={{ placement: 'top' }} /> */}
          {/* <Tooltip title="Download">
          <IconButton sx={{ flex: '0 0 auto' }}>
            <FileDownloadIcon />
          </IconButton>
        </Tooltip> */}
        </Box>
      </Wrapper>
    )
  }
}

const Wrapper = styled(Box)({
  display: 'flex',
  gap: '10px',
  padding: '6px',
  alignItems: 'center',
  borderRadius: '4px',
  transition: 'all linear 0.2s',
  overflow: 'hidden',
  backgroundColor: '#fafafa',
  '&:hover': {
    backgroundColor: '#fdfdfd',
  },
  '& .btn-open-detail': {
    transition: 'all linear 0.2s',
    opacity: 0,
    transform: 'translateX(-50%)',
  },
  '&:hover .btn-open-detail': {
    opacity: 1,
    transform: 'translateX(0)',
  },
})

const Info = styled(Box)({
  display: 'flex',
  flex: 1,
  gap: '10px',
  cursor: 'pointer',
  width: '350px',
  textDecoration: 'unset !important',
  color: '#202020',
})

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 700,
  padding: '5px 0',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060',
  overflow: 'hidden',
})

const TextDes = styled(Text)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const WraperImage = styled(Box)<{ color?: string }>(({ color }) => ({
  backgroundColor: alpha(color ?? '#1976D2', 0.15),
  flex: '0 0 auto',
  width: '84px',
  height: '84px',
  margin: '-6px 0 -6px -6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
