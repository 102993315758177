import { ModelType } from 'graphql-ts-client-api'
import { query$, notificationGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { userExtend$$ } from '../../__generated/media-store/fetchers'
import { notificationUser$$ } from '../../__generated/media-store/fetchers'
import { notificationStatus$$ } from '../../__generated/media-store/fetchers'
import { notificationInfo$$, notificationInfoPage$ } from '../../__generated/media-store/fetchers'

const NotificationUserSelector = notificationUser$$.user(userExtend$$)
export type TNotificationUser = ModelType<typeof NotificationUserSelector>

const NotificationStatusSelector = notificationStatus$$
export type TNotificationStatus = ModelType<typeof NotificationStatusSelector>

const NotificationInfoSelector = notificationInfo$$
  .notificationStatuses(NotificationStatusSelector)
  .notificationUsers(NotificationUserSelector)
export type TNotificationInfo = ModelType<typeof NotificationInfoSelector>
export type TNotificationInfosFilterParams = Parameters<typeof notificationGraphDbContext$.notificationInfos>[0]
export const NotificationInfosQuery = (pamras: TNotificationInfosFilterParams) => {
  const NotificationInfoPage = notificationInfoPage$.items(NotificationInfoSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.notification(notificationGraphDbContext$.notificationInfos(pamras, NotificationInfoPage))
}

export const GuestNotificationInfosQuery = (pamras: TNotificationInfosFilterParams) => {
  const NotificationInfoPage = notificationInfoPage$.items(notificationInfo$$).hasNextPage.hasPreviousPage.totalItems
  return query$.notification(notificationGraphDbContext$.notificationInfos(pamras, NotificationInfoPage))
}
