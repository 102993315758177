import React, { Component } from 'react'
import { Box, styled } from '@mui/material'
import HeightEqualWidth from '../../../../Components/HeightEqualWidth'
import { createImageUri, mapLinkExplore } from '../../helpers'
import { IGallery } from '@coreprj/types/models'
import { EMediaType } from '@coreprj/types/enum'

interface IProps {
  data: IGallery
}

export default class PlaylistItem extends Component<IProps> {
  render() {
    const { data } = this.props
    const imageSource = createImageUri(data.ImageUri)
    return (
      <Box {...mapLinkExplore({ categories: data.Name, mediaType: EMediaType.Audio })}>
        <Wrap
          sx={{
            '&::before': {
              borderRight: { xs: '210px solid transparent', md: '250px solid transparent' },
              borderBottom: { xs: '60px solid white', md: '65px solid white' }
            }
          }}
        >
          <HeightEqualWidth>
            <CustomImage>
              <Box className='st-bg' sx={{ backgroundImage: `url(${imageSource})` }} />
            </CustomImage>
          </HeightEqualWidth>
          <Content>
            <Text>{this.props.data.Name}</Text>
          </Content>
        </Wrap>
      </Box>
    )
  }
}

const Wrap = styled('div')({
  // width: 'calc(100% - 15px)',
  margin: '12px 7.5px',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
  boxShadow: '0 2px 14px rgba(0,0,0,0.15)',
  transition: 'all linear 0.2s',
  cursor: 'pointer',
  '&::before': {
    position: 'absolute',
    display: 'block',
    content: '""',
    bottom: '-2px',
    left: 0,
    width: 0,
    zIndex: 5
  },
  '&:hover .st-bg': {
    transform: 'translate(-50%, -50%) scale(1.1)'
  }
})

const CustomImage = styled(Box)({
  flex: 1,
  borderRadius: '4px',
  boxShadow: '0 2px 15px rgba(0,0,0,0.23)',
  overflow: 'hidden',
  position: 'relative',
  '& .st-bg': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%) scale(1)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'all .3s'
  }
})

const Content = styled('div')({
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  zIndex: 6
})

const Text = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 700,
  textAlign: 'center',
  color: '#606060'
})
