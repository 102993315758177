import { IFilterDTO } from '../type'
import { ICategory, IMedia, IPricePackage } from '../../../Models'
import { EOperator, IFilter, PropFilter } from '../../../Models/filter'
import { EMediaType } from '../../../Models/enums'
import { EPriceType } from '@coreprj/types/enum'

type TMediaFilter = IFilter<IMedia>
type TCategoryFilter = IFilter<ICategory>
type TPricePackageFilter = IFilter<IPricePackage>

class FilterMappingBase {
  parseMedia = (value: IFilterDTO): TMediaFilter => {
    const filter: TMediaFilter = { Page: 0, Amount: 12 }
    let propFilters: PropFilter<IMedia>[] = [{ FieldName: 'Type', Value: value.mediaType ?? EMediaType.Video, Operator: EOperator.Equal }]
    filter.PropFilters = propFilters
    return filter
  }

  parseCategory = (value: IFilterDTO): TCategoryFilter => {
    const filter: TCategoryFilter = {}
    let propFilters: PropFilter<ICategory>[] = [{ FieldName: 'Type', Value: value.mediaType ?? EMediaType.Video, Operator: EOperator.Equal }]
    filter.PropFilters = propFilters
    return filter
  }

  parsePricePackage = (value: IFilterDTO): TPricePackageFilter => {
    const filter: TPricePackageFilter = {}
    let propFilters: PropFilter<IPricePackage>[] = [
      { FieldName: 'Type', Value: value.pricePackagetype ?? EPriceType.Media, Operator: EOperator.Equal }
    ]
    filter.PropFilters = propFilters
    return filter
  }
}

const FilterMapping = new FilterMappingBase()
export default FilterMapping
