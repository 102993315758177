import { ModelType } from 'graphql-ts-client-api'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

import { applicationUser$ } from '../../__generated/media-store/fetchers'
import { order$$, orderPage$ } from '../../__generated/media-store/fetchers'
import { orderDetail$$ } from '../../__generated/media-store/fetchers'
import { pricePackage$$, cartResourceInfo$$ } from '../../__generated/media-store/fetchers'

const OrderDetailSelector = orderDetail$$.pricePackage(pricePackage$$).cartResourceInfo(cartResourceInfo$$)
const OrderSelector = order$$.user(applicationUser$.displayName).orderDetails(OrderDetailSelector)
export type TOrderDetail = ModelType<typeof OrderSelector>
export type TOrderFilterParams = Parameters<typeof mediaStoreGraphDbContext$.order>[0]
export const OrderDetailsQuery = (pamras: TOrderFilterParams) => {
  return query$.mediaStore(mediaStoreGraphDbContext$.order(pamras, OrderSelector))
}
