import { FC } from 'react'
import { Container, Grid, Stack, styled } from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import Carousel from './Carousel'
import { ICategory } from '@coreprj/types/models'
import { ECategoryType } from '@coreprj/types/enum'
import { filterCategoriesByType } from '../../helpers'

interface IStateToProps {
  data: ICategory[]
  onClick: (value: ICategory) => void
}

interface IProps extends IStateToProps {}

export const Categories: FC<IProps> = (props) => {
  const data = filterCategoriesByType(props.data)
  return (
    <Container sx={{ marginTop: '36px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} pt='35px'>
          <Stack direction='row' gap='5px'>
            <OndemandVideoIcon fontSize='large' sx={{ mt: '-18px', color: '#606060' }} />
            <Title>Video Categories</Title>
          </Stack>
          <SubTitle>Authentic videos, extraordinary social performance</SubTitle>
        </Grid>
        <Grid item xs={12} md={8}>
          <Carousel data={data[ECategoryType.Video]} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '75px' }}>
        <Grid item xs={12} md={4} pt='35px' sx={{ display: { xs: 'block', md: 'none' } }}>
          <Stack direction='row' gap='5px' justifyContent='flex-end'>
            <Title>Music Categories</Title>
            <MusicNoteIcon fontSize='large' sx={{ mt: '-18px', color: '#606060' }} />
          </Stack>
          <SubTitle sx={{ textAlign: 'right' }}>Authentic audios, extraordinary social performance</SubTitle>
        </Grid>
        <Grid item xs={12} md={8}>
          <Carousel data={data[ECategoryType.Audio]} />
        </Grid>
        <Grid item xs={12} md={4} pt='35px' sx={{ display: { xs: 'none', md: 'block' } }}>
          <Stack direction='row' gap='5px' justifyContent='flex-end'>
            <Title>Music Categories</Title>
            <MusicNoteIcon fontSize='large' sx={{ mt: '-18px', color: '#606060' }} />
          </Stack>
          <SubTitle sx={{ textAlign: 'right' }}>Authentic audios, extraordinary social performance</SubTitle>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Categories

const Title = styled('div')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '36px',
  fontWeight: '700',
  color: '#606060'
})

const SubTitle = styled('div')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '20px',
  fontWeight: '500',
  color: '#606060'
})
