import React, { Component, ComponentType } from 'react'
import LazySpinner from './LazySpinner'
import { LazyStatus, TStateRedux, TDispatchRedux } from './types'

interface hocComponentProp<TActionParam extends Object> {
  params?: TActionParam
}

export interface OptionsHocLazy<TActionParam extends Object> {
  params?: TActionParam
}

export const CreateHocLazy = function <TComponentProp, TActionParam extends Object = any>(
  WrappedComponent: ComponentType<TComponentProp>,
  options: OptionsHocLazy<TActionParam> | null = null
) {
  type Props = hocComponentProp<TActionParam> & TComponentProp & TStateRedux & TDispatchRedux
  class HocComponent extends Component<Props> {
    renderContent = () => {
      switch (this.props.Status) {
        case LazyStatus.Loading:
        case LazyStatus.Loaded:
          return (
            <LazySpinner loading={this.props.Status === LazyStatus.Loading}>
              <WrappedComponent {...this.props} />
            </LazySpinner>
          )
        case LazyStatus.Error:
          return <div>Error...</div>
        default:
          return <div></div>
      }
    }
    initialParam = () => {
      const gParams = options?.params ?? {}
      const rParams = this.props?.params ?? {}
      return Object.assign({}, gParams, rParams)
    }

    fetchSource?: { abort: () => void }
    componentDidMount = () => {
      if (this.props.FetchData) {
        const param = this.initialParam()
        this.TokenSources?.abort()
        this.TokenSources = new AbortController()
        this.fetchSource = this.props.FetchData([param], this.TokenSources.signal)
      }
    }
    TokenSources?: AbortController
    componentWillUnmount() {
      this.TokenSources?.abort()
      this.fetchSource?.abort()
    }
    render = () => {
      return this.renderContent()
    }
  }
  return HocComponent
}

// ========= ========= ========= CreateHocLazy Unspinner ========= ========= =========
export const CreateHocLazyUnspinner = function <TComponentProp, TActionParam extends Object = any>(
  WrappedComponent: ComponentType<TComponentProp>,
  options: OptionsHocLazy<TActionParam> | null = null
) {
  type Props = hocComponentProp<TActionParam> & TComponentProp & TStateRedux & TDispatchRedux<TActionParam>
  class HocComponent extends Component<Props> {
    renderContent = () => {
      switch (this.props.Status) {
        case LazyStatus.Loading:
        case LazyStatus.Loaded:
          return <WrappedComponent {...this.props} />
        case LazyStatus.Error:
          return <div>Error...</div>
        default:
          return <div></div>
      }
    }

    initialParam = (): TActionParam => {
      const gParams = options?.params ?? {}
      const rParams = this.props?.params ?? {}
      return Object.assign({}, gParams, rParams) as TActionParam
    }

    fetchSource?: { abort: () => void }
    componentDidMount = () => {
      if (this.props.FetchData) {
        const param = this.initialParam()
        this.TokenSources?.abort()
        this.TokenSources = new AbortController()
        this.fetchSource = this.props.FetchData(param, this.TokenSources.signal)
      }
    }
    TokenSources?: AbortController
    componentWillUnmount() {
      this.TokenSources?.abort()
      this.fetchSource?.abort()
    }
    render = () => {
      return this.renderContent()
    }
  }
  return HocComponent
}
