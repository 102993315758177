import React, { FC } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { IMediaDTO } from '../type'
import { MediaItem } from '../../../Components'
import { EMediaType } from '../../../Models/enums'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Link } from 'react-router-dom'
import { NavigateKey } from '../../../Route'
import Helpers from '../helpers'

interface IProps {
  mediaPlayed?: IMediaDTO
  data: IMediaDTO[]
}

const RecommendedMedias: FC<IProps> = (props) => {
  if (props.data.length <= 0) return <></>
  return (
    <Grid container spacing={2} sx={{ backgroundColor: '#fff' }}>
      {props.data.map((item) => {
        const repon = { xs: 6, sm: 4, md: 3 }
        if (item.Type === EMediaType.Audio) repon.sm = repon.md = 12
        const isPlaying = item.Id === props.mediaPlayed?.Id
        return (
          <Grid item xs={12} sm={repon.sm} md={repon.md} key={item.Id}>
            <MediaItem data={item} isPlaying={isPlaying} />
          </Grid>
        )
      })}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '18px' }}>
          <Button
            color="primary"
            sx={{ color: '#1976D2', fontWeight: 600, width: '120px' }}
            endIcon={<KeyboardDoubleArrowRightIcon />}
            {...Helpers.mapLinkExplore({ mediaType: props.mediaPlayed?.Type ?? EMediaType.Video })}
            component={Link}
            to={NavigateKey.Explore}
          >
            More
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
export default RecommendedMedias
