import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Grid, styled } from '@mui/material'
import { NavigateKey } from '../../../Route'
import { Text14 } from '../../../Components/Text'
import { IFormCheckOutProps } from './FormCheckOut'

interface IProps extends IFormCheckOutProps {
  components: {
    content: JSX.Element
    summaryReport: JSX.Element
  }
}

export default class Layout extends Component<IProps> {
  render() {
    return (
      <Container sx={{ pt: '64px', pb: '128px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Box sx={{ minHeight: '50vh' }}>
              <Title sx={{ mb: '5px' }}>Cart</Title>
              <Text18>Please check this information is correct</Text18>
              {this.props.components.content}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', pt: '18px' }}>
              <Text14
                {...{ component: Link, to: NavigateKey.Explore }}
                sx={{ transition: 'linear 0.3s', fontWeight: 600, '&:hover': { color: '#006bd6' } }}
              >
                Continue shopping
              </Text14>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {this.props.components.summaryReport}
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const Title = styled('h1')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '32px',
  fontWeight: 700,
  color: '#606060',
})

const Text18 = styled('p')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '18px',
  fontWeight: 500,
  color: '#606060',
})
