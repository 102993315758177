/* eslint-disable @typescript-eslint/no-explicit-any */
import { connect } from 'react-redux'
import { fetchExploreThunk } from './Thunks'
import { hocConnect } from '../../../Redux/hocs/hocConnect'
import { AppDispatch, RootState } from '../../../Redux/type'
import { IOtherDispatchRedux, IOtherStateRedux } from '../type'
import { IExploreDispatchRedux, IExploreStateRedux, TPutExploreFilterFunc } from '../type'
import store from '../../../Redux'
import ViewBase from '../ViewBase'
import ExploreSlice from './Slice'
import SearchInput from '../SearchInput'
import MediaTypeTabs from '../MediaTypeTabs'

const putFilter: (dispatch: AppDispatch) => TPutExploreFilterFunc = (dispatch) => (key, value) => {
  dispatch((dis) => {
    dis(ExploreSlice.actions.updateFilter({ key, value }))
    dis(fetchExploreThunk())
  })
}

export const putFilterExplore: TPutExploreFilterFunc = (key, value) => {
  return putFilter(store.dispatch)(key, value)
}

// ========= ========= ========= View Base ========= ========= =========
const mapStateToProps = (state: RootState): IExploreStateRedux => ({
  Status: state.ExploreSlice.Status,
  ExploreSlice: state.ExploreSlice,
})
const mapDispatchToProps = (dispatch: AppDispatch): IExploreDispatchRedux => {
  return {
    fetchInitial: () => {
      dispatch(ExploreSlice.actions.fetchFromQueryParams())
      return dispatch(fetchExploreThunk())
    },
    putFilter: putFilter(dispatch),
    fetchFromQueryParams: () => dispatch(ExploreSlice.actions.fetchFromQueryParams()),
  }
}
export const ExploreMapRedux = hocConnect<any, IExploreStateRedux, IExploreDispatchRedux>(
  ViewBase,
  mapStateToProps,
  mapDispatchToProps
)

// ========= ========= ========= Search Input ========= ========= =========
const mapStateToPropsOther = (state: RootState): IOtherStateRedux => ({ ExploreSlice: state.ExploreSlice })
const mapDispatchToPropsOther = (dispatch: AppDispatch): IOtherDispatchRedux => ({
  putFilter: putFilter(dispatch),
  fetchFromQueryParams: () => dispatch(ExploreSlice.actions.fetchFromQueryParams()),
})
export const SearchInputMapRedux = connect(mapStateToPropsOther, mapDispatchToPropsOther)(SearchInput)
export const MediaTypeTabsMapRedux = connect(mapStateToPropsOther, mapDispatchToPropsOther)(MediaTypeTabs)
