import React, { Component } from 'react'
import { Box, Chip, Fade } from '@mui/material'
import Helpers from '../helpers'
import { EMediaType } from '../../../Models/enums'

interface IProps {
  data: string[]
  mediaType?: EMediaType
  onClick?: (value?: string) => void
}

export default class RecommendedCategories extends Component<IProps> {
  render() {
    return (
      <Fade in={this.props.data.length > 0}>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          {this.props.data.map((e, i) => (
            <Chip
              key={i}
              label={e}
              variant="outlined"
              onClick={() => {}}
              {...Helpers.mapLinkExplore({ categories: e, mediaType: this.props.mediaType })}
            />
          ))}
        </Box>
      </Fade>
    )
  }

  // handleClick = (value?: string) => {
  //   this.props.onClick && this.props.onClick(value)
  // }
}
