import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { buildThunkGlobal } from '../../Helper'
import { ELazyStatus } from '../../../Models/enums'
import { fetchHomeThunk } from '../../../Views/Home/redux/Thunks'
import { fetchPricingThunk } from '../../../Views/Pricing/redux/Thunks'
import { fetchExploreThunk } from '../../../Views/Explore/redux/Thunks'
import { fetchCheckOutThunk } from '../../../Views/CheckOut/redux/Thunks'
import { fetchMediaDetailThunk } from '../../../Views/MediaDetail/redux/Thunks'
import { fetchCartThunk, fetchCheckOutValidateThunk } from '../../../Views/Cart/redux/Thunks'
import { fetchProfileThunk, fetchProfileUpdateThunk } from '../../../Views/Profile/redux/Thunks'

export interface LoadingAppState {
  Status: ELazyStatus
}

export interface AppStatusState extends LoadingAppState {}
// Define the initial state using that type
const initialState: AppStatusState = {
  Status: ELazyStatus.Loaded,
}

const AppStatusSlice = createSlice({
  name: 'AppStatusSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    changeLoading: (state, action: PayloadAction<ELazyStatus>) => {
      state.Status = action.payload
    },
  },
  extraReducers: (builder) => {
    buildThunkGlobal(builder, fetchHomeThunk)
    buildThunkGlobal(builder, fetchPricingThunk)
    buildThunkGlobal(builder, fetchExploreThunk)
    buildThunkGlobal(builder, fetchMediaDetailThunk)
    buildThunkGlobal(builder, fetchCartThunk)
    buildThunkGlobal(builder, fetchCheckOutValidateThunk)
    buildThunkGlobal(builder, fetchCheckOutThunk)
    buildThunkGlobal(builder, fetchProfileThunk)
    buildThunkGlobal(builder, fetchProfileUpdateThunk)
  },
})

export default AppStatusSlice
