import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {ETicketType} from '../enums';
import type {ETicketStatus} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface TicketFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'Ticket', T, TVariables> {

    on<XName extends ImplementationType<'Ticket'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): TicketFetcher<
        XName extends 'Ticket' ?
        T & X :
        WithTypeName<T, ImplementationType<'Ticket'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'Ticket'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): TicketFetcher<T, TVariables>;


    readonly __typename: TicketFetcher<T & {__typename: ImplementationType<'Ticket'>}, TVariables>;


    readonly id: TicketFetcher<T & {readonly "id": number}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: number} : 
                {readonly [key in XAlias]: number}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": TicketFetcher<Omit<T, 'id'>, TVariables>;


    readonly type: TicketFetcher<T & {readonly "type": ETicketType}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: ETicketType} : 
                {readonly [key in XAlias]: ETicketType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": TicketFetcher<Omit<T, 'type'>, TVariables>;


    readonly resourceId: TicketFetcher<T & {readonly "resourceId"?: string}, TVariables>;

    "resourceId+"<
        XAlias extends string = "resourceId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"resourceId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~resourceId": TicketFetcher<Omit<T, 'resourceId'>, TVariables>;


    readonly subject: TicketFetcher<T & {readonly "subject"?: string}, TVariables>;

    "subject+"<
        XAlias extends string = "subject", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"subject", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~subject": TicketFetcher<Omit<T, 'subject'>, TVariables>;


    readonly userCreatedId: TicketFetcher<T & {readonly "userCreatedId"?: string}, TVariables>;

    "userCreatedId+"<
        XAlias extends string = "userCreatedId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userCreatedId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userCreatedId": TicketFetcher<Omit<T, 'userCreatedId'>, TVariables>;


    readonly status: TicketFetcher<T & {readonly "status": ETicketStatus}, TVariables>;

    "status+"<
        XAlias extends string = "status", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"status", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): TicketFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: ETicketStatus} : 
                {readonly [key in XAlias]: ETicketStatus}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~status": TicketFetcher<Omit<T, 'status'>, TVariables>;


    ticketConversation<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'TicketConversation', X, XVariables>
    ): TicketFetcher<
        T & {readonly "ticketConversation"?: X}, 
        TVariables & XVariables & TicketArgs["ticketConversation"]
    >;

    ticketConversation<
        XArgs extends AcceptableVariables<TicketArgs['ticketConversation']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'TicketConversation', X, XVariables>
    ): TicketFetcher<
        T & {readonly "ticketConversation"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, TicketArgs['ticketConversation']>
    >;

    ticketConversation<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticketConversation", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'TicketConversation', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticketConversation", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & TicketArgs["ticketConversation"] & XDirectiveVariables
    >;

    ticketConversation<
        XArgs extends AcceptableVariables<TicketArgs['ticketConversation']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticketConversation", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'TicketConversation', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticketConversation", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, TicketArgs['ticketConversation']> & XDirectiveVariables
    >;


    ticketConversations<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'TicketConversation', X, XVariables>
    ): TicketFetcher<
        T & {readonly "ticketConversations"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    ticketConversations<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticketConversations", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'TicketConversation', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticketConversations", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    ticketVisiteds<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'TicketVisited', X, XVariables>
    ): TicketFetcher<
        T & {readonly "ticketVisiteds"?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables
    >;

    ticketVisiteds<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticketVisiteds", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'TicketVisited', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticketVisiteds", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketFetcher<
        T & {readonly [key in XAlias]?: ReadonlyArray<X | undefined>}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    user<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): TicketFetcher<
        T & {readonly "user"?: X}, 
        TVariables & XVariables
    >;

    user<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "user", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"user", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): TicketFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;
}

export const ticket$: TicketFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "Ticket", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                "type", 
                {
                    category: "SCALAR", 
                    name: "resourceId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "subject", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "userCreatedId", 
                    undefinable: true
                }, 
                "status", 
                {
                    category: "REFERENCE", 
                    name: "ticketConversation", 
                    argGraphQLTypeMap: {id: 'Int!'}, 
                    targetTypeName: "TicketConversation", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "ticketConversations", 
                    targetTypeName: "TicketConversation", 
                    undefinable: true
                }, 
                {
                    category: "LIST", 
                    name: "ticketVisiteds", 
                    targetTypeName: "TicketVisited", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "user", 
                    targetTypeName: "ApplicationUser", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const ticket$$ = 
    ticket$
        .id
        .type
        .resourceId
        .subject
        .userCreatedId
        .status
;

export interface TicketArgs {

    readonly ticketConversation: {
        readonly id: number
    }
}
