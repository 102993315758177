import { createAsyncThunk } from '@reduxjs/toolkit'
import { ICategoryDTO, IExploreStateSlice, IMediaDTO } from '../type'
import MediaService from '../services/MediaService'
import DataMapping from '../services/DataMapping'
import CategoryService from '../services/CategoryService'

interface IExploreResult {
  categories: ICategoryDTO[]
  medias: IMediaDTO[]
  mediaTotal: number
}

export const fetchExploreThunk = createAsyncThunk<IExploreResult>('fetchExploreThunk', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as { ExploreSlice: IExploreStateSlice }
  const filter = state.ExploreSlice.filter
  const [medias, categories] = await Promise.all([MediaService.filter(filter), CategoryService.filter(filter)])
  return {
    medias: DataMapping.medias(medias.Data),
    mediaTotal: medias.Total,
    categories: DataMapping.categories(categories),
  }
})

// interface IExploreFilterMediaResult {
//   medias: IMediaDTO[]
//   mediaTotal: number
// }

// export const fetchExploreFilterMediaThunk = createAsyncThunk<IExploreFilterMediaResult>(
//   'fetchExploreFilterMediaThunk',
//   async (_, thunkAPI) => {
//     const state = thunkAPI.getState() as { ExploreSlice: IExploreStateSlice }
//     const filter = state.ExploreSlice.filter
//     const medias = await MediaService.filter(filter)
//     return { medias: DataMapping.medias(medias.Data), mediaTotal: medias.Total }
//   }
// )
