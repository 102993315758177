import { connect } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { IOrder } from '@coreprj/types/models'
import { GridCallbackDetails } from '@mui/x-data-grid'
import { ITableTemplateState } from '@coreprj/shareds/Tables/TemplateTable'
import { OrderSlice } from './Slice'
import { fetchOrderThunk } from './Thunks'
import ViewBase from '..'
import { CreateHocLazy } from '@coreprj/redux/Core/HocLazy'
import { ICRUDActionRedux, TDispatchRedux, TStateRedux } from '@coreprj/redux/Core/types'
import { AppDispatch, RootState } from '@coreprj/redux/type'

export interface ReduxOrderProps extends TDispatchRedux, ICRUDActionRedux<IOrder, 'Id'> {
  onChange: <Tk extends keyof ITableTemplateState<IOrder>>(key: Tk, value: ITableTemplateState<IOrder>[Tk], details: GridCallbackDetails) => void
}
export interface ReduxOrderState extends TStateRedux {
  data: Dictionary<IOrder>
  tableInfo: ITableTemplateState<IOrder>
}
export type TOrderProps = ReduxOrderState & ReduxOrderProps & {}
const mapStateToProps = (state: RootState): ReduxOrderState => ({
  Status: state.OrderSlice.Status,
  data: state.OrderSlice.data.entities,
  tableInfo: state.OrderSlice.TableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
const mapDispatchToProps = (dispatch: AppDispatch): ReduxOrderProps => {
  return {
    FetchData() {
      return dispatch(fetchOrderThunk())
    },
    onChange(key, value, details) {
      if (key === 'FilterModel') {
        dispatch(OrderSlice.actions.onChange({ key, value, details }))
      }
      FetchAccessory.request?.abort()
      FetchAccessory.request = dispatch(fetchOrderThunk({ key, value: value, details }))
    },
    Create: async (model) => {},
    Update: async (Id, model) => {},
    Delete: async (Id) => {}
  }
}
const ComponentLazy = CreateHocLazy(ViewBase)

const OrderMapRedux = connect(mapStateToProps, mapDispatchToProps)(ComponentLazy)
export default OrderMapRedux
