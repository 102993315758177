import React, { Component, FC } from 'react'
import { Dictionary } from '@reduxjs/toolkit'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { NavigateKey } from '../../Route'
import { ICartDTO, ICartReduxProps } from './type'
import { ECheckOutValidateStatus } from '../../Models/enums'
import Layout from './ui/Layout'
import CartItem from './ui/CartItem'
import SummaryReport from './ui/SummaryReport'
import { Box } from '@mui/material'
import NoData from './ui/NoData'
import { ApiAlertContext } from '@coreprj/shareds/AlertGlobal'

interface IContentProps extends ICartReduxProps {
  navigate: NavigateFunction
}

class ViewBaseContent extends Component<IContentProps> {
  render() {
    const data = this.getCartData()
    return (
      <Layout
        onSubmit={this.handleSubmitCheckOut}
        components={{
          content: this.renderContent(data),
          summaryReport: (
            <SummaryReport
              // TODO thêm thanh toán
              // disabled={data.length < 1}
              disabled={true}
              data={data}
              onCheckOut={this.handleSubmitCheckOut}
              checkOutStatus={this.props.CartSlice.checkOutStatus}
            />
          )
        }}
      />
    )
  }

  componentDidUpdate(prevProps: Readonly<IContentProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.CartSlice.checkOutStatus === ECheckOutValidateStatus.Valid) {
      this.props.navigate(NavigateKey.CheckOut)
    }
  }

  itemMap: Dictionary<CartItem | null> = {}
  renderContent = (carts: ICartDTO[]) => {
    if (carts.length < 1) return <NoData />
    return (
      <Box sx={{ py: '28px' }}>
        {carts.map((item, index) => (
          <CartItem
            ref={(ref) => (this.itemMap[item.id] = ref)}
            key={index}
            data={item}
            onChange={this.handleChangeItem}
            onDelete={this.handleDeleteItem}
          />
        ))}
      </Box>
    )
  }

  validateAll = () => {
    let temp = true
    Object.values(this.itemMap)
      .filter((e) => !!e)
      .forEach((item) => {
        const status = item?.validate() ?? false
        if (temp) temp = status
      })
    return temp
  }

  getCartData = () => {
    return Object.values(this.props.CartSlice.carts.entities) as ICartDTO[]
  }

  handleSubmitCheckOut = async () => {
    const isValid = this.validateAll()
    if (!isValid) {
      ApiAlertContext.ApiAlert?.PushWarning('Please check your cart again!')
      return
    }
    this.props.checkOutValidate()
  }

  handleChangeItem = (value: ICartDTO) => {
    this.props.updateCart(value)
  }

  handleDeleteItem = (value: ICartDTO) => {
    delete this.itemMap[value.id]
    this.props.removeCart(value.id)
  }
}

interface IProps extends ICartReduxProps {}

const ViewBase: FC<IProps> = (props) => {
  return <ViewBaseContent {...props} navigate={useNavigate()} />
}
export default ViewBase
