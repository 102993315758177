import { ICategory, IMedia } from '../Models'
import { EMediaType } from '../Models/enums'

export interface ISearchBoxData {
  title: string
  subTitle: string
  lead: string
}

export enum EInputType {
  Checkbox = 'Checkbox',
  RadioButton = 'RadioButton',
  DateFromTo = 'DateFromTo',
  ProgressFromTo = 'ProgressFromTo',
}

export interface TFilterConfig {
  title: string
  type: EInputType
  display?: Array<string | number>
}

export type TFilterConfigMap = {
  [key: string]: TFilterConfig
}

export class SupportDisplayData {
  static SearchBoxVideoData: ISearchBoxData = {
    title: 'VIDEO COLLECTION',
    subTitle: 'Authentic media, new updates constantly, high quality videos and high quality audio',
    lead: 'Try video url or keyword. e.g: the dogs',
  }

  static SearchBoxAudioData: ISearchBoxData = {
    title: 'AUDIO COLLECTION',
    subTitle:
      '10,000+ unique media product items, copyright support in the most popular social networks: youtube, facebook, spotify...',
    lead: 'Try audio url or keyword. e.g: dreamy',
  }

  static VideoCategoriesData: ICategory[] = [
    {
      Id: '41fb7341-d823-4e60-b187-d133019569b4',
      Name: 'Art',
      Description: '11 234 Media',
      ImageUri: '/images/videos/art.png',
      Type: EMediaType.Video,
    },
    {
      Id: '8a4d3d97-5a72-4fd3-875b-49f28c65096b',
      Name: 'Cute',
      Description: '11 234 Media',
      ImageUri: '/images/videos/cute.png',
      Type: EMediaType.Video,
    },
    {
      Id: 'Pets',
      Name: 'Pets',
      Description: '11 234 Media',
      ImageUri: '/images/videos/pets.png',
      Type: EMediaType.Video,
    },
    {
      Id: 'Family',
      Name: 'Family',
      Description: '11 234 Media',
      ImageUri: '/images/videos/family.png',
      Type: EMediaType.Video,
    },
    {
      Id: 'Food',
      Name: 'Food',
      Description: '11 234 Media',
      ImageUri: '/images/videos/food.png',
      Type: EMediaType.Video,
    },
    {
      Id: 'Talent',
      Name: 'Talent',
      Description: '11 234 Media',
      ImageUri: '/images/videos/talent.png',
      Type: EMediaType.Video,
    },
    {
      Id: 'Travel',
      Name: 'Travel',
      Description: '11 234 Media',
      ImageUri: '/images/videos/travel.png',
      Type: EMediaType.Video,
    },
    {
      Id: 'a31d42e8-25c0-4402-91df-5f3d75644429',
      Name: 'Fails',
      Description: '11 234 Media',
      ImageUri: '/images/videos/fails.png',
      Type: EMediaType.Video,
    },
  ]

  static getProductByCategoryIds = (ids: string[]) => {
    let list: (ICategory & { Products: IMedia[] })[] = []
    const categories = SupportDisplayData.VideoCategoriesData.filter((e) => ids.findIndex((id) => id === e.Id) >= 0)
    list = categories.map((e) => {
      const products = SupportDisplayData.Products.filter((p) => p.CategoryId === e.Id)
      return { ...e, Products: products }
    })
    // console.log({ ids, list, categories })
    return list
  }

  static AudioCategoriesData: ICategory[] = [
    {
      Id: 'Acoustic',
      Name: 'Acoustic',
      Description: '11 234 Media',
      ImageUri: '/images/audios/acoustic.png',
      Type: EMediaType.Audio,
    },
    {
      Id: 'Film',
      Name: 'Film',
      Description: '11 234 Media',
      ImageUri: '/images/audios/film.png',
      Type: EMediaType.Audio,
    },
    {
      Id: 'Hip Hop',
      Name: 'Hip Hop',
      Description: '11 234 Media',
      ImageUri: '/images/audios/hiphop.png',
      Type: EMediaType.Audio,
    },
    {
      Id: 'Piano',
      Name: 'Piano',
      Description: '11 234 Media',
      ImageUri: '/images/audios/piano.png',
      Type: EMediaType.Audio,
    },
    {
      Id: 'Pop',
      Name: 'Pop',
      Description: '11 234 Media',
      ImageUri: '/images/audios/pop.png',
      Type: EMediaType.Audio,
    },
  ]

  static getCategories = (status?: EMediaType) => {
    if (status === EMediaType.Audio) return SupportDisplayData.AudioCategoriesData
    return SupportDisplayData.VideoCategoriesData
  }

  static Products: any[] = [
    // AUDIOS
    {
      Id: '4737afa0-391e-41c8-a533-5db2111b5577',
      Name: 'Lorem ipsum dolor sit amet',
      Type: EMediaType.Audio,
    },
    {
      Id: '268ef9e0-9035-49e5-8182-095105ba3b22',
      Name: 'Consectetur adipiscing elit',
      Type: EMediaType.Audio,
    },
    {
      Id: 'd15a4f2f-8baa-4ca8-9e43-4d50e7a80aad',
      Name: 'Sed ac erat nunc',
      Type: EMediaType.Audio,
    },
    {
      Id: '0de26e9f-25ff-49d9-909f-0cb35d034034',
      Name: 'Curabitur eget',
      Type: EMediaType.Audio,
    },
    {
      Id: 'b7758cbc-b0db-4763-b8a4-d509e057f0ea',
      Name: 'Turpis ac dui cursus',
      Type: EMediaType.Audio,
    },
    {
      Id: '14ef5fef-9ee7-4709-ad8a-27c85b2d5686',
      Name: 'Fermentum at at nunc',
      Type: EMediaType.Audio,
    },
    {
      Id: '94e1aebf-59bf-409a-a5f5-739d67134c00',
      Name: 'Aenean eget ultricies arcu',
      Type: EMediaType.Audio,
    },
    {
      Id: '467a34ad-8331-4f1b-ab61-6137e9d95b33',
      Name: 'A molestie velit',
      Type: EMediaType.Audio,
    },
    {
      Id: 'c917dba1-6c28-473a-9f38-a10171448187',
      Name: 'Vestibulum lacinia vulputate venenatis',
      Type: EMediaType.Audio,
    },
    // VIDEOS
    {
      Id: 'e8026a8e-12d0-4339-a139-ecba8d3dea51',
      Name: 'Sed vitae sodales quam',
      Type: EMediaType.Video,
      CategoryId: '41fb7341-d823-4e60-b187-d133019569b4',
    },
    {
      Id: '5e2d4d14-e121-4363-8a39-7b5b304ffa50',
      Name: 'Fusce in nisl diam',
      Type: EMediaType.Video,
      CategoryId: '41fb7341-d823-4e60-b187-d133019569b4',
    },
    {
      Id: 'f3da3366-0acd-4b49-848b-e5c4705af7d4',
      Name: 'Nullam vestibulum dictum facilisis',
      Type: EMediaType.Video,
      CategoryId: '41fb7341-d823-4e60-b187-d133019569b4',
    },
    {
      Id: '600fc426-9f47-4d90-bd62-081fd1818307',
      Name: 'Ut posuere tincidunt elit',
      Type: EMediaType.Video,
      CategoryId: '41fb7341-d823-4e60-b187-d133019569b4',
    },
    {
      Id: '3c896369-0ee5-410c-9bfe-58f8d48321ad',
      Name: 'Nunc id egestas arcu',
      Type: EMediaType.Video,
      CategoryId: '41fb7341-d823-4e60-b187-d133019569b4',
    },
    {
      Id: '050d39cf-a844-40c6-89f4-fd25170c31e7',
      Name: 'Sed sodales ante vitae aliquam gravida',
      Type: EMediaType.Video,
      CategoryId: '8a4d3d97-5a72-4fd3-875b-49f28c65096b',
    },
    {
      Id: '1abc0f6a-186b-4e06-b1dc-6ae048e84bc2',
      Name: 'Integer in felis tortor',
      Type: EMediaType.Video,
      CategoryId: '8a4d3d97-5a72-4fd3-875b-49f28c65096b',
    },
    {
      Id: '2a5616a5-7f65-49c9-832f-a21bc56f1234',
      Name: 'Donec risus diam',
      Type: EMediaType.Video,
      CategoryId: '8a4d3d97-5a72-4fd3-875b-49f28c65096b',
    },
    {
      Id: 'e88e26d5-b737-4a6e-a9d5-08dfc873ec1b',
      Name: 'Blandit quis tortor vel',
      Type: EMediaType.Video,
      CategoryId: '8a4d3d97-5a72-4fd3-875b-49f28c65096b',
    },
    {
      Id: '86f42a92-f9af-44d1-b134-18542dfcd902',
      Name: 'Elementum pulvinar lacus',
      Type: EMediaType.Video,
      CategoryId: '8a4d3d97-5a72-4fd3-875b-49f28c65096b',
    },
  ]

  static getProducts = (status?: EMediaType) => {
    if (!status) return SupportDisplayData.Products
    return SupportDisplayData.Products.filter((e) => e.Type === status)
  }

  static VideoFilterConfig: TFilterConfigMap = {
    // Quality: {
    //   title: "Quanlity",
    //   type: EInputType.Checkbox,
    //   display: ["240p", "720p", "1080p"],
    // },
    // Orientation: {
    //   title: "Orientation",
    //   type: EInputType.RadioButton,
    //   display: ["Horizontal", "Vertical"],
    // },
    DateAdded: {
      title: 'Date Added',
      type: EInputType.DateFromTo,
    },
    // DatePosted: {
    //   title: "Date Posted",
    //   type: EInputType.DateFromTo,
    // },
    // Duaration: {
    //   title: 'Duaration',
    //   type: EInputType.ProgressFromTo,
    //   display: [0, 1380],
    // },
    Sort: {
      title: 'Sort',
      type: EInputType.RadioButton,
      display: ['Date Added', 'Date Posted', 'Duration'],
    },
    SortBy: {
      title: 'Sort by',
      type: EInputType.RadioButton,
      display: ['Ascending', 'Descending'],
    },
  }

  static AudioFilterConfig: TFilterConfigMap = {
    // Format: {
    //   title: "Format",
    //   type: EInputType.Checkbox,
    //   display: ["MP3", "WAV", "FLAC"],
    // },
    DateAdded: {
      title: 'Date Added',
      type: EInputType.DateFromTo,
    },
    // DatePosted: {
    //   title: "Date Posted",
    //   type: EInputType.DateFromTo,
    // },
    Sort: {
      title: 'Sort',
      type: EInputType.RadioButton,
      display: ['Date Added', 'Date Posted', 'Duration'],
    },
    SortBy: {
      title: 'Sort by',
      type: EInputType.RadioButton,
      display: ['Ascending', 'Descending'],
    },
  }
}
