import { PayloadAction } from '@reduxjs/toolkit'
import { INotification } from '@coreprj/types/models'
import { GridCallbackDetails } from '@mui/x-data-grid'
import { CreateCRUDSlice2 } from '@coreprj/redux/Core/CRUDSlice2'
import { ITableTemplateState } from '@coreprj/shareds/Tables/TemplateTable'
import { INotificationStateSlice } from './type'
import { fetchNotificationThunk } from './Thunks'
import { LazyStatus } from '@coreprj/redux/Core/types'

export const NotificationSlice = CreateCRUDSlice2<INotification>()({
  name: 'NotificationSlice',
  selectId: (x) => x.Id,
  Initial: (x) => {
    const initalValue: INotificationStateSlice = {
      ...x,
      TableInfo: {
        isLoading: true,
        PageInfo: { data: [], page: 0, pageSize: 25 },
        GridSortModel: [{ field: 'DateUpdated', sort: 'desc' }]
      }
    }
    return initalValue
  },
  extraReducers: (builder, adapter) => {
    builder
      .addCase(fetchNotificationThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        const { Result, TableInfo } = action.payload

        state.Status = LazyStatus.Loaded
        state.TableInfo = {
          ...TableInfo,
          isLoading: false,
          PageInfo: {
            data: Result?.Data ?? [],
            page: Result?.Page ?? 0,
            pageSize: Result?.Amount ?? 100,
            rowTotal: Result?.Total
          }
        }
      })
      .addCase(fetchNotificationThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = LazyStatus.Error
        state.TableInfo.isLoading = true
      })
      .addCase(fetchNotificationThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = LazyStatus.Loading
        state.TableInfo.isLoading = true
      })
  },
  reducers: {
    onChange: (state, action: PayloadAction<{ key: keyof ITableTemplateState<INotification>; value: any; details: GridCallbackDetails }>) => {
      state.TableInfo[action.payload.key] = action.payload.value
      state.TableInfo.details = action.payload.details
    }
  }
})
