import { AppConstant, FetchDelay } from '../../../utilities'
import { ICartDTO } from '../type'

class CartLocalStorageBase {
  private key = AppConstant.cartLocalKey

  getDataAsync = (): Promise<ICartDTO[]> => {
    return FetchDelay(async () => this.getData(), 300)
  }

  getData = (): ICartDTO[] => {
    try {
      const data = localStorage.getItem(this.key)
      const carts: ICartDTO[] = JSON.parse(data ?? '[]')
      return carts
    } catch (error) {
      return []
    }
  }

  setData = (value: ICartDTO[]) => {
    localStorage.setItem(this.key, JSON.stringify(value))
  }
}
const CartLocalStorage = new CartLocalStorageBase()
export default CartLocalStorage
