import React, { Component, ComponentType, Fragment } from 'react'
import { IFormBase } from '@coreprj/shareds/Forms/Subs'
import { getErrorMessage } from '@coreprj/shareds/Forms/ValidateForm/Common'
import { Box, Button, Divider, Fade, Stack, alpha, styled } from '@mui/material'
import { InputField } from './InputControls'
import { IItemConfig, IProfileContentFormConfig } from '../configs'
import UiStyleds from '../UI/Styled'
import TextOnly from './TextOnly'

interface IFormBaseProps<T> extends IFormBase<T> {
  config: IProfileContentFormConfig<T>
  disabled?: boolean
  overlayLazy?: boolean
  alwaysCustomized?: boolean
}
const filterConfig = function <T>(item: IItemConfig<T>, model?: T) {
  const dValue = model ? model[item.inputName] : ''
  return !(item.AllwaysDisplay === false && !dValue)
}
const CreateFormBase = function <T>(): ComponentType<IFormBaseProps<T>> {
  class FormBase extends Component<IFormBaseProps<T>> {
    render() {
      const { config } = this.props
      return (
        <UiStyleds.FormWrap>
          <Box sx={{ mb: '3px' }}>{this.renderTitle()}</Box>
          <Fade in={!!this.props.config.description}>
            <Box height={22}>{this.renderDescription()}</Box>
          </Fade>
          <Stack sx={{ gap: '14px', marginTop: '16px', marginBottom: '-1px' }}>
            {config.items
              .filter((x) => filterConfig(x, this.props.Model))
              .map((item, index) => (
                <Fragment key={item.inputName + index}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <UiStyleds.Text16 sx={{ width: '240px', mt: '2px' }}>{item.title}</UiStyleds.Text16>
                    <Box sx={{ mb: '-5px', flex: 1, mr: '18px' }}>{this.renderInputControl(item)}</Box>
                  </Box>
                  <Divider />
                </Fragment>
              ))}
          </Stack>
          {this.props.alwaysCustomized !== false && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: '9px', pr: '12px' }}>
              <Button color='primary' type='submit' disabled={this.props.disabled} sx={{ fontWeight: 600, textTransform: 'unset' }}>
                Save
              </Button>
            </Box>
          )}
          <Fade in={this.props.overlayLazy}>
            <OverlayLazy />
          </Fade>
        </UiStyleds.FormWrap>
      )
    }

    renderTitle = () => {
      if (typeof this.props.config.title === 'string') {
        return <UiStyleds.Text16 sx={{ fontWeight: 600 }}>{this.props.config.title}</UiStyleds.Text16>
      }
      return this.props.config.title ?? <></>
    }

    renderDescription = () => {
      if (typeof this.props.config.description === 'string') {
        return <UiStyleds.Text14>{this.props.config.description}</UiStyleds.Text14>
      }
      return this.props.config.description ?? <></>
    }

    renderInputControl = (item: IItemConfig<T>) => {
      const errorData = getErrorMessage(this.props.MessageError, item.inputName)
      const dValue = this.props.Model ? this.props.Model[item.inputName] : ''
      let InputElement = item.inputElement ?? InputField
      if (this.props.alwaysCustomized === false) InputElement = item.inputElement ?? TextOnly
      return (
        <InputElement
          name={item.inputName}
          defaultValue={dValue}
          {...errorData}
          placeholder={item.placeholder ?? item.title}
          disabled={this.props.disabled || item.disabled}
          onBlur={() => this.props.onBlur && this.props.onBlur(item.inputName)}
        />
      )
    }
  }
  return FormBase
}
export default CreateFormBase

const OverlayLazy = styled(Box)({
  position: 'absolute',
  backgroundColor: alpha('#fff', 0.5),
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
})
