import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ELazyStatus } from '../../../Models/enums'
import { IMediaDetailStateSlice } from '../type'
import { fetchMediaDetailThunk } from './Thunks'

// Define the initial state using that type
const initialState: IMediaDetailStateSlice = {
  Status: ELazyStatus.Loading,
  categories: [],
  pricePackages: [],
  recommends: []
}

const MediaDetailSlice = createSlice({
  name: 'MediaDetailSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<ELazyStatus>) => {
      state.Status = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMediaDetailThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Loaded

        state.media = action.payload.media
        state.categories = action.payload.categories
        state.recommends = action.payload.recommends
        state.pricePackages = action.payload.pricePackages
      })
      .addCase(fetchMediaDetailThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Status = ELazyStatus.Error
      })
      .addCase(fetchMediaDetailThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Status = ELazyStatus.Loading
      })
  }
})
export default MediaDetailSlice
