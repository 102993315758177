import { Divider, styled } from '@mui/material'
import { Component, FC } from 'react'
import AddIcon from '@mui/icons-material/Add'

interface OldLoginModel {
  title: string
}

interface IProps {}

export class HistoryLoginControl extends Component<IProps> {
  size = 108
  imageSource = process.env.PUBLIC_URL + '/images/videos/art.png'
  oldLogins = []

  _renderOldLogin = ({ data }: { data?: OldLoginModel }) => {
    if (!data) {
      return (
        <WrapperItem size={this.size} sx={{ backgroundColor: '#878787' }}>
          <FlexCenterCenter sx={{ flex: 1 }}>
            <AddBox className="add-box">
              <AddIcon sx={{ color: '#FFFFFF', fontSize: `${this.size * 0.56}px` }} />
            </AddBox>
          </FlexCenterCenter>
          <Title sx={{ color: '#FFFFFF' }}>Add Account</Title>
        </WrapperItem>
      )
    }
    return (
      <WrapperItem size={this.size}>
        <FlexCenterCenter sx={{ flex: 1 }}>
          <CustomImage src={this.imageSource} />
        </FlexCenterCenter>
        <Title>Medium/16px</Title>
      </WrapperItem>
    )
  }

  render() {
    return (
      <Container>
        <Row>
          <OldLogin imageSource={this.imageSource} size={this.size} data={{ title: 'My name' }} />
          <OldLogin imageSource={this.imageSource} size={this.size} />
        </Row>
        <Divider role="presentation" sx={{ width: '55vw' }}>
          <TextGreen>OR</TextGreen>
        </Divider>
      </Container>
    )
  }
}

export default HistoryLoginControl

interface IOldLoginProps {
  data?: OldLoginModel
  size: number
  imageSource: string
}

const OldLogin: FC<IOldLoginProps> = (props) => {
  if (!props.data) {
    return (
      <WrapperItem size={props.size} sx={{ backgroundColor: '#878787' }}>
        <FlexCenterCenter sx={{ flex: 1 }}>
          <AddBox className="add-box">
            <AddIcon sx={{ color: '#FFFFFF', fontSize: `${props.size * 0.56}px` }} />
          </AddBox>
        </FlexCenterCenter>
        <Title sx={{ color: '#FFFFFF' }}>Add Account</Title>
      </WrapperItem>
    )
  }
  return (
    <WrapperItem size={props.size}>
      <FlexCenterCenter sx={{ flex: 1 }}>
        <CustomImage src={props.imageSource} />
      </FlexCenterCenter>
      <Title>{props.data.title}</Title>
    </WrapperItem>
  )
}

const FlexCenterCenter = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const Row = styled(FlexCenterCenter)({
  gap: '20px',
})

const Container = styled(FlexCenterCenter)({
  // backgroundColor: "#FFFFFF",
  gap: '20px',
  padding: '20px 0',
  flexDirection: 'column',
  width: '100%',
})

interface IWrapper {
  size: number
}
const WrapperItem = styled(FlexCenterCenter)<IWrapper>(({ size }) => ({
  padding: '10px 0',
  flexDirection: 'column',
  width: `${size}px`,
  height: `${size}px`,
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow: '0 2px 14px rgba(0,0,0,0.2)',
  transform: 'scale(1)',
  transition: 'all linear 0.2s',
  cursor: 'pointer',
  '& img': {
    width: `${size * 0.56}px`,
    height: `${size * 0.56}px`,
    borderRadius: `${size * 0.28}px`,
  },
  '& .add-box': {
    borderRadius: `${size * 0.28}px`,
  },
  '&:hover': {
    boxShadow: '0 2px 14px rgba(0,0,0,0.45)',
    transform: 'scale(1.02)',
  },
}))

const CustomImage = styled('img')({
  boxShadow: '0 2px 14px rgba(0,0,0,0.25)',
  borderRadius: '4px',
})

const Title = styled('h4')({
  fontWeight: 500,
  fontSize: '16px',
})

const AddBox = styled('div')({
  backgroundColor: 'rgba(255,255,255,0.35)',
})

const TextGreen = styled('span')({
  display: 'inline-block',
  padding: '7.5px 15px',
  color: '#00CE0A',
  fontWeight: 700,
  fontSize: '16px',
})
