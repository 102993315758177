export enum EAppName {
  ClientApp = 'ClientApp',
  ClientAdmin = 'ClientAdmin'
}

export enum RoleKeyInternalSite {
  Admin = 'Admin',
  User = 'User',
  Manage = 'Manage'
}

export enum RoleKeyExternalSite {
  NetworkChannel = 'NetworkChannel',
  AssetContent = 'AssetContent',
  AudioAsset = 'AudioAsset'
}

export enum EGraphqlSite {
  Admin = 'admin',
  Manage = 'manage',
  User = 'user'
}
