import React, { Component } from 'react'
import { Params, useParams } from 'react-router-dom'
import { ELazyStatus } from '../../Models/enums'
import { IMediaDetailReduxProps } from './type'
import { WindowScrollToTop } from '../../utilities'
import { ChooseLicenseMapRedux } from '../Cart/redux/Map'
import Helpers from './helpers'
import Layout from './ui/Layout'
import RecommendedMedias from './ui/RecommendedMedias'
import MediaPlayerLayout from './ui/MediaPlayerLayout'
import RecommendedCategories from './ui/RecommendedCategories'
import { AudioSingleEventEmitter, IRecommendedMediasContext, RecommendedMediasContext } from './ui/ComponentContext'

interface IProps extends IMediaDetailReduxProps {
  params?: Readonly<Params<string>>
}

class ViewBaseContent extends Component<IProps> implements IRecommendedMediasContext {
  constructor(props: any) {
    super(props)
    this.audioEvents = new AudioSingleEventEmitter()
  }
  audioEvents: AudioSingleEventEmitter
  render() {
    const isLoading = this.props.Status === ELazyStatus.Loading
    const mediaType = Helpers.parseMediaType(this.props.params?.type)
    const media = this.props.MediaDetailSlice.media
    const categories = this.props.MediaDetailSlice.categories
    const recommendedMedia = this.props.MediaDetailSlice.recommends
    const licenses = this.props.MediaDetailSlice.pricePackages
    return (
      <RecommendedMediasContext.Provider value={this}>
        <Layout
          isLoading={isLoading}
          components={{
            player: <MediaPlayerLayout data={media} mediaType={mediaType} />,
            license: <ChooseLicenseMapRedux pricePackages={licenses} media={media} />,
            category: <RecommendedCategories data={categories.map((e) => e.Name)} mediaType={mediaType} />,
            recommend: <RecommendedMedias mediaPlayed={media} data={recommendedMedia} />,
          }}
        />
      </RecommendedMediasContext.Provider>
    )
  }

  componentDidMount() {
    this.fetchAPI()
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.params?.id !== prevProps.params?.id) this.fetchAPI()
  }

  fetchAPI = () => {
    const id = Helpers.parseId(this.props.params?.id)
    const mediaType = Helpers.parseMediaType(this.props.params?.type)
    if (!id || !mediaType) return
    this.props.fetchData({ id, type: mediaType })
    WindowScrollToTop()
  }
}
const ViewBase: React.FC<IMediaDetailReduxProps> = (props) => {
  return <ViewBaseContent {...props} params={useParams()} />
}
export default ViewBase
