import { authService } from 'partner-oidc-auth'
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { HttpService } from './Getway'
import { EGraphqlSite } from '../models'
import { ProcessRepose } from './Getway/RequestHelper'

interface ErrorModel {
  [key: string]: string[]
}

const ProcessError = (err: AxiosError<ErrorModel>) => {
  const data = err.response?.data ?? {}
  const errorMessages = Object.keys(data)
    .filter((x: any) => data[x].length > 0)
    .map((x: any) => data[x][0])
  if (errorMessages.length < 1) return
  ;(window as any).ApiAlertContext.ApiAlert?.PushError(errorMessages.join('\n'))
}

export class ServiceBase {
  _http = HttpService
  _urlBase = ''
  /**
   *
   */
  constructor(http?: AxiosInstance) {
    if (http) {
      this._http = http
    }
    this.MapResponse()
  }
  MapResponse = () => {
    this._http.interceptors.response.use((res) => res, ProcessRepose)
  }
  private _token?: string
  SetToken(token?: string) {
    this._token = token
  }
  getToken = async () => {
    return this._token === undefined ? await authService.getAccessToken() : this._token
  }
  private addHeader = async (config?: AxiosRequestConfig) => {
    if (!config) {
      config = { headers: {} }
    }
    if (!config.headers) {
      config.headers = {}
    }
    await this.addtoken(config)
    await this.addSite(config)
    return config
  }

  private addtoken = async (config?: AxiosRequestConfig) => {
    const _token = await this.getToken()
    if (_token && config?.headers) {
      config.headers.Authorization = 'Bearer ' + _token
    }
    return config
  }
  private addSite(config?: AxiosRequestConfig) {
    if (this._site && config?.headers) {
      config.headers['graphql-site'] = this._site
    }
    return config
  }

  private _site?: EGraphqlSite
  SetSite = (site: EGraphqlSite) => {
    this._site = site
  }
  async Get<TModel>(url: string, config?: AxiosRequestConfig | undefined) {
    const response = await this._http.get<TModel>(url, await this.addHeader(config))
    return response.data
  }
  async TryGet<TModel>(url: string, config?: AxiosRequestConfig | undefined) {
    try {
      return this.Get<TModel>(url, config)
    } catch {
      return null
    }
  }
  async Post<TModel>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    const response = await this._http.post<TModel>(url, data, await this.addHeader(config))
    return response.data
  }
  async PostResponse<TModel>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    const response = await this._http.post<TModel>(url, data, await this.addHeader(config))
    return response
  }
  async Put<TModel>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    const response = await this._http.put<TModel>(url, data, await this.addHeader(config))
    return response.data
  }

  async TryPut<TModel>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    try {
      await this.addHeader(config)
      return await this.Put<TModel>(url, data, config)
    } catch (error) {
      return null
    }
  }
  async TryPost<TModel>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    try {
      return await this.Post<TModel>(url, data, config)
    } catch (error) {
      return null
    }
  }
  async Delete<TModel>(url: string, config?: AxiosRequestConfig | undefined) {
    const response = await this._http.delete<TModel>(url, await this.addHeader(config))
    return response.data
  }
  async TryDelete<TModel>(url: string, config?: AxiosRequestConfig | undefined) {
    try {
      return await this.Delete<TModel>(url, await this.addHeader(config))
    } catch (error) {
      ProcessError(error as any)
      return null
    }
  }
  async PushNotify<TFunc extends (...param: any[]) => any>(action: TFunc, ...p: Parameters<TFunc>) {
    try {
      return await action.bind(this)(...p)
    } catch (err) {
      const error = err as AxiosError
      const data = error.response?.data as any
      if (data) {
        const keys = Object.keys(data)
        keys.forEach((key) => {
          const errors = data[key] as string[]
          if (Array.isArray(errors)) {
            errors.forEach((message) => {
              ;(window as any).ApiAlertContext?.ApiAlert?.PushError(message)
            })
          }
        })
      }
      throw error
    }
  }
}
export default ServiceBase
export class CancelAction extends AbortController {
  cancel = () => {
    this.trigger && this.trigger()
    super.abort()
  }
  trigger?: () => void
}
