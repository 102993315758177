import React, { Component, Fragment } from 'react'
import { Box, Button, Container, Divider, Stack, styled } from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import { IPricePackage } from '../../Models'
import { AppConstant } from '../../utilities'
import { getPricingConfigs } from './Configs'
import Helpers from './helpers'
import PricingSection from './ui/PricingSection'

interface IProps {
  data?: IPricePackage[]
  isId?: boolean
}

export class PricingContent extends Component<IProps> {
  render() {
    return (
      <Box>
        {this.props.isId && <Box id="PricingSection" height={AppConstant.headerHeight} />}
        {this.renderContent()}
        <Divider />
        <Container id="SubmitVideoSection">
          <Stack sx={{ gap: '20px', alignItems: 'center', textAlign: 'center', padding: '72px 0 36px' }}>
            <Title>Ready to start generating income?</Title>
            <Text sx={{ width: '80%' }}>
              Are you an individual or a business looking to maximize your profits? If you have a digital product, please provide
              us with information about it. We are here to help you optimize your profits. Thank you!
            </Text>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: '18px' }}>
              <CustomButton
                variant="outlined"
                color="inherit"
                startIcon={<OndemandVideoIcon />}
                {...Helpers.mapLinkSubmitMedia()}
              >
                Videos Submissions
              </CustomButton>
              <CustomButton variant="outlined" color="inherit" startIcon={<MusicNoteIcon />} {...Helpers.mapLinkSubmitMedia()}>
                Audios Submissions
              </CustomButton>
            </Box>
          </Stack>
        </Container>
      </Box>
    )
  }

  renderContent = () => {
    const configs = getPricingConfigs(this.props.data ?? [])
    return configs.map((item, index) => {
      if (item.disabled) return <Fragment key={index} />
      return <PricingSection key={index} data={item} />
    })
  }
}
export default PricingContent

const Title = styled('h3')({
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '36px',
  fontWeight: 700,
})

const Text = styled('span')({
  display: 'inline-block',
  fontFamily: "'Exo 2', sans-serif",
  fontSize: '16px',
  fontWeight: 500,
  color: '#606060',
})

const CustomButton = styled(Button)({
  textTransform: 'unset',
  color: '#606060',
  fontWeight: 700,
  whiteSpace: 'nowrap',
  transition: 'all linear 0.2s',
  '&:hover': {
    color: '#1976D2',
  },
})
