import { EPriceType } from '@coreprj/types/enum'
import { ICart } from '../../../Models'
import { ICartDTO } from '../type'

export class DataMapping {
  static cartsCheckout = (value: ICartDTO[]): ICart[] => {
    return value.map<ICart>((item) => ({
      ProductId: item.id,
      PricePackageId: item.pricePackage?.Id ?? -1,
      Amount: item.jsonContent?.links?.length ?? 0,
      SocialUrl: JSON.stringify(item.jsonContent?.links ?? '[]'),
      Type: item.pricePackage?.Type ?? EPriceType.Media,
      ProductName: item.name
    }))
  }
}
